import React, { useState } from "react";
import useDGSync from "./useDGSync";
import Badge from "@material-ui/core/Badge";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Refresh from "@material-ui/icons/Refresh";
import useFarm from "../../hooks/useFarm";
import { Loads } from "../../Components/Icons";
import useLanguage from "../../hooks/useLanguage";

interface Props {}

export const DGSync = ({}: Props) => {
  const [farm] = useFarm();
  const { t } = useLanguage();
  const [disabled, setDisabled] = useState<boolean>(false);
  const [mutate, state] = useDGSync(setDisabled);
  return farm.isDG ? (
    <div>
      <Tooltip title={t("Sync with controller") as string} placement="bottom">
        <IconButton onClick={() => mutate()} disabled={disabled}>
          <Badge
            color="secondary"
            badgeContent={
              state.isLoading ? <CircularProgress size={20} color="inherit" /> : <Refresh color="inherit" />
            }
          >
            <Loads color="#fff" />
          </Badge>
        </IconButton>
      </Tooltip>
    </div>
  ) : null;
};
