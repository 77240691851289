import React from "react";
import { useMutation } from "react-query";
import useFarm from "../../hooks/useFarm";
import useAxios from "../../hooks/useAxios";
import useToast from "../../hooks/useToast";
import useLanguage from "../../hooks/useLanguage";

const useDGSync = (setDisabled: (isDisabled: boolean) => void) => {
  const [farm] = useFarm();
  const { axios } = useAxios();
  const toast = useToast();
  const { t } = useLanguage();

  const QDGSync = useMutation(() => axios.get(`sync/farm/${farm.farmId}`), {
    onMutate: () => {
      setDisabled(true);
    },
    onError: (err, variables, previousValue) => {
      toast.error(t("ERROR - Can't sync at this time"));
    },
    onSuccess: (res, _name) => {
      setDisabled(false);
      toast.success(t("Sync carried out successfully"));
    },
  });

  return QDGSync;
};

export default useDGSync;
