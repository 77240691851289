import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import React, { ReactNode, useEffect, useRef, useState } from "react";
import useAuth from "../hooks/useAuth";
import useLegal from "../hooks/useLegal";
import useLanguage from "../hooks/useLanguage";
import useStyles from "../Screens/styles";
import Backdrop from "@material-ui/core/Backdrop";

import CircularProgress from "@material-ui/core/CircularProgress";
import { Box, Tab, Tabs, Typography, useTheme } from "@material-ui/core";
import { TabPanel } from "@material-ui/lab";
import LanguageSelect from "../locales/LanguageSelect";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ErrorLegal from "../Screens/ErrorLegal";
import { Check, Clear, Delete } from "@material-ui/icons";
import LogoAndSpinner from "../Screens/LogoAndSpinner";

interface Props {
  children: ReactNode;
}
enum ETabs {
  "TermsOfUse",
  "Privacy",
}
export default function Legal({ children }: Props) {
  const LANGUAGE_SELECT = false;
  const [open, setOpen] = React.useState(true);
  const classes = useStyles();
  const { t, code } = useLanguage();
  const { user, logout } = useAuth();
  const { QLegal, agree, status, setStatus } = useLegal();
  const [tab, setTab] = useState<ETabs>(0);
  const theme = useTheme();

  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    if (status === "agreed") {
      setOpen(false);
    }
  }, [status]);

  const handleAgree = () => {
    agree.mutate();
  };
  const handleDisagree = () => {
    setOpen(false);
    logout();
  };

  const handleTabs = (event: React.ChangeEvent<{}>, newValue: ETabs) => {
    setTab(newValue);
  };

  return status === "agreed" ? (
    <React.Fragment>{children}</React.Fragment>
  ) : status === "error" ? (
    <ErrorLegal />
  ) : status === "checking" ? (
    <LogoAndSpinner />
  ) : (
    <div className={classes.root}>
      <Dialog
        fullScreen={fullScreen}
        fullWidth
        maxWidth="lg"
        open={open}
        onClose={handleAgree}
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle disableTypography>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h6">{t("Terms and Conditions")}</Typography>
            {LANGUAGE_SELECT && <LanguageSelect color />}
          </Box>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {`${t("Please read the following carefully")}. ${t(
              "If you have seen, and agreed, to this before, there may have be some minor changes."
            )}. ${t("You must agree to The Terms of Use, and Privacy Policy, to continue")}.`}
          </DialogContentText>
          <Tabs value={tab} onChange={handleTabs}>
            <Tab value={ETabs.TermsOfUse} label={t("Terms of Use")} />
            <Tab value={ETabs.Privacy} label={t("Privacy Policy")} />
          </Tabs>
          <Box mt={2} minHeight={500} height="100%" position="relative">
            {tab === ETabs.TermsOfUse && (
              <iframe
                src={`https://stintellyncsharedprod.blob.core.windows.net/legal/${
                  LANGUAGE_SELECT ? code.substr(0, 2) : "en"
                }/feedconnect/TermsOfUse.pdf#view=FitH&toolbar=0&statusbar=0&messages=0&navpanes=0`}
                style={{ position: "absolute", width: "100%", height: "100%" }}
                frameBorder={0}
              ></iframe>
            )}
            {tab === ETabs.Privacy && (
              <iframe
                src={`https://stintellyncsharedprod.blob.core.windows.net/legal/${
                  LANGUAGE_SELECT ? code.substr(0, 2) : "en"
                }/feedconnect/Privacy.pdf#view=FitH&toolbar=0&statusbar=0&messages=0&navpanes=0`}
                style={{ position: "absolute", width: "100%", height: "100%" }}
                frameBorder={0}
              ></iframe>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDisagree} color="primary" endIcon={<Clear />}>
            {t("No, I do not Agree. Log me out")}
          </Button>
          <Button onClick={handleAgree} color="secondary" variant="contained" endIcon={<Check />}>
            {t("Yes, I Agree to Both. Continue")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
