import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import useLanguage from "../../hooks/useLanguage";

interface Props {
  onConfirm: (value?: string) => void;
  onCancel: () => void;
  message: string;
  title?: string;
  positiveText?: string;
  negativeText?: string;
  positiveColor?: string;
  open: any;
}

const Confirm = ({
  onConfirm,
  onCancel,
  message,
  open = false,
  title,
  positiveText,
  negativeText,
  positiveColor,
}: Props) => {
  const { t } = useLanguage();
  const handleOk = () => {
    onConfirm();
  };

  return (
    <Dialog disableBackdropClick disableEscapeKeyDown maxWidth="xs" open={open}>
      <DialogTitle>{title || t("Confirm")}</DialogTitle>
      <DialogContent>{message}</DialogContent>
      <DialogActions>
        {negativeText !== "none" && (
          <Button onClick={onCancel} color="primary">
            {negativeText || t("Cancel")}
          </Button>
        )}
        <Button
          onClick={handleOk}
          color="primary"
          variant="contained"
          style={positiveColor ? { backgroundColor: positiveColor } : {}}
        >
          {positiveText || t("OK")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Confirm;
