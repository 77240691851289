import React, { useState, Fragment, useEffect, useContext } from "react";
import clsx from "clsx";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Settings from "@material-ui/icons/Settings";
import AccountCircle from "@material-ui/icons/AccountCircle";
import SettingsEthernet from "@material-ui/icons/SettingsEthernet";
import Equalizer from "@material-ui/icons/Equalizer";
import TrendingUp from "@material-ui/icons/TrendingUp";
import Timeline from "@material-ui/icons/Timeline";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import NaturePeople from "@material-ui/icons/NaturePeople";
import SwapHoriz from "@material-ui/icons/SwapHoriz";
import AddLocation from "@material-ui/icons/AddLocation";
import { NavLink, useLocation } from "react-router-dom";
import useStyles from "./style";
import { CSSProperties } from "@material-ui/styles";
import useLanguage from "../../hooks/useLanguage";
import User from "../../Components/User";
import {
  Home,
  Silo,
  Ingredients,
  FeedManagement,
  Loads,
  LoadIn,
  LoadOut,
  Recipes,
  Pens,
  Groups,
  Costings,
  Tasks,
  NorFor,
  Bucket,
  Contractor,
  Wageningen,
  MilkJar,
} from "../../Components/Icons";

import FlexRow from "../../Components/Flex/FlexRow";
import useFarm from "../../hooks/useFarm";
import useMainUser from "../../hooks/useMainUser";
import Exposure from "@material-ui/icons/Exposure";
import People from "@material-ui/icons/People";
import { Apps, CameraAlt, CreditCard, LocalShipping, PlaylistAddCheck, Room, ViewComfy } from "@material-ui/icons";
import useFarmModules, { EModules } from "../../hooks/useFarmModules";
import { useAppInsightsContext, useTrackEvent, useTrackMetric } from "@microsoft/applicationinsights-react-js";

import { Box, LinearProgress, ListSubheader } from "@material-ui/core";
import useFarmDetails from "../../Features/FarmDetails/useFarmDetails";
import useRole from "../../hooks/useRole";

type SubMenuProps = {
  children: any;
  text: string;
  icon: any;
  menuExpanded: boolean;
};

type NavigationLinkProps = {
  icon: any;
  text: string;
  link: string;
};

const activeStyle: CSSProperties = {
  fontWeight: "bold",
  backgroundColor: "#ddd",
};

const BREAKPOINT_IPAD = 769;

export default function SideMenu() {
  const [menuExpanded, setMenuExpanded] = useState(true);
  const screenResizedLarger = useMediaQuery(`(min-width:${BREAKPOINT_IPAD.toString()}px)`);
  const QFarmModules = useFarmModules();
  const { QFarmDetails } = useFarmDetails();
  const isMainUser = useMainUser();
  const { isAdmin } = useRole();
  const toggleMenuExpanded = () => {
    setMenuExpanded((prevState) => !prevState);
  };
  useEffect(() => {
    if (screenResizedLarger) {
      setMenuExpanded(true);
    } else {
      setMenuExpanded(false);
    }
  }, [screenResizedLarger]);

  const classes = useStyles();
  const { t } = useLanguage();
  const [farm] = useFarm();

  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: menuExpanded,
        [classes.drawerClose]: !menuExpanded,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: menuExpanded,
          [classes.drawerClose]: !menuExpanded,
        }),
      }}
    >
      <Box
        maxHeight="100vh"
        height="100vh"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        position="relative"
      >
        <Box display="flex" flexDirection="column" justifyContent="stretch" flexGrow={1}>
          <Box>
            <div className={classes.toolbar} />
            <FlexRow justifyContent={menuExpanded ? "flex-end" : "center"}>
              <IconButton color="inherit" aria-label="open drawer" onClick={toggleMenuExpanded}>
                {menuExpanded ? <ChevronLeft /> : <ChevronRight />}
              </IconButton>
            </FlexRow>
          </Box>
          <Box flexGrow={1} overflow="auto">
            {/*
             * THIS IS AN IDEA TO MOVE MODULES OUT OF THE FARM OBJECT AND USE THE QUERY RESPONSE DIRECTLY
             * THIS SHOULD BE MORE SCALABLE
             */}
            {QFarmDetails.isLoading ? (
              <LinearProgress />
            ) : QFarmModules.has(EModules.NIR) && QFarmDetails.data?.licenseLevel === "NIR - FeedConnect" ? (
              <>
                <List style={{ paddingBottom: 0 }}>
                  <NavigationLink icon={<Home />} text={t("Home")} link="/" />
                  <NavigationLink icon={<AccountCircle />} text={t("Farm Details")} link="/settings/details" />
                  <ListSubheader>{t("Scans")}</ListSubheader>
                  <List style={{ marginLeft: 24, padding: 0 }}>
                    <NavigationLink icon={<Pens />} text={t("Pens/TMR")} link="/nir/pens" />
                    <NavigationLink icon={<Ingredients />} text={t("Feedstuffs")} link="/nir/feedstuffs" />
                    <NavigationLink icon={<CameraAlt />} text={t("Quick Scans")} link="/nir/quickscans" />
                  </List>
                  <ListSubheader>{t("Management")}</ListSubheader>
                  <List style={{ marginLeft: 24, padding: 0 }}>
                    <NavigationLink icon={<Pens />} text={t("Pens/TMR")} link="/nir/manage/pens/" />
                    <NavigationLink icon={<Ingredients />} text={t("Feedstuffs")} link="/nir/manage/feedstuffs/" />
                  </List>
                </List>
              </>
            ) : (
              <List>
                <NavigationLink icon={<Home />} text={t("Home")} link="/" />
                {/*
                 * THIS IS AN IDEA TO MOVE MODULES OUT OF THE FARM OBJECT AND USE THE QUERY RESPONSE DIRECTLY
                 * THIS SHOULD BE MORE SCALABLE
                 */}
                {QFarmModules.has(EModules.NIR) && (
                  <SubMenu menuExpanded={menuExpanded} text={t("NIR")} icon={<CameraAlt />}>
                    <NavigationLink icon={<Pens />} text={t("Pens/TMR")} link="/nir/pens" />
                    <NavigationLink icon={<Ingredients />} text={t("Feedstuffs")} link="/nir/feedstuffs" />
                    <NavigationLink icon={<CameraAlt />} text={t("QuickScans")} link="/nir/quickscans" />
                  </SubMenu>
                )}

                <SubMenu menuExpanded={menuExpanded} text={t("Feed Management")} icon={<FeedManagement />}>
                  <NavigationLink icon={<Ingredients />} text={t("Ingredients")} link="/ingredients" />
                  {farm.isCC && Boolean(farm.isConverted) ? (
                    <NavigationLink icon={<Recipes />} text={t("Rations")} link="/rations" />
                  ) : (
                    <NavigationLink icon={<Recipes />} text={t("Recipes")} link="/recipes" />
                  )}
                  {/* <NavigationLink icon={<PlaylistAddCheck />} text={t("Feedplans")} link="/feedmanagement/feedplans" /> */}

                  <NavigationLink icon={<Pens />} text={t("Pens")} link="/pens" />
                  {QFarmModules.has(EModules.CC) && (
                    <NavigationLink icon={<Bucket />} text={t("Concentrates")} link="/feedmanagement/concentrates" />
                  )}
                  {farm.isCC && (
                    <NavigationLink icon={<People />} text={t("Operators")} link="/feedmanagement/operators" />
                  )}
                </SubMenu>

                <SubMenu menuExpanded={menuExpanded} text={t("Loads/Precision")} icon={<Loads />}>
                  <NavigationLink icon={<Loads />} text={t("Loads")} link="/loading/loads" />
                  <NavigationLink icon={<LoadIn />} text={t("Loaded Mixes")} link="/loading/loadedmixes" />
                  <NavigationLink icon={<LoadOut />} text={t("Fed Mixes")} link="/loading/fedmixes" />
                  <NavigationLink icon={<Exposure />} text={t("Mix Precision")} link="/loading/mixprecision" />
                </SubMenu>

                <SubMenu menuExpanded={menuExpanded} text={t("Analysis")} icon={<TrendingUp />}>
                  {/* <NavigationLink icon={<Timeline />} text={t("Standard Graphs")} link="/graphs" /> */}
                  <NavigationLink icon={<ViewComfy />} text={t("Intake")} link="/analysis/intake" />
                  <NavigationLink icon={<MilkJar />} text={t("Physical Data")} link="/analysis/physical-data" />
                  <NavigationLink icon={<Timeline />} text={t("Nutrient Data")} link="/analysis/nutrient-data" />
                  {/* <NavigationLink icon={<ViewComfy />} text={t("Summary")} link="/analysis/summary-data" /> */}
                  <NavigationLink icon={<Timeline />} text={t("Feed History")} link="/analysis/feed-history" />
                  <NavigationLink icon={<Equalizer />} text={t("Custom Graphs")} link="/customgraphs" />
                </SubMenu>

                {farm.isCC && farm.isStockControl && (
                  <SubMenu menuExpanded={menuExpanded} text={t("Stock Management")} icon={<Silo />}>
                    <NavigationLink icon={<NaturePeople />} text={t("Fields")} link="/stock/fields" />
                    <NavigationLink icon={<Ingredients />} text={t("Products")} link="/stock/products" />
                    <NavigationLink icon={<Silo />} text={t("Stocks/Silos")} link="/stock/stocks" />
                    <NavigationLink icon={<SwapHoriz />} text={t("Weigh In/Out")} link="/stock/weighedins" />
                    <NavigationLink icon={<AddLocation />} text={t("Yield")} link="/stock/yield" />
                    <NavigationLink icon={<LocalShipping />} text={t("Drivers")} link="/stock/drivers" />
                  </SubMenu>
                )}
                <NavigationLink icon={<Tasks />} text={t("Tasks")} link="/tasks" />
                <NavigationLink icon={<Costings />} text={t("Costings")} link="/costings" />

                <SubMenu menuExpanded={menuExpanded} text={t("Settings")} icon={<Settings />}>
                  <NavigationLink icon={<AccountCircle />} text={t("Farm Details")} link="/settings/details" />
                  {isMainUser || isAdmin ? (
                    <NavigationLink icon={<CreditCard />} text={t("My Account")} link="/settings/finance" />
                  ) : null}
                  <NavigationLink icon={<Apps />} text={t("Modules")} link="/settings/modules" />
                  <NavigationLink icon={<Groups />} text={t("Groups")} link="/groups" />
                  <NavigationLink icon={<Room />} text={t("Locations")} link="/settings/locations" />
                  <NavigationLink icon={<People />} text={t("Sub-Users")} link="/settings/subusers" />
                  <NavigationLink icon={<Contractor />} text={t("Contractors")} link="/settings/contractors" />
                  <NavigationLink icon={<SettingsEthernet />} text={t("Connections")} link="/settings/connections" />
                  {farm.isNorFor && (
                    <NavigationLink icon={<NorFor opacity={0.5} />} text={t("NorFor")} link="/settings/norfor" />
                  )}
                  {farm.isWageningen && (
                    <NavigationLink
                      icon={<Wageningen opacity={0.5} />}
                      text={t("Wageningen")}
                      link="/settings/wageningen"
                    />
                  )}
                </SubMenu>
              </List>
            )}
          </Box>
        </Box>
        <Box>
          <Divider />
          <User menuExpanded={menuExpanded} />
        </Box>
      </Box>
    </Drawer>
  );
}

const NavigationLink = ({ icon, link, text }: NavigationLinkProps) => {
  const classes = useStyles();
  const [isActive, setIsActive] = useState(false);
  const loc = useLocation();
  const [farm] = useFarm();
  const appInsights = useAppInsightsContext();
  const tracker = useTrackMetric(appInsights, link);
  const trackerEvent = useTrackEvent(appInsights, link, farm.farmId);

  const track = () => {
    tracker();
    trackerEvent(farm.farmName);
  };

  useEffect(() => {
    setIsActive(loc && loc.pathname && link !== "/" ? loc.pathname.startsWith(link) : false);
  }, [loc, link]);

  return (
    <NavLink to={link} className={classes.navLink} onClick={track}>
      <ListItem button style={isActive ? activeStyle : {}}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText style={{ whiteSpace: "nowrap" }} primary={text} />
      </ListItem>
    </NavLink>
  );
};

const SubMenu = ({ children, text, icon, menuExpanded }: SubMenuProps) => {
  const [open, setOpen] = useState(false);
  const loc = useLocation();
  const classes = useStyles();
  const screenResizedLarger = useMediaQuery(`(min-width:${BREAKPOINT_IPAD.toString()}px)`);

  const handleClick = () => setOpen((state) => !state);

  useEffect(() => {
    (children.length ? children : [children]).forEach((c: any) => {
      if (loc.pathname.startsWith(c?.props?.link)) setOpen(true);
    });
  }, [children, loc]);

  return (
    <Fragment>
      <ListItem button onClick={handleClick} className={clsx({ [classes.submenuLinkAnchor]: !menuExpanded && open })}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText style={{ whiteSpace: "nowrap" }} primary={text} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List
          dense={!screenResizedLarger}
          component="div"
          disablePadding
          className={clsx(classes.nested, {
            [classes.nestedClosed]: !menuExpanded,
            [classes.submenuLinks]: !menuExpanded,
          })}
        >
          {children}
        </List>
      </Collapse>
    </Fragment>
  );
};
