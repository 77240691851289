import { useQuery, queryCache, useMutation } from "react-query";
import useFarm from "../../hooks/useFarm";
import useAxios from "../../hooks/useAxios";

import useLanguage from "../../hooks/useLanguage";
import { IFarmDetails } from "./types";
import useToast from "../../hooks/useToast";

const useFarmDetails = () => {
  const [farm, setFarm] = useFarm();
  const { axios } = useAxios();
  const { t } = useLanguage();
  const toast = useToast();

  const QFarmDetails = useQuery<IFarmDetails, [string, string | undefined]>(
    ["farmdetails", farm?.farmId],
    async () => await axios.get(`farms/details/${farm.farmId}`),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1000 * 60 * 5,
      staleTime: 1000 * 60 * 5,
      enabled: !!farm?.farmId,
    }
  );

  const [mutate, saveState] = useMutation((_data: IFarmDetails) => axios.post(`farms/details/${farm.farmId}`, _data), {
    onMutate: (res) => {},
    onError: (err, variables, previousValue) => {
      toast.error(t("ERROR - Can't save details at this time"));
    },
    onSuccess: (res) => {
      toast.success(t("Details saved successfully"));
      queryCache.invalidateQueries(["modules", farm.farmId]);
      queryCache.invalidateQueries(["farmdetails", farm.farmId]);
    },
  });

  return {
    QFarmDetails,
    update: {
      mutate,
      saveState,
    },
  };
};

export default useFarmDetails;
