import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

interface Props {
  color?: string;
  opacity?: number;
}

const Groups = ({ color = "#000", opacity = 0.7 }: Props) => {
  return (
    <SvgIcon>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path
          d="M401.7,395.3c1.1-2.1,2.7-4,3.3-6.2c2.8-9.9,5.2-20,8-29.9c0.6-2.3,1.7-4.9,3.4-6.3c2.2-1.8,5.5-3.8,7.8-3.3
				c2.7,0.6,6.2,3.3,7,5.8c2.2,7,1.3,14.5-4.2,19.7c-2.8,2.6-7.9,2.8-12,4.1c9.4,6.9,4.4,16.8,1.8,25.9c-4.9,17.1-10.8,34-16,51
				c-0.9,3-0.7,6.6-0.2,9.9c0.9,6.5,2.9,12.9,3.3,19.4c0.3,6.4-3.2,9.4-9.7,8.6c-4.5-0.5-9.1-1.9-13.2-3.7
				c-12.9-5.7-25.6-5.6-38.5-0.2c-4.4,1.8-9.1,3.4-13.8,3.8c-6.5,0.6-9.9-2.4-9.6-8.8c0.3-6.3,2.5-12.4,3.4-18.7
				c0.5-3.5,0.8-7.3-0.2-10.6c-5.9-20.5-11.7-41-18.6-61.2c-2.6-7.6,2.4-11.4,4.7-15.5c-4.3-1.4-10.2-1.4-12.2-4.3
				c-3.1-4.5-4.7-10.9-4.7-16.4c0-3,4-7.2,7.2-8.8c4.1-2,8,1,9.4,5.1c3.6,11.1,6.6,22.3,9.7,33.5c0.8,3.1,0.8,6.6,3.9,6.5
				c0-8.8,0.9-17.2-0.3-25.3c-1-7.3-3.6-14.5-6.6-21.2c-4.4-9.5-9.1-10.6-18.6-6.3c-24.1,10.9-46.7,8.7-67.5-8.1
				c-0.7-0.6-1.4-1.1-2-1.7c-13.9-13-12.3-24.4,5.1-32c8-3.5,16.7-5.5,25.2-7.4c10.3-2.4,18.4,2.4,25.6,9.2
				c4.5,4.2,8.9,8.4,13.3,12.5c4.6-13.4,10-17.2,24.4-18.1c2.4-0.1,5.2-1.8,6.9-3.6c9.3-10.1,19.3-18.9,34.2-19
				c13.7-0.1,23.9,7,32.3,16.8c3.4,3.9,6.6,5.9,12.1,6.1c12.4,0.4,17.7,5.3,22.1,19.5c5.6-6,10.5-11.6,15.8-16.7
				c5.6-5.5,12.5-8.1,20.5-7.1c11.9,1.4,23.4,4.4,33.6,11.1c9.4,6.2,10.8,12.4,4.3,21.7c-10.3,14.8-25.3,21.5-42.7,23.1
				c-11.7,1.1-22.8-1.7-33.3-6.8c-7.8-3.8-12.3-2.6-16.6,4.8c-6.9,12-9.7,24.9-7.9,38.8c0.4,3.1-0.9,6.3-1.4,9.5
				C400.7,394.7,401.2,395,401.7,395.3z M332.3,481.7c2-3.6,4-6.4,5.2-9.6c2.7-7,6-9.4,13.5-8.9c4.6,0.3,9.3,1.4,13.8,0.9
				c15.3-1.7,15.2-1.9,22.3,12.2c0.2,0.4,0.3,0.9,0.7,1.1c1.4,1,2.9,2.7,4.2,2.6c1.2-0.1,2.5-2.2,3-3.7c3-7.8-1.1-16.7-10-19.4
				c-15.6-4.7-31.4-4.8-46.9,0.2C327.3,460.6,324.3,471.7,332.3,481.7z"
        />
        <path d="M381.8,498.5c-11.8,11.4-29,11.4-39.9,0.3C350.2,488.4,370.3,488.1,381.8,498.5z" />
        <path
          fill="#fff"
          d="M332.3,481.7c-8-10-5.1-21.2,5.7-24.6c15.5-4.9,31.4-4.8,46.9-0.2c8.8,2.7,13,11.5,10,19.4
				c-0.6,1.5-1.9,3.6-3,3.7c-1.3,0.1-2.8-1.6-4.2-2.6c-0.3-0.2-0.5-0.7-0.7-1.1c-7-14.1-7-13.8-22.3-12.2c-4.5,0.5-9.2-0.6-13.8-0.9
				c-7.5-0.5-10.8,2-13.5,8.9C336.3,475.3,334.3,478.1,332.3,481.7z"
        />
        <path
          fill="#fff"
          d="M373.7,346c0,28.6-5.6,79.2-12.5,79.2c-6.9,0-12.5-50.6-12.5-79.2s5.6-32.9,12.5-32.9
			C368.1,313.1,373.7,317.4,373.7,346z"
        />

        <path
          d="M224.3,378c1.3-2.4,3.2-4.7,3.9-7.4c3.3-11.8,6.1-23.7,9.4-35.5c0.7-2.7,2-5.8,4-7.4c2.6-2.1,6.5-4.5,9.3-3.9
				c3.2,0.7,7.4,3.9,8.3,6.9c2.6,8.3,1.5,17.2-4.9,23.4c-3.3,3.1-9.4,3.3-14.3,4.9c11.2,8.2,5.2,19.9,2.2,30.7
				c-5.8,20.3-12.8,40.2-19,60.5c-1.1,3.6-0.8,7.9-0.3,11.7c1,7.7,3.5,15.3,3.9,23c0.4,7.6-3.8,11.1-11.5,10.2
				c-5.3-0.6-10.8-2.2-15.7-4.4c-15.3-6.7-30.3-6.6-45.6-0.2c-5.2,2.2-10.8,4-16.4,4.5c-7.7,0.7-11.7-2.8-11.4-10.4
				c0.4-7.4,3-14.7,4-22.2c0.6-4.1,0.9-8.7-0.2-12.5c-7-24.3-13.9-48.6-22-72.6c-3-9,2.9-13.5,5.5-18.4c-5.1-1.6-12.1-1.7-14.5-5.1
				c-3.7-5.3-5.5-12.9-5.6-19.5c0-3.6,4.7-8.5,8.5-10.4c4.8-2.4,9.5,1.2,11.1,6c4.2,13.1,7.8,26.4,11.5,39.7c1,3.6,0.9,7.9,4.6,7.7
				c0-10.4,1-20.4-0.3-30c-1.2-8.6-4.2-17.2-7.9-25.2c-5.2-11.3-10.8-12.6-22-7.5c-28.6,12.9-55.3,10.4-80-9.5
				c-0.8-0.7-1.7-1.3-2.4-2c-16.4-15.4-14.6-29,6-37.9c9.5-4.1,19.8-6.5,29.9-8.8c12.2-2.8,21.8,2.9,30.4,10.9
				c5.3,5,10.6,10,15.8,14.9c5.5-15.9,11.9-20.4,28.9-21.4c2.8-0.2,6.2-2.1,8.2-4.2c11.1-12,22.9-22.4,40.5-22.5
				c16.2-0.1,28.3,8.3,38.3,19.9c4,4.7,7.8,7,14.3,7.2c14.7,0.5,21,6.2,26.2,23.1c6.6-7.1,12.4-13.7,18.7-19.8
				c6.7-6.5,14.8-9.6,24.3-8.5c14.2,1.7,27.8,5.2,39.8,13.1c11.2,7.3,12.8,14.7,5.1,25.7c-12.2,17.6-30,25.5-50.7,27.4
				c-13.9,1.3-27-2-39.5-8.1c-9.2-4.5-14.6-3.1-19.7,5.7c-8.1,14.2-11.6,29.5-9.3,46c0.5,3.6-1.1,7.5-1.7,11.3
				C223.1,377.3,223.7,377.6,224.3,378z M142,480.4c2.3-4.3,4.7-7.6,6.1-11.4c3.2-8.3,7.2-11.1,16-10.6c5.5,0.3,11,1.7,16.3,1.1
				c18.1-2,18.1-2.3,26.4,14.4c0.2,0.5,0.4,1.1,0.8,1.3c1.6,1.2,3.4,3.3,4.9,3.1c1.4-0.1,2.9-2.6,3.6-4.4c3.6-9.3-1.4-19.8-11.8-23
				c-18.4-5.5-37.3-5.7-55.6,0.2C136,455.3,132.6,468.6,142,480.4z"
        />
        <path d="M200.7,500.3c-14,13.5-34.4,13.5-47.3,0.4C163.2,488.3,187.1,488,200.7,500.3z" />
        <path
          fill="#fff"
          d="M142,480.4c-9.5-11.9-6-25.1,6.7-29.1c18.4-5.9,37.2-5.7,55.6-0.2c10.5,3.2,15.4,13.7,11.8,23
				c-0.7,1.8-2.2,4.2-3.6,4.4c-1.5,0.2-3.3-1.9-4.9-3.1c-0.4-0.3-0.5-0.9-0.8-1.3c-8.3-16.7-8.3-16.4-26.4-14.4
				c-5.3,0.6-10.9-0.8-16.3-1.1c-8.8-0.5-12.8,2.3-16,10.6C146.7,472.8,144.4,476.1,142,480.4z"
        />
        <path
          fill="#fff"
          d="M191.1,319.5c0,33.9-6.6,93.9-14.8,93.9s-14.8-60-14.8-93.9s6.6-39,14.8-39S191.1,285.6,191.1,319.5z"
        />

        <path
          d="M307.7,172.1c1.4-2.6,3.4-5.1,4.2-7.9c3.5-12.6,6.6-25.4,10.1-38.1c0.8-2.9,2.2-6.2,4.3-8c2.8-2.3,7-4.8,9.9-4.2
				c3.4,0.7,7.9,4.2,8.9,7.4c2.8,8.9,1.6,18.4-5.3,25c-3.5,3.4-10,3.6-15.3,5.2c12,8.8,5.6,21.4,2.3,32.9
				c-6.2,21.8-13.8,43.2-20.3,64.8c-1.2,3.9-0.8,8.4-0.3,12.6c1.1,8.2,3.7,16.4,4.2,24.6c0.4,8.1-4.1,11.9-12.3,11
				c-5.7-0.6-11.5-2.4-16.8-4.7c-16.4-7.2-32.5-7.1-48.9-0.3c-5.6,2.3-11.6,4.3-17.6,4.9c-8.2,0.8-12.6-3-12.2-11.2
				c0.4-8,3.2-15.8,4.3-23.8c0.6-4.4,1-9.3-0.2-13.5c-7.5-26.1-14.9-52.2-23.6-77.8c-3.3-9.6,3.1-14.5,6-19.7
				c-5.4-1.7-13-1.8-15.5-5.5c-4-5.7-5.9-13.8-6-20.9c0-3.8,5-9.1,9.1-11.1c5.2-2.6,10.2,1.3,11.9,6.4c4.5,14.1,8.4,28.3,12.3,42.6
				c1.1,3.9,1,8.5,5,8.2c0-11.2,1.1-21.9-0.3-32.2c-1.3-9.2-4.5-18.5-8.4-27c-5.6-12.1-11.5-13.5-23.6-8.1
				c-30.6,13.8-59.3,11.1-85.8-10.2c-0.9-0.7-1.8-1.4-2.6-2.2C67.6,75.1,69.5,60.6,91.6,51c10.1-4.4,21.2-7,32.1-9.5
				c13.1-3,23.4,3.1,32.6,11.7c5.7,5.3,11.4,10.7,16.9,15.9c5.9-17,12.8-21.9,31-23c3-0.2,6.6-2.2,8.8-4.5
				c11.9-12.9,24.6-24,43.4-24.1c17.4-0.1,30.3,8.9,41.1,21.4c4.3,5,8.4,7.5,15.3,7.8c15.8,0.5,22.5,6.7,28.1,24.8
				c7.1-7.6,13.3-14.7,20.1-21.3c7.2-6.9,15.9-10.3,26.1-9.1c15.2,1.8,29.8,5.6,42.7,14c12,7.8,13.7,15.7,5.5,27.6
				c-13,18.8-32.1,27.3-54.3,29.3c-14.9,1.3-28.9-2.1-42.3-8.7c-9.9-4.8-15.7-3.3-21.1,6.1c-8.7,15.3-12.4,31.6-10,49.3
				c0.5,3.9-1.2,8.1-1.8,12.1C306.4,171.4,307.1,171.8,307.7,172.1z M219.5,282c2.5-4.6,5-8.2,6.6-12.2c3.5-8.8,7.7-11.9,17.1-11.4
				c5.9,0.4,11.8,1.8,17.5,1.2c19.4-2.2,19.4-2.4,28.3,15.4c0.2,0.5,0.4,1.1,0.8,1.4c1.7,1.3,3.7,3.5,5.3,3.3
				c1.5-0.2,3.1-2.8,3.9-4.7c3.9-10-1.5-21.3-12.7-24.6c-19.8-5.9-40-6.1-59.6,0.2C213.1,255.1,209.4,269.3,219.5,282z"
        />
        <path d="M282.4,303.3c-15,14.5-36.9,14.5-50.8,0.4C242.2,290.5,267.8,290.1,282.4,303.3z" />
        <path
          fill="#fff"
          d="M219.5,282c-10.1-12.7-6.4-26.9,7.2-31.2c19.7-6.3,39.9-6.2,59.6-0.2c11.2,3.4,16.5,14.7,12.7,24.6
				c-0.7,1.9-2.4,4.5-3.9,4.7c-1.6,0.2-3.6-2.1-5.3-3.3c-0.4-0.3-0.6-0.9-0.8-1.4c-8.9-17.9-8.9-17.6-28.3-15.4
				c-5.7,0.6-11.7-0.8-17.5-1.2c-9.5-0.6-13.7,2.5-17.1,11.4C224.5,273.8,222,277.4,219.5,282z"
        />

        <path
          fill="#fff"
          d="M272.1,109.4c0,36.4-7.1,100.7-15.9,100.7c-8.8,0-15.9-64.3-15.9-100.7s7.1-41.8,15.9-41.8
			C265,67.6,272.1,73.1,272.1,109.4z"
        />
      </svg>
    </SvgIcon>
  );
};

export default Groups;
