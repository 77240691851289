import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import RefreshIcon from "@material-ui/icons/Refresh";
import React from "react";
import Logo from "../Components/Logo";
import useLanguage from "../hooks/useLanguage";
import AnimatedBackdrop from "./AnimatedBackdrop";
import useStyles from "./styles";

const ErrorFallback = () => {
  const { t } = useLanguage();
  const classes = useStyles();

  const doReload = () => window.location.reload();

  return (
    <AnimatedBackdrop>
      <Box
        p={2}
        width="100%"
        maxWidth={400}
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
      >
        <Logo />
        <Typography variant="h4" component="div" className={classes.prompt}>
          {t("There appears to be an issue with the site")}.
        </Typography>
        <Box p={2} width="100%">
          <p>{t("Please reload to ensure you have the latest version")}.</p>
        </Box>
        <Button variant="contained" color="primary" size="large" onClick={doReload} startIcon={<RefreshIcon />}>
          {t("RELOAD")}
        </Button>
      </Box>
    </AnimatedBackdrop>
  );
};

export default ErrorFallback;
