import React from "react";
import useLanguage from "../hooks/useLanguage";
import useStyles from "./styles";

interface Props {
  error: any;
}

const LoginError = ({ error }: Props) => {
  const { t } = useLanguage();
  const classes = useStyles();
  console.error(error);
  return (
    <p style={{ margin: "32px 0", textAlign: "center" }}>
      {error.errorCode === "endpoints_resolution_error"
        ? t("Please check your network connection and try again")
        : t("There was a problem signing in, please click Login, below, to try again.")}
    </p>
  );
};

export default LoginError;
