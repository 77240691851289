import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

interface Props {
  color?: string;
  opacity?: number;
}

const Loads = ({ color = "#7e7e7e", opacity = 0.7 }: Props) => {
  return (
    <SvgIcon>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 868 704">
        <circle cx="561.28" cy="579" r="105.47" style={{ fill: "none", stroke: color, strokeWidth: 40 }} />
        <polyline
          points="20 633.04 74.04 633.04 126.34 588.59 404.39 588.59"
          style={{ fill: "none", stroke: color, strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: 40 }}
        />
        <polyline
          points="471.71 108.46 88.86 91.76 190.84 528.45"
          style={{ fill: "none", stroke: color, strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: 40 }}
        />
        <polyline
          points="719.92 588.59 771.34 588.59 848.05 124.88 820.41 123.67"
          style={{ fill: "none", stroke: color, strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: 40 }}
        />
        <path
          d="M555.7,479.73c-35.63-25.43-88.85-59.55-126.35-66.49C440.67,304.11,541.7,138.49,654.66,99.33L620.89,33.4,884.61,20.53,780.72,289.1l-41.16-57C645.78,274.64,589.11,353.13,555.7,479.73Z"
          transform="translate(-65.98 -20.53)"
          style={{ fill: color }}
        />
      </svg>
    </SvgIcon>
  );
};

export default Loads;
