import { MuiPickersUtilsProvider } from "@material-ui/pickers";

import DateFnsUtils from "@date-io/date-fns";
import React from "react";

function DateTimePickers(props: React.PropsWithChildren<{}>) {
  return <MuiPickersUtilsProvider utils={DateFnsUtils}>{props.children}</MuiPickersUtilsProvider>;
}

export default DateTimePickers;
