import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

interface Props {
  color?: string;
  opacity?: number;
}

const Wageningen = ({ color = "#000", opacity = 0.7 }: Props) => {
  return (
    <SvgIcon>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
        <path
          style={{ color, opacity }}
          d="M780.1,574.3l184.5,11.1l30.5,32.2l-8.8,28.1l-6.4,7.4l3.9,5.1l-34.4,24.6l-4.4,9.6l4.2,5.4
	l34.9,16.9l7.3,14.7l-20.9-3.8l12.6,14.9l-14.5-3.2l-1.6,2.7l7.2,9.1l-10.3,14.3l-0.2,0.9l-59.3,24.6l0.2,3l13.5,17.1l2.9,0.8
	l-19.4,21.3l-51.8,3.4l6-1.6l-0.4-0.8l-24.6-2.1l-4.5-3.8l-146.7-15.8l25.2-3.6l-69.9-3.1l1.7-3.4l-110.7-5.1l80.9-4.4l-172.6-3.5
	l395.8-13l-17.9-7.9L642,759.1l54.3-1.8l-0.7-1.2l0.1-0.3l-0.3-0.2l69.7-4.3L683,738.7l2.8-1.2l26.9-0.9l-290.9-2.5l93.6,1.8
	l-3.3,3.4l83.8,2l-60.9,4.5l91,2.9l-116.6,1.9l94.4,3.7l1.5,1.5l-257.5,17.3l21,6.3L215,794.9l5.8-3.7l-77.8,12.1l0.1-11.1l-21,0.4
	l-5-6.2l-12.4-4.7l-11.4,4.7l-35.1-10.9l-2-7.2l1-8.4l16.4-20l14.8-7.2l-38.5-2.8l-15.3-9.2l-0.5-7.5l-14.7-19.2L7,688.4l-3.8-19.3
	l4.7-4.7l-5.3-4.9L34.5,634l-10.3-4.8l-1.1-24.8l22.6-22.2l172.3-6.9l292.1-6.8L780.1,574.3z M789.9,778.9
	c6.3,0.3,12.5,1.8,19.5,1.2C802.6,777.6,796.2,778.5,789.9,778.9z"
        />
        <path
          style={{ color, opacity }}
          d="M217.9,575.4c0.3-144.6,0-289.2,0.1-433.8c0-7.6-1-6.8,6.5-6.8c183.1,0,366.2,0,549.3-0.1
	c5.1,0,6.2,1.3,6.2,6.3c-0.1,162.3-0.2,324.5,0,486.8c0,3.9-1,5.1-5,5.1c-59-0.1-118-0.1-177,0c-4.1,0-5.4-1-5.4-5.2
	c0.1-99.7-0.1-199.5,0.1-299.2c0-4-1.5-4.6-5-4.6c-59.1,0.1-118.2,0.1-177.3,0c-3.7,0-5.2,0.7-5.2,4.9c0.1,80.3,0.1,160.5,0.1,240.8
	c0,19.5-0.2,38.9,0,58.4c0,4-1.3,5-5.1,5c-59.1-0.1-118.2-0.1-177.2,0c-3.9,0-5.1-1-5-5C218.1,610.3,218,592.9,217.9,575.4z"
        />
      </svg>
    </SvgIcon>
  );
};

export default Wageningen;
