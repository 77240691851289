import React from "react";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/core/styles";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { defaultColors } from "../lib/constants";

const Theme = {
  fontFamily: ["Muli", "Calibri", "Roboto", '"Helvetica Neue"', "Arial", "sans-serif"],
  palette: {
    primary: {
      light: "rgb(87,195,255)", // 75% lightness on lab version of main, which is 57% color
      main: "rgb(0, 148, 210)",
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      light: "rgb(79,220,188)",
      main: "rgb(26,179,148)",
      // dark: will be calculated from palette.secondary.main,
      contrastText: "#fff",
    },
  },
};

type ThemeProps = {
  children: any;
};

export const ThemeCtx = React.createContext<any>(null);

const theme = ({ children }: ThemeProps) => {
  const theme = createTheme({
    typography: {
      fontFamily: Theme.fontFamily.join(","),
    },
    palette: Theme.palette,
    overrides: {
      //@ts-ignore
      MUIDataTableToolbar: {
        root: {
          alignItems: "flex-start",
          paddingTop: 8,
          minHeight: 64,
        },
      },
    },
  });

  return (
    <ThemeCtx.Provider value={{ theme, defaultColors }}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ThemeCtx.Provider>
  );
};

export default theme;
