import ErrorBoundary from "./ErrorBoundary";
import Routes from "./Routes";
import Farm from "./Farm";
import Refresh from "./Refresh";
import Confirm from "./Confirm";
import Cache from "./Cache";
import TimePeriod from "./TimePeriod";
import React, { memo, Suspense } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import PageStructure from "../Layout/PageStructure";
import SuspenseFallback from "../Screens/SuspenseFallback";
import ErrorFallback from "../Screens/ErrorFallback";
import { SnackbarProvider } from "notistack";
import Insights from "./Insights";
import { DndProvider } from "react-dnd";
import Backend from "react-dnd-html5-backend";
import { ReactQueryDevtools } from "react-query-devtools";
import Legal from "./Legal";
import DateTimePickers from "./DateTimePickers";

const Providers = () => {
  return (
    <Insights>
      <Refresh>
        <ReactQueryDevtools initialIsOpen={false} />
        <SnackbarProvider maxSnack={5}>
          <DateTimePickers>
            <Legal>
              <Router>
                <Confirm>
                  <Farm>
                    <Cache>
                      <ErrorBoundary fallback={<ErrorFallback />}>
                        <PageStructure>
                          <DndProvider backend={Backend}>
                            <TimePeriod>
                              <Suspense fallback={<SuspenseFallback />}>
                                <Routes />
                              </Suspense>
                            </TimePeriod>
                          </DndProvider>
                        </PageStructure>
                      </ErrorBoundary>
                    </Cache>
                  </Farm>
                </Confirm>
              </Router>
            </Legal>
          </DateTimePickers>
        </SnackbarProvider>
      </Refresh>
    </Insights>
  );
};

export default memo(Providers);
