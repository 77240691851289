import Box from "@material-ui/core/Box";
import LinearProgress from "@material-ui/core/LinearProgress";
import React from "react";
import Logo from "../Components/Logo";
import AnimatedBackdrop from "./AnimatedBackdrop";
import LogoAndSpinner from "./LogoAndSpinner";
interface Props {
  full?: boolean;
}
const SuspenseFallback = ({ full = false }: Props) => {
  return !full ? (
    <LogoAndSpinner back={false} />
  ) : (
    <AnimatedBackdrop>
      <Box
        p={2}
        maxWidth={400}
        width="100%"
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
      >
        <Logo />
        <Box p={2} width="100%">
          <LinearProgress color="primary" />
        </Box>
      </Box>
    </AnimatedBackdrop>
  );
};

export default SuspenseFallback;
