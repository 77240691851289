import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

interface Props {
  color?: string;
  opacity?: number;
  size?: "small" | "large" | "inherit" | "medium";
}

const Pit = ({ color = "#000", opacity = 0.7, size = "medium" }: Props) => {
  return (
    <SvgIcon fontSize={size}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800 800">
        <g opacity={opacity}>
          <g>
            <polygon fill="#5F5F5F" points="706.7,193.6 700.9,421.2 426.7,388.8 416.6,184" />
          </g>
          <g>
            <polygon fill="#828282" points="779,186.4 769.1,409.6 757.6,427.9 767.2,195.7" />
          </g>
          <g>
            <polygon fill="#959595" points="416.6,184 426.7,388.8 113.3,596.5 70.4,295.5" />
          </g>
          <g>
            <polygon fill="#5A5A5A" points="70.4,295.5 113.3,596.5 56,583 9.6,289.8" />
          </g>
          <g>
            <polygon
              fill="#717171"
              points="779,186.4 767.2,195.7 579.1,342.9 485.1,334.1 706.7,193.6 416.6,184 70.4,295.5 9.6,289.8 
					370.5,182.5 395.5,175 440.4,176.4 720.8,184.7"
            />
          </g>
          <g>
            <polygon fill="#828282" points="767.2,195.7 757.6,427.9 582,706.6 579.1,342.9" />
          </g>
          <g>
            <polygon fill="#5A5A5A" points="579.1,342.9 582,706.6 496.7,686.5 485.1,334.1" />
          </g>
        </g>
      </svg>
    </SvgIcon>
  );
};

export default Pit;
