import * as Yup from "yup";
import useLanguage from "../../hooks/useLanguage";

const useSchema = () => {
  const { t } = useLanguage();

  const email = Yup.string().email().required(t("A valid email is required"));
  const farmName = Yup.string().required(t("A name is required"));
  const currencyId = Yup.number().min(1, t("Choose a currency")).required(t("Choose a currency"));
  const countryId = Yup.number().min(1, t("Choose a country")).required(t("Choose a country"));
  const unitSystemId = Yup.number().required(t("Choose a Unit System"));
  const licenseId = Yup.number().min(1, t("Choose a licence")).required(t("Choose a licence"));
  const timeZoneId = Yup.string().required("Choose a timezone");

  return Yup.object().shape({
    email,
    farmName,
    currencyId,
    countryId,
    licenseId,
    unitSystemId,
    timeZoneId,
  });
};

export default useSchema;
