import makeStyles from "@material-ui/core/styles/makeStyles";

const drawerWidth = 240;
export default makeStyles((theme) => ({
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  navLink: {
    textDecoration: "none",
    color: "inherit",
  },
  submenuLinkAnchor: {
    backgroundColor: "#d1d1d1",
    "&:hover": {
      backgroundColor: "#ccc",
    },
  },
  submenuLinks: {
    backgroundColor: "#eaeaea",
    marginBottom: theme.spacing(1.5),
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  nestedClosed: {
    paddingLeft: theme.spacing(0),
  },
}));
