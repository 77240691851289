import React from "react";
import { useQuery } from "react-query";
import { IInvoice } from "../types/finance";
import useAxios from "./useAxios";
import useFarm from "./useFarm";
import useLanguage from "./useLanguage";
import useMainUser from "./useMainUser";
import useRole from "./useRole";
import useToast from "./useToast";

function useInvoices() {
  const { t } = useLanguage();
  const isMainUser = useMainUser();
  const { isAdmin } = useRole();
  const [farm] = useFarm();
  const { axios, cancelToken } = useAxios();
  const toast = useToast();

  const QInvoices = useQuery<IInvoice[], Error>(
    [`invoices`, farm.farmId],
    async () => {
      return await axios.get(`invoices/${farm.farmId}`, {
        cancelToken,
      });
    },
    {
      refetchOnWindowFocus: false,
      cacheTime: 1000 * 60 * 60,
      staleTime: 1000 * 60 * 60,
      enabled: !!farm?.farmId && (isMainUser || isAdmin),
      retry: 0,
    }
  );

  React.useEffect(() => {
    QInvoices.error && QInvoices.error.message !== "Changed Page" && toast.error(t("Can't find any data"));
  }, [QInvoices.error]);

  return {
    QInvoices,
  };
}

export default useInvoices;
