import React, { lazy } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import useFarm from "../hooks/useFarm";
import useMainUser from "../hooks/useMainUser";
import useRole from "../hooks/useRole";
const LiteProPremium = lazy(() => import("../Features/LiteProPremium"));
const Dashboard = lazy(() => import("../Features/Dashboard"));
const IngredientsPage = lazy(() => import("../Features/Ingredients"));
const PensPage = lazy(() => import("../Features/Pens2"));
const RecipesPage = lazy(() => import("../Features/Recipes/DG"));
const SharedRationsPage = lazy(() => import("../Features/Recipes/CC"));
const Groups = lazy(() => import("../Features/Groups"));
const Loads = lazy(() => import("../Features/Loads"));
const Tasks = lazy(() => import("../Features/Tasks"));
const GraphsCustom = lazy(() => import("../Features/GraphsCustom"));
const Costings = lazy(() => import("../Features/Costings"));
const FarmDetails = lazy(() => import("../Features/FarmDetails"));
const Connections = lazy(() => import("../Features/Connections"));
const NorForOptions = lazy(() => import("../Features/NorFor/NorForOptions"));
const Fields = lazy(() => import("../Features/StockControl/Fields"));
const Products = lazy(() => import("../Features/StockControl/Products"));
const Stocks = lazy(() => import("../Features/StockControl/Stocks"));
const Drivers = lazy(() => import("../Features/StockControl/Drivers"));
const WeighedIns = lazy(() => import("../Features/StockControl/WeighIn"));
const Yield = lazy(() => import("../Features/StockControl/Yield"));
const MixPrecision = lazy(() => import("../Features/MixPrecision/MixPrecision"));
const LoadedMixes = lazy(() => import("../Features/MixPrecision/LoadedMix"));
const FedMixes = lazy(() => import("../Features/MixPrecision/FedMixes"));
const SubUsers = lazy(() => import("../Features/SubUsers"));
const Machines = lazy(() => import("../Features/Machines"));
const Concentrates = lazy(() => import("../Features/Concentrates"));
const Feedplans = lazy(() => import("../Features/Feedplans"));
const Operators = lazy(() => import("../Features/Operators"));
const Wageningen = lazy(() => import("../Features/Wageningen"));
const NIRFeedStuffs = lazy(() => import("../Features/NIR/NIRFeedStuffs"));
const NIRPens = lazy(() => import("../Features/NIR/NIRPens"));
const NIRQuickScans = lazy(() => import("../Features/NIR/NIRQuickScans"));
const FeedStuffs = lazy(() => import("../Features/FeedStuffs"));
const Locations = lazy(() => import("../Features/Locations"));
const Modules = lazy(() => import("../Features/Modules"));
const Graphs2 = lazy(() => import("../Features/Graphs2"));
const Finance = lazy(() => import("../Features/Finance"));

const Routes = () => {
  const isMainUser = useMainUser();
  const { isAdmin } = useRole();
  const [farm] = useFarm();
  const loc = useLocation();

  React.useEffect(() => {
    (window as any).fcWidget?.user.setProperties({
      URL: loc?.pathname || "unknown",
    });
  }, [loc]);

  return (
    <Switch>
      <Route path="/machines">
        <Machines />
      </Route>
      <Route path="/pens">
        <LiteProPremium content={<PensPage />} name="FeedManagement:Pens" />
      </Route>
      <Route path="/feedmanagement/concentrates">
        <Concentrates />
        {/* <LiteProPremium content={<Concentrates />} name="FeedManagement:Concentrates" /> */}
      </Route>
      <Route path="/feedmanagement/feedplans">
        <Feedplans />
      </Route>
      <Route path="/groups">
        <LiteProPremium content={<Groups />} name="Settings:Groups" />
      </Route>
      <Route path="/settings/details">
        <LiteProPremium content={<FarmDetails />} name="Settings:FarmDetails" />
      </Route>
      {isMainUser || isAdmin ? (
        <Route path="/settings/finance">
          <Finance />
          {/* <LiteProPremium content={<Finance />} name="Settings:Finance" /> */}
        </Route>
      ) : null}
      <Route path="/loading/mixprecision">
        <LiteProPremium content={<MixPrecision />} name="LoadsPrecision:MixPrecision" />
      </Route>
      <Route path="/loading/loadedmixes">
        <LiteProPremium content={<LoadedMixes />} name="LoadsPrecision:LoadedMixes" />
      </Route>
      <Route path="/loading/fedmixes">
        <LiteProPremium content={<FedMixes />} name="LoadsPrecision:FedMixes" />
      </Route>
      <Route path="/loading/loads" exact>
        <LiteProPremium content={<Loads />} name="LoadsPrecision:Loads" />
      </Route>
      <Route path="/tasks/:id/graphs">
        <LiteProPremium content={<Graphs2 />} name="Graphs:StandardGraphs" />
      </Route>
      <Route path="/tasks">
        <LiteProPremium content={<Tasks />} name="Tasks" />
      </Route>
      <Route path="/analysis">
        <LiteProPremium content={<Graphs2 />} name="Graphs:StandardGraphs" />
      </Route>
      <Route path="/customgraphs">
        <LiteProPremium content={<GraphsCustom />} name="Graphs:CustomGraphs" />
      </Route>
      <Route path="/costings">
        <LiteProPremium content={<Costings />} name="Costings" />
      </Route>
      {/* NIR ONLY */}
      <Route path="/nir/pens">
        <LiteProPremium content={<NIRPens />} name="NIR:Pens" />
        {/* <NIRPens /> */}
      </Route>
      <Route path="/nir/feedstuffs">
        <LiteProPremium content={<NIRFeedStuffs />} name="NIR:FeedStuffs" />
        {/* <NIRFeedStuffs /> */}
      </Route>
      <Route path="/nir/quickscans">
        <LiteProPremium content={<NIRQuickScans />} name="NIR:QuickScans" />
        {/* <NIRQuickScans /> */}
      </Route>
      <Route path="/nir/manage/pens">
        <LiteProPremium content={<PensPage nir />} name="NIR:ManagePens" />
        {/* <PensPage nir /> */}
      </Route>
      <Route path="/nir/manage/feedstuffs">
        {/* <LiteProPremium content={<FeedStuffs nir url="/nir/manage/feedstuffs" />} name="NIR:ManageFeedStuffs" /> */}
        <FeedStuffs lite url="/nir/manage/feedstuffs" />
      </Route>
      {/* SETTINGS */}
      <Route path="/settings/connections">
        <LiteProPremium content={<Connections />} name="Settings:Connections" />
      </Route>
      <Route path="/settings/modules">
        <Modules />
      </Route>
      <Route path="/settings/norfor">
        {farm.isNorFor ? (
          <LiteProPremium content={<NorForOptions />} name="FeedManagement:Rations:NorFor" />
        ) : (
          <Redirect to="/" />
        )}
      </Route>
      <Route path="/settings/wageningen">{farm.isWageningen ? <Wageningen /> : <Redirect to="/" />}</Route>
      <Route path="/settings/subusers">
        <LiteProPremium content={<SubUsers />} name="Settings:Subusers" />
      </Route>
      <Route path="/settings/contractors">
        <LiteProPremium content={<SubUsers contractor />} name="Settings:Contractors" />
      </Route>
      <Route path="/settings/locations">
        <Locations />
      </Route>
      <Route path="/feedmanagement/operators">
        <LiteProPremium content={<Operators />} name="FeedManagement:Operators" />
      </Route>
      <Route path="/ingredients">
        <LiteProPremium content={<IngredientsPage />} name="FeedManagement:Ingredients" />
      </Route>
      <Route path="/feedmanagement/feedstuffs">
        <FeedStuffs url="/feedmanagement/feedstuffs" />
      </Route>

      <Route path="/recipes">
        {farm.isCC && farm.isConverted ? (
          <Redirect to="/rations" />
        ) : (
          <LiteProPremium content={<RecipesPage />} name="FeedManagement:Rations" />
        )}
      </Route>
      <Route path="/rations">
        {farm.isCC && farm.isConverted ? (
          <LiteProPremium content={<SharedRationsPage />} name="FeedManagement:Rations" />
        ) : (
          <Redirect to="/recipes" />
        )}
      </Route>
      <Route path="/stock/fields">
        <LiteProPremium content={<Fields />} name="StockManagement:Fields" />
      </Route>
      <Route path="/stock/products">
        <LiteProPremium content={<Products />} name="StockManagement:Products" />
      </Route>
      <Route path="/stock/stocks">
        <LiteProPremium content={<Stocks />} name="StockManagement:Stocks" />
      </Route>
      <Route path="/stock/weighedins">
        <LiteProPremium content={<WeighedIns />} name="StockManagement:WeighedInOut" />
      </Route>
      <Route path="/stock/yield">
        <LiteProPremium content={<Yield />} name="StockManagement:Yield" />
      </Route>
      <Route path="/stock/drivers">
        <LiteProPremium content={<Drivers />} name="StockManagement:Stocks" />
      </Route>
      <Route path="/">
        <Dashboard />
      </Route>
    </Switch>
  );
};

export default Routes;
