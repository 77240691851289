import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

interface Props {
  color?: string;
  opacity?: number;
}

const Bucket = ({ color = "#000", opacity = 0.7 }: Props) => {
  return (
    <SvgIcon>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path
          style={{ color, opacity }}
          d="M425.2,186.3c0.2-3.2,0.3-6.5,0.3-9.8c0-95.6-79.7-173-178-173s-178,77.5-178,173c0,3.3,0.5,12.6,1.1,19.5
	c1.5,16.5,59.7,273.8,59.7,273.8s8.5,26.6,122.6,26.6s118.5-26.6,118.5-26.6S424.5,191.1,425.2,186.3z M247.5,26.2
	c81,0,147.4,60.5,154.1,137.5c-8.7-3.8-19.7-7.2-33.1-10.2c-32.4-7.2-75.4-11.1-121-11.1s-88.6,4-121,11.1
	c-13.4,3-24.5,6.4-33.1,10.2C100.1,86.6,166.5,26.2,247.5,26.2z"
        />
        <path
          style={{ fill: "#BCBEC0", opacity }}
          d="M401.6,193.6c0,13.9-61.3,31.4-154.1,31.4S93.4,205.6,93.4,193.5c0-20.6,61.3-34,154.1-34
	S401.6,173.1,401.6,193.6z"
        />
      </svg>
    </SvgIcon>
  );
};

export default Bucket;
