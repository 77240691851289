import React, { useState, useEffect, memo, useRef } from "react";
import { FarmCtx } from "../lib/contexts";
import { IFarm } from "../types/farm";
import { saveInLocal, getFromLocal } from "../lib/utilities";
import useAuth from "../hooks/useAuth";
import useAxios from "../hooks/useAxios";
import useLanguage from "../hooks/useLanguage";
import LoginInProgress from "../Screens/LoginInProgress";
import { IModule } from "../Features/Groups/types";
import { useQuery } from "react-query";
import useToast from "../hooks/useToast";
import LoginNoFarms from "../Screens/LogInNoFarms";
import useFarmIdQuery from "../hooks/useQuery";
import useFarms from "../hooks/useFarms";

type FarmProviderProps = {
  children: any;
};

const findModule = (_modules: IModule[] | null, _id: string) =>
  !!_modules?.find((_module) => _module.moduleId.toLowerCase() === _id.toLowerCase());

const FarmProvider = ({ children }: FarmProviderProps) => {
  const farmid = useRef<string | undefined>(useFarmIdQuery().get("farmid")).current;
  const { user } = useAuth();
  const storageKey = `_COWCONNECT_FARMS_${user.email}`;
  const previousFarm = useRef<IFarm>(getFromLocal(storageKey, "json")).current;
  const toast = useToast();
  const [farm, setFarm] = useState<IFarm | null>(null);
  const [flags, setFlags] = useState<Partial<IFarm>>();
  const { axios } = useAxios();
  const { t } = useLanguage();
  const { QFarms } = useFarms();

  // COULD ALSO BE PUT IN HOOK - HOOK EXISTS ALREADY
  const QModules = useQuery<IModule[], [string, string | undefined]>(
    ["modules", farm?.farmId],
    async (key, farmId) => await axios.get(`farms/modules/${farmId}`),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1000 * 60 * 60 * 24,
      staleTime: 1000 * 60 * 60 * 24,
      enabled: !!farm?.farmId,
    }
  );

  useEffect(() => {
    if ((window as any).fcWidget?.isInitialized() == true) {
      (window as any).fcWidget.user.setFirstName(user.name);
      (window as any).fcWidget.user.setEmail(user.email);
      (window as any).fcWidget.user.setProperties({
        Farm: farm?.farmName || "Unknown",
        FarmID: farm?.farmId || "Unknown",
        FarmContact: farm?.farmContact || "Unknown",
      });
    }
  }, [farm]);

  useEffect(() => {
    farm && saveInLocal(storageKey, farm, "json");
  }, [farm]);

  useEffect(() => {
    if (QFarms.data?.[0]) {
      if (farmid) {
        const _farm = QFarms.data.find((_f) => _f.farmId?.toLowerCase() === farmid?.toLowerCase());
        _farm && setFarm(_farm);
      } else if (previousFarm) {
        const _farm = QFarms.data.find((_f) => _f.farmId === previousFarm.farmId);
        if (_farm) setFarm(_farm);
        else setFarm(QFarms.data?.[0]);
      } else {
        setFarm(QFarms.data?.[0]);
      }
    } else if (!QFarms.isLoading) {
      setFarm({ farmId: undefined, farmName: "", farmContact: "" });
    }
  }, [QFarms.data, user.email, farmid]);

  useEffect(() => {
    if (QModules.data) {
      const isCC = findModule(QModules.data, "bf8fb31d-5843-4bd8-8ce0-63c4393d13dc");
      const isDG = findModule(QModules.data, "259d2913-0e04-4170-bf81-a9f9c01610ce");
      const isNorFor = findModule(QModules.data, "36745e9f-8ddc-4bf6-bf66-f79b1f6d4398");
      const isFarmWizard = findModule(QModules.data, "b3f3854b-2c90-4bd9-ab85-371e6ba075d9");
      const isStockControl = findModule(QModules.data, "FA8700D2-8A17-4814-A3C0-1E4B3FE3971E");
      const isWageningen = findModule(QModules.data, "ad3b0c4e-58ae-474e-af16-38ee783fb177");
      const isVEM = findModule(QModules.data, "58ebbe12-290a-4b42-8669-13843c8362af");
      const isNIR = findModule(QModules.data, "9bb6f1ec-54aa-47e1-bdf7-602c3153c51d");
      setFlags({ isCC, isDG, isNorFor, isFarmWizard, isStockControl, isWageningen, isVEM, isNIR });
    }
  }, [QModules.data, farm]);

  useEffect(() => {
    QFarms.error && toast.error(t("Can't find any farm data"));
  }, [QFarms.error]);

  useEffect(() => {
    QModules.error && toast.error(t("Can't find any module data"));
  }, [QModules.error]);

  return (
    <FarmCtx.Provider
      value={[{ ...farm, ...flags }, (f: IFarm) => setFarm((state: IFarm | null) => ({ ...state, ...f }))]}
    >
      {!!farm && QFarms?.data && QFarms?.data.length > 0 ? (
        children
      ) : QFarms.isLoading || QModules.isLoading ? (
        <LoginInProgress text="Loading farms" />
      ) : (
        <LoginNoFarms />
      )}
    </FarmCtx.Provider>
  );
};

export default memo(FarmProvider);
