import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

interface Props {
  color?: string;
  opacity?: number;
}

const Loads = ({ color = "#7e7e7e", opacity = 0.7 }: Props) => {
  return (
    <SvgIcon>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 868.05 714.22">
        <circle cx="561.28" cy="579" r="105.47" style={{ fill: "none", stroke: color, strokeWidth: 40 }} />
        <polyline
          points="20 642.79 74.04 642.79 126.34 598.34 404.39 598.34"
          style={{ fill: "none", stroke: color, strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: 40 }}
        />
        <polyline
          points="139.85 103.73 88.86 101.51 190.84 538.2"
          style={{ fill: "none", stroke: color, strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: 40 }}
        />
        <polyline
          points="719.92 598.34 771.34 598.34 848.05 134.63 483.5 118.73"
          style={{ fill: "none", stroke: color, strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: 40 }}
        />
        <path
          d="M147,41.52s164.6,4.57,202.45-30.74c102.91,38,172.85,119.66,182.77,238.8L650.75,201l-116.32,293L250.6,340.9l121.76-42C362.43,175.25,268.41,84,147,41.52Z"
          transform="translate(-65.98 -20.53)"
          style={{ fill: color }}
        />
      </svg>
    </SvgIcon>
  );
};

export default Loads;
