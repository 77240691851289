import React, { useState, useEffect } from "react";
import { ConfirmCtx } from "../lib/contexts";
import Confirm from "../Components/Confirm";
import useLanguage from "../hooks/useLanguage";

type ConfirmProviderProps = {
  children: any;
};
type TResponse = string | null;
interface IConfirmOptions {
  message: string;
  title?: string;
  positiveText?: string;
  negativeText?: string;
  identifier:string;
}
export default ({ children }: ConfirmProviderProps) => {
  const { t } = useLanguage();
  const [options, setOptions] = useState<IConfirmOptions | null>();
  const [response, setResponse] = useState<TResponse>(null);
  const [identifier, setIdentifier] = useState<TResponse>(null);

  const show = (_options: IConfirmOptions) => {
    setOptions({
      title: t("Confirm"),
      positiveText: t("Okay"),
      negativeText: t("Cancel"),
      ..._options,
    });
    setIdentifier(_options?.identifier || "")
  };

  const set = (_res: TResponse) => {
    setResponse(_res);
    setOptions(null);
  };

  useEffect(() => {
    !options?.message && setResponse(null);
  }, [options?.message]);

  return (
    <ConfirmCtx.Provider value={[show, response, identifier, setIdentifier]}>
      {children}
      {!!options && (
        <Confirm
          onConfirm={() => set(options.identifier || "positive")}
          onCancel={() => set("negative")}
          open={true}
          message={options.message}
          title={options.title}
          positiveText={options.positiveText}
          negativeText={options.negativeText}
        />
      )}
    </ConfirmCtx.Provider>
  );
};
