import { Box } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import React from "react";
import AccountSearch from "../../Components/AccountSearch";
import Logo from "../../Components/Logo";
import { DGSync } from "../../Features/DGSync";
import { FinanceBanner } from "../../Features/Finance";
import useMainUser from "../../hooks/useMainUser";
import useRole from "../../hooks/useRole";
import LanguageSelect from "../../locales/LanguageSelect";
import useStyles from "./styles";

type BannerProps = {
  toggleDrawer: () => void;
};
function Banner({ toggleDrawer }: BannerProps) {
  const classes = useStyles();
  const isMainUser = useMainUser();
  const { isAdmin } = useRole();
  return (
    <div className={classes.appBar}>
      <AppBar>
        <Toolbar className={classes.centeredToolbar}>
          <Logo reverse height={56} />
          {isMainUser || isAdmin ? <FinanceBanner /> : null}
          <Box display="flex" alignItems="center">
            <AccountSearch />
            <LanguageSelect />
            <DGSync />
          </Box>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default Banner;
