import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

interface Props {
  color?: string;
  opacity?: number;
  size?: "small" | "large" | "inherit" | "medium";
}

const Ingredients = ({ color = "#000", opacity = 0.7, size = "medium" }: Props) => {
  return (
    <SvgIcon fontSize={size}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="-30 0 397 397">
        <path
          d="M340.6,397c-13.1,0-26.7-10-33.7-26c-1.4-3.1-2.4-6.4-3.1-9.8H93.5c-0.7,3.4-1.7,6.6-3.1,9.8c-8.9,20.1-27.9,30.7-43.5,24
		c-6.3-2.7-11-7.8-13.8-14.7c-3.9-9.8-3.2-21.9,1.8-33.2c3.1-7,7.8-13.2,13.3-17.8C42.8,272.6,36,142.4,75.8,54.6
		c-2.8-1.8-5.4-3.9-7.8-6.2c-7.9-7.8-12.7-17.9-12.7-27c0-6.2,2.1-11.5,6.1-15.4c10.1-10,28.9-7.1,42.7,6.5c4,3.9,6.4,9.9,8.3,14.8
		h161.3c2.4-4.8,6.3-11,10.1-14.8C297.6-1.2,316.4-4,326.5,5.9c4,3.9,6,9.2,6,15.4c0,9.1-4.7,19.2-12.7,27c-0.3,0.3-0.6,0.6-0.9,0.9
		c42.5,87.7,35.7,221.9,30.1,280c5.6,4.6,10.2,10.8,13.3,17.8c5,11.3,5.6,23.4,1.8,33.2c-2.7,7-7.5,12.1-13.8,14.7
		C347.2,396.3,344,397,340.6,397z M87.6,347.6h222.1c3.6,0,6.5,2.7,6.8,6.3c0.3,4,1.3,7.9,2.9,11.6c5.6,12.8,17.4,20.5,25.7,17
		c3.6-1.5,5.5-4.8,6.5-7.2c2.5-6.3,1.9-14.8-1.6-22.7c-2.7-6.1-6.8-11.2-11.8-14.5c-2.1-1.4-3.3-3.8-3-6.4
		c5.5-55.1,13.7-192.3-29.5-278.7l-0.4-0.4c-1.4-1.4-2.2-3.4-2-5.4c0.2-2,1.2-3.8,2.8-5c1.7-1.2,3.1-2.3,4.3-3.5
		c5.3-5.2,8.6-11.9,8.6-17.3c0-2.5-0.7-4.4-2-5.7c-4-4-14.7-2.2-23.6,6.5c-3.1,3-7.3,10.2-8.9,14.4c-1,2.6-3.5,4.3-6.4,4.3H107.6
		c-2.8,0-5.3-1.7-6.4-4.3l-1-2.6c-1.4-3.8-3.5-9.5-5.8-11.7c-8.9-8.8-19.6-10.5-23.6-6.5c-1.3,1.3-2,3.2-2,5.7
		c0,5.4,3.3,12.1,8.6,17.3c2.9,2.9,6.2,5.1,9.9,6.7c1.7,0.7,3.1,2.1,3.7,3.9c0.7,1.8,0.5,3.7-0.3,5.4
		c-42.4,86.6-34.2,222.7-28.8,277.1c0.2,2.5-0.9,5-3,6.4c-4.9,3.3-9.1,8.4-11.8,14.5c-3.5,7.9-4.1,16.4-1.6,22.7
		c1,2.4,2.9,5.7,6.5,7.2c8.3,3.5,20-4.2,25.7-17c1.6-3.7,2.6-7.6,2.9-11.6C81,350.3,84,347.6,87.6,347.6z"
        />
        <path
          d="M245.4,178.3c-0.2-3.4-2.9-6.1-6.3-6.3c-0.3,0-2.5-0.2-5.8,0c0.3-0.3,0.6-0.5,0.8-0.8c12.7-12.7,11.4-33.7,11.3-34.5
		c-0.2-3.4-2.9-6.1-6.3-6.3c-0.7-0.1-12.9-0.8-24.3,4.3c1.7-4.1,2.8-8.7,2.8-13.5c0-18-15.8-31.8-16.4-32.4c-2.6-2.2-6.4-2.2-8.9,0
		c-0.7,0.6-16.4,14.5-16.4,32.4c0,4.8,1.2,9.4,2.8,13.5c-11.4-5.1-23.6-4.4-24.3-4.3c-3.4,0.2-6.1,2.9-6.3,6.3
		c-0.1,0.9-1.4,21.8,11.3,34.5c0.3,0.3,0.6,0.5,0.8,0.8c-3.3-0.2-5.5,0-5.8,0c-3.4,0.2-6.1,2.9-6.3,6.3c-0.1,0.8-1.2,19.3,9.1,32
		c-1.6,0-2.6,0.1-2.7,0.1c-3.4,0.2-6.1,2.9-6.3,6.3c-0.1,0.9-1.4,21.8,11.3,34.5c9.3,9.3,23.1,11.2,30.2,11.5l-0.2,50.7
		c0,3.8,3,6.8,6.8,6.8c0,0,0,0,0,0c3.8,0,6.8-3,6.8-6.8l0.2-50.7c6.9-0.3,21.3-2,30.9-11.6c12.7-12.7,11.4-33.7,11.3-34.5
		c-0.2-3.4-2.9-6.1-6.3-6.3c-0.2,0-1.2-0.1-2.7-0.1C246.6,197.6,245.4,179.1,245.4,178.3z M231.5,185.8c-0.6,5.3-2.3,12.6-7.1,17.4
		c-4.8,4.8-12.2,6.6-17.5,7.3c0.7-5.4,2.4-12.7,7.2-17.5C219,188.1,226.2,186.4,231.5,185.8z M224.4,161.6
		c-4.8,4.8-12.2,6.6-17.5,7.3c0.7-5.4,2.4-12.7,7.3-17.5c4.8-4.8,12.1-6.5,17.4-7.1C230.9,149.5,229.2,156.7,224.4,161.6z
		 M196.7,103.9c3.3,4.1,7.3,10.5,7.3,17.3c0,6.8-3.9,13.3-7.3,17.5c-3.3-4.3-7.3-10.7-7.3-17.5C189.5,114.3,193.4,108,196.7,103.9z
		 M179.3,151.3c4.8,4.8,6.6,12.2,7.3,17.5c-5.4-0.7-12.7-2.5-17.5-7.3c-4.8-4.8-6.5-12.1-7.1-17.4
		C167.2,144.8,174.5,146.5,179.3,151.3z M186.6,210.4c-5.4-0.7-12.7-2.4-17.5-7.2c-4.8-4.8-6.5-12.1-7.1-17.4
		c5.3,0.6,12.6,2.3,17.4,7.1C184.2,197.7,185.9,205.1,186.6,210.4z M169,241.6c-4.8-4.8-6.5-12.1-7.1-17.4
		c5.3,0.6,12.6,2.3,17.4,7.1c4.8,4.8,6.6,12.2,7.3,17.5C181.2,248.2,173.8,246.4,169,241.6z M224.4,241.6
		c-4.8,4.8-12.2,6.6-17.5,7.3c0.7-5.4,2.4-12.7,7.3-17.5c4.8-4.8,12.1-6.5,17.4-7.1C230.9,229.5,229.2,236.8,224.4,241.6z"
        />
      </svg>
    </SvgIcon>
  );
};

export default Ingredients;
