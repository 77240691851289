import { Button } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import RefreshIcon from "@material-ui/icons/Refresh";
import React from "react";
import Logo from "../Components/Logo";
import useLanguage from "../hooks/useLanguage";
import AnimatedBackdrop from "./AnimatedBackdrop";

interface Props {}

const ErrorLegal = ({}: Props) => {
  const { t } = useLanguage();
  return (
    <AnimatedBackdrop>
      <Box
        p={2}
        width="100%"
        maxWidth={400}
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
      >
        <Logo />
        <Typography variant="h5" component="div" style={{ paddingBottom: 16, width: "100%", textAlign: "center" }}>
          {t("Error getting legal agreement")}
        </Typography>
        <Typography variant="h5" component="div" style={{ paddingBottom: 16, width: "100%", textAlign: "center" }}>
          {t(
            "This could be an issue with your chosen language. If you are comfortable with another, you can choose it here."
          )}
        </Typography>
        <Box display="flex">
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={() => window.location.reload()}
            startIcon={<RefreshIcon />}
          >
            {t("RELOAD")}
          </Button>
        </Box>
      </Box>
    </AnimatedBackdrop>
  );
};

export default ErrorLegal;
