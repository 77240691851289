import { Box, Typography } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import React, { useState } from "react";
import Banner from "../Banner";
import SideMenu from "../SideMenu";
import useStyles from "./style";

type PageStructureProps = {
  children: any;
};

const PageStructure = ({ children }: PageStructureProps) => {
  const [, setIsDrawerOpen] = useState(true);
  const classes = useStyles();
  const theme = useTheme();

  const toggleDrawer = () => {
    setIsDrawerOpen((state) => !state);
  };

  return (
    <div className={classes.root}>
      <Banner toggleDrawer={toggleDrawer} />
      <SideMenu />
      <Box minHeight={"100vh"} display="flex" flexDirection={"column"} width="100%">
        <main className={classes.content} style={{ flexGrow: 1 }}>
          <Box className={classes.toolbar} />
          <Box>{children}</Box>
        </main>
        {/* <Box p={1} bgcolor={theme.palette.primary.main} display="flex" justifyContent={"center"}>
          <Typography variant="button" style={{ color: theme.palette.getContrastText(theme.palette.primary.main) }}>
            Feedlync by CowConnect
          </Typography>
        </Box> */}
      </Box>
    </div>
  );
};

export default PageStructure;
