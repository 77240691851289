import React, { memo, useEffect, useRef } from "react";
import { AzureAD, AuthenticationState, IAzureADFunctionProps } from "react-aad-msal";
import { AccessTokenResponse, IdTokenResponse } from "react-aad-msal";
import { authProvider, pwProvider } from "./auth/authProvider";
import Providers from "./Providers";
import { AuthCtx } from "./auth";
import LoginPrompt from "./Screens/LoginPrompt";
import LoginInProgress from "./Screens/LoginInProgress";
import Theme from "./Providers/Theme";
import sub from "date-fns/sub";
import axios, { baseURL } from "./lib/axios";

import { AxiosResponse } from "axios";
const App = () => {
  return (
    <Theme>
      <AzureAD provider={localStorage.getItem("DO_PASSWORD_RESET") ? pwProvider() : authProvider} forceLogin={true}>
        {({ authenticationState, error, login, accountInfo, logout, ...rest }: IAzureADFunctionProps) => {
          if (error?.errorMessage?.includes("AADB2C90118")) {
            localStorage.setItem("DO_PASSWORD_RESET", "true");
            window.location.reload();
            return <LoginInProgress text="Changing password" />;
          } else if (localStorage.getItem("DO_PASSWORD_RESET")) {
            localStorage.removeItem("DO_PASSWORD_RESET");
            localStorage.setItem("DONE_PASSWORD_RESET", "true");
            login();
            return <LoginInProgress text="Changing password" />;
          } else if (localStorage.getItem("DONE_PASSWORD_RESET") && authenticationState !== "InProgress") {
            localStorage.removeItem("DONE_PASSWORD_RESET");
            localStorage.setItem("AUTO_LOGIN", "true");
            return <LoginInProgress text="Password reset done" />;
          } else if (localStorage.getItem("AUTO_LOGIN")) {
            localStorage.removeItem("AUTO_LOGIN");
            window.location.reload();
            return <LoginInProgress text="Starting login" />;
          }
          switch (authenticationState) {
            case AuthenticationState.Authenticated:
              const p = { authenticationState, error, login, accountInfo, logout, ...rest };
              return <Authenticated {...p} />;
            case AuthenticationState.Unauthenticated:
              logout();
              return <LoginPrompt error={error} login={login} />;
            case AuthenticationState.InProgress:
              return <LoginInProgress />;
          }
        }}
      </AzureAD>
    </Theme>
  );
};

export default App;

const Authenticated = memo((props: IAzureADFunctionProps) => {
  const accessTokenRef = useRef<string>();
  const accessTokenExpiryRef = useRef<Date>();
  const getIdToken = async () => {
    const token: IdTokenResponse = await authProvider.getIdToken();
    return token.idToken.rawIdToken;
  };

  const getAccessToken = async () => {
    if (
      accessTokenRef.current &&
      accessTokenExpiryRef.current &&
      new Date() < sub(accessTokenExpiryRef.current, { minutes: 15 })
    ) {
      return accessTokenRef.current;
    } else {
      const token: AccessTokenResponse = await authProvider.getAccessToken();
      accessTokenRef.current = token.accessToken;
      accessTokenExpiryRef.current = token.expiresOn;
      return token.accessToken;
    }
  };

  useEffect(() => {
    /**
     * REQUEST INTERCEPTER
     */
    axios.interceptors.request.use(
      async (config: any) => {
        if (config.baseURL === baseURL && !config.headers.Authorization) {
          const token = await getAccessToken();
          token && (config.headers["Authorization"] = `Bearer ${token}`);
        }
        return config;
      },
      (error: any) => {
        Promise.reject(error);
      }
    );

    /**
     * RESPONSE INTERCEPTER
     */
    axios.interceptors.response.use(
      (res: AxiosResponse) => {
        return res.status >= 200 && res.status < 300 ? res.data : res;
      },
      (error: any) => Promise.reject(error)
    );
  }, []);

  return (
    <AuthCtx.Provider
      value={{
        authenticationState: props.authenticationState,
        error: props.error,
        login: props.login,
        logout: props.logout,
        accountInfo: props.accountInfo,
        getAccessToken: getAccessToken,
        getIdToken: getIdToken,
        // ...props.rest,
      }}
    >
      <Providers />
    </AuthCtx.Provider>
  );
});
