import { Backdrop } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import React from "react";
import Logo from "../Components/Logo";
import AnimatedBackdrop from "./AnimatedBackdrop";
interface Props {
  back?: boolean;
}

const LogoAndSpinner = ({ back = true }: Props) => {
  return back ? (
    <AnimatedBackdrop>
      <Box display="flex" flexDirection="column" alignItems="center" p={4} pb={6}>
        <Box m={4}>
          <Logo />
        </Box>
        <CircularProgress color="primary" />
      </Box>
    </AnimatedBackdrop>
  ) : (
    <Backdrop open={true}>
      <Box display="flex" flexDirection="column" alignItems="center" p={4} pb={6}>
        <Box m={4}>
          <Logo reverse />
        </Box>
        <CircularProgress color="secondary" />
      </Box>
    </Backdrop>
  );
};

export default LogoAndSpinner;
