import { MsalAuthProvider, LoginType } from "react-aad-msal";
import { Logger, LogLevel } from "msal";
import browser from "../lib/browserDetection";

const config = {
  auth: {
    authority: "https://ABAgriLink.b2clogin.com/ABAgriLink.onmicrosoft.com/B2C_1_LinkSignUpSignInNoMFA",
    clientId: "1e5b3002-b399-480e-abc2-c883a4bcc937",
    postLogoutRedirectUri: window.location.origin,
    redirectUri: window.location.origin,
    validateAuthority: false,
    navigateToLoginRequestUrl: false,
    knownAuthorities: ["ABAgriLink.b2clogin.com "],
  },
  // TO-DO
  // Enable logging of MSAL events for easier troubleshooting.
  // This should be disabled in production builds.
  system: {
    name: "SignIn",
    logger: new Logger(
      (logLevel, message, containsPii) => {
        console.log("[MSAL]", message);
      },
      {
        level: LogLevel.Error,
        piiLoggingEnabled: false,
      }
    ),
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
};

const configPw = {
  auth: {
    authority: "https://ABAgriLink.b2clogin.com/ABAgriLink.onmicrosoft.com/B2C_1_LinkNewPasswordReset",
    clientId: "1e5b3002-b399-480e-abc2-c883a4bcc937",
    postLogoutRedirectUri: window.location.origin,
    redirectUri: window.location.origin,
    validateAuthority: false,
    navigateToLoginRequestUrl: false,
    knownAuthorities: ["ABAgriLink.b2clogin.com "],
  },
  // TO-DO
  // Enable logging of MSAL events for easier troubleshooting.
  // This should be disabled in production builds.
  system: {
    name: "PasswordReset",
    logger: new Logger(
      (logLevel, message, containsPii) => {
        console.log("[MSAL]", message);
      },
      {
        level: LogLevel.Error,
        piiLoggingEnabled: false,
      }
    ),
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
};

const authParams = {
  scopes: ["https://ABAgriLink.onmicrosoft.com/dairy/user_impersonation"],
};

const options = {
  loginType: browser.isSafari && browser.hasString("13.1") ? LoginType.Popup : LoginType.Redirect,
  tokenRefreshUri: window.location.origin + "/auth.html",
};

export const authProvider = new MsalAuthProvider(config, authParams, options);
export const pwProvider = () => new MsalAuthProvider(configPw, authParams, options);
