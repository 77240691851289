import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

interface Props {
  color?: string;
  opacity?: number;
  size?: "small" | "large" | "inherit" | "medium";
}

const Silo = ({ color = "#000", opacity = 0.7, size = "medium" }: Props) => {
  return (
    <SvgIcon fontSize={size}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400">
        <polygon
          fill={color}
          opacity={opacity}
          points="302,229 302,201 302,5 98,5 98,201 98,229 98,394 130,394 130,255.7 200,314 270,255.7 270,394 302,394 "
        />
      </svg>
    </SvgIcon>
  );
};

export default Silo;
