import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

interface Props {
  color?: string;
  opacity?: number;
  size?: "small" | "large" | "inherit" | "medium";
}

const Bale = ({ color = "#000", opacity = 0.7, size = "medium" }: Props) => {
  return (
    <SvgIcon fontSize={size}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800 800">
        <g opacity={opacity}>
          <g>
            <g className="st0">
              <g className="st0">
                <defs>
                  <path
                    id="SVGID_1_"
                    className="st0"
                    d="M288.1,58c2.4,0,5.5-0.1,7.9-0.1c2.8,0,5.4,0.1,10,0.3c-0.9-0.1-1.8-0.1-2.8-0.1
						c-0.9,0-1.9-0.1-2.8-0.1c-0.9,0-1.9,0-2.8,0c-0.9,0-1.9,0-2.8,0.1c-0.6,0-1.2-0.1-1.7-0.1c-0.6,0-1.1,0-1.7,0
						c-0.6,0-1.1,0-1.6,0C289.2,58,288.6,58,288.1,58z"
                  />
                </defs>
                <g className="st1">
                  <path
                    fill="#8C8C8C"
                    d="M288.1,58c5.4-0.1,10.2-0.2,17.9,0.2c-0.9-0.1-1.8-0.1-2.8-0.1c-0.9,0-1.9-0.1-2.8-0.1c-0.9,0-1.9,0-2.8,0
						c-0.9,0-1.9,0-2.8,0.1c-0.6,0-1.2-0.1-1.7-0.1c-0.6,0-1.1,0-1.7,0c-0.6,0-1.1,0-1.6,0C289.2,58,288.6,58,288.1,58"
                  />
                  <path
                    fill="#8C8C8C"
                    d="M294.8,58.1c-0.6,0-1.2-0.1-1.7-0.1c-0.6,0-1.1,0-1.7,0c-0.6,0-1.1,0-1.6,0c-0.5,0-1.1,0-1.6,0l4.8-0.1
						l3.1,0l4.7,0.1c1.6,0.1,3.7,0.1,5.3,0.2c-0.9-0.1-1.8-0.1-2.8-0.1c-0.9,0-1.9-0.1-2.8-0.1c-0.9,0-1.9,0-2.8,0
						C296.7,58.1,295.7,58.1,294.8,58.1"
                  />
                </g>
              </g>
              <g className="st0">
                <defs>
                  <path
                    id="SVGID_3_"
                    className="st0"
                    d="M170.8,621.2c-5.6-2.6-5.8-2.7-9.1-4.3c-2.5-1.3-5.9-3.1-8.4-4.4c-3.1-1.7-9-5.2-13-7.9
						c-1.4-0.9-3.2-2.1-4.5-3.1c-2.6-1.9-6-4.4-8.5-6.4c-4.1-3.4-8.1-6.6-14.6-13c-1.7-1.7-3.9-3.9-5.5-5.7
						c-2.4-2.6-5.5-6.2-7.7-8.9c-3.3-4-7.4-9.7-10.3-13.9c-2-2.9-4.5-6.8-6.3-9.9c-1.6-2.9-3.8-6.6-5.4-9.5c-1.4-2.7-3.3-6.3-4.5-9
						c-1.4-3-3.3-7.1-4.6-10.2c-1.3-3-2.8-7-4-10c-2.8-7.6-2.8-7.6-7.1-21.9c-1.5-4.9-5.3-20.2-7.7-35.9c-1.2-7.7-2.2-18-3-25.7
						c-0.4-4-0.7-9.4-0.8-13.4c-0.1-4-0.3-9.4-0.3-13.5c0-4,0-9.4,0.1-13.5c0.4-15.1,1.6-26.9,1.6-27c1.5-13.3,2.1-19.4,3.4-26.9
						c0.7-4,1.5-9.4,2.4-13.3c1.7-7.9,3.4-18.7,6-26.3c0,0,0,0,0,0c1.3-3.8,2.5-9,3.6-12.9c1.1-3.9,2.7-9,4-12.7
						c1.3-3.8,2.9-8.8,4.4-12.5c1.4-3.7,3.3-8.6,4.7-12.3c1.5-3.6,3.5-8.4,5.1-12c5.5-12.4,11.1-23,11.1-23.1
						c1.8-3.3,4.1-7.8,6-11.1c3.8-6.4,8.9-14.8,12.9-21c5.5-8.6,16-22.6,21.2-28.6c2.3-2.6,5.2-6.2,7.6-8.8c2.2-2.5,5.3-5.8,7.6-8.2
						c8.7-9,15.8-15,15.8-15.1c2.4-2,5.5-4.8,8-6.7c2.5-2,5.8-4.6,8.4-6.5c7.9-5.8,14.3-9.7,16.8-11.2c8.3-5,14.7-8,17.1-9.2
						c8-3.8,14.5-6.2,17.4-7.2c3.6-1.3,8.4-2.9,12.1-3.8c1.8-0.5,4.1-1.1,5.9-1.5c3.6-0.8,8.4-1.8,12.1-2.5c0.1,0,6-1.1,13-1.8
						c1.5-0.2,3.6-0.3,5.1-0.4c2.7-0.2,6.2-0.4,8.9-0.5c0.5,0,1.1,0,1.6,0c0.5,0,1.1,0,1.6,0c0.6,0,1.1,0,1.7,0c0.6,0,1.1,0,1.7,0.1
						c-14.3,0.4-28.7,3.2-42.9,8.1c-14.6,5-28.9,12.3-42.9,21.7c-14.2,9.6-28,21.3-41,34.9c-13.2,13.8-25.7,29.6-37,47
						c-11.5,17.6-21.9,36.8-30.9,57.2c-9,20.6-16.6,42.3-22.5,64.8c-5.9,22.5-10.1,45.7-12.4,69.1c-2.3,23.3-2.6,46.6-0.9,69.6
						c1.7,22.7,5.3,43.9,10.6,63.3c5.2,19.1,11.9,36.4,20,51.9c7.9,15.1,17,28.3,27.1,39.6c9.9,11,20.6,20.1,32.1,27.3
						c1.4,0.8,2.7,1.7,4.1,2.5c1.4,0.8,2.8,1.6,4.1,2.3c1.4,0.7,2.8,1.4,4.2,2.1C168,620,169.4,620.6,170.8,621.2z"
                  />
                </defs>
                <g className="st3">
                  <path
                    fill="#8C8C8C"
                    d="M170.8,621.2c-16.8-7.6-36.8-18.3-58-39c-22.2-21.8-35.2-47.1-39.7-56.9c-4.2-9.2-17.1-37.1-23.3-77.8
						c-6.6-43.8-3.9-79.8-2.4-93.3c1.1-9.8,2.8-26.1,5.8-40.3c2.5-12,6.8-32.2,13.6-52c12.8-37,24.4-61.1,44.3-92
						c13.7-21.3,35.1-46.3,52.2-60.7c11-9.3,34-28.8,67.7-40.8c26.2-9.3,48.7-10.2,57.1-10.5c0.5,0,1.1,0,1.6,0c0.5,0,1.1,0,1.6,0
						c0.6,0,1.1,0,1.7,0c0.6,0,1.1,0,1.7,0.1c-14.3,0.4-28.7,3.2-42.9,8.1c-14.6,5-28.9,12.3-42.9,21.7c-14.2,9.6-28,21.3-41,34.9
						c-13.2,13.8-25.7,29.6-37,47c-11.5,17.6-21.9,36.8-30.9,57.2c-9,20.6-16.6,42.3-22.5,64.8c-5.9,22.5-10.1,45.7-12.4,69.1
						c-2.3,23.3-2.6,46.6-0.9,69.6c1.7,22.7,5.3,43.9,10.6,63.3c5.2,19.1,11.9,36.4,20,51.9c7.9,15.1,17,28.3,27.1,39.6
						c9.9,11,20.6,20.1,32.1,27.3c1.4,0.8,2.7,1.7,4.1,2.5c1.4,0.8,2.8,1.6,4.1,2.3c1.4,0.7,2.8,1.4,4.2,2.1
						C168,620,169.4,620.6,170.8,621.2"
                  />
                  <path
                    fill="#8C8C8C"
                    d="M294.8,58.1c-3.8,0.1-7.6,0.4-11.4,0.8c-3.8,0.4-7.7,1-11.5,1.8c-3.8,0.8-7.7,1.7-11.5,2.8
						c-3.9,1.1-7.7,2.3-11.5,3.7l-8.8,3.2c-36.6,15.1-58,35.9-75.6,56c-3,3.2-5.9,6.5-8.8,9.9c-2.9,3.4-5.7,6.9-8.5,10.5
						c-2.8,3.6-5.5,7.3-8.2,11.1c-2.7,3.8-5.4,7.7-8,11.7c-11.5,17.6-21.9,36.8-30.9,57.2c-9,20.6-16.6,42.3-22.5,64.8
						c-5.9,22.5-10.1,45.7-12.4,69.1c-2.3,23.3-2.6,46.6-0.9,69.6c1.7,22.7,5.3,43.9,10.6,63.3c5.2,19.1,11.9,36.4,20,51.9
						c7.9,15.1,17,28.3,27.1,39.6c9.9,11,20.6,20.1,32.1,27.3c1.4,0.8,2.7,1.7,4.1,2.5c1.4,0.8,2.8,1.6,4.1,2.3
						c1.4,0.7,2.8,1.4,4.2,2.1c1.4,0.7,2.8,1.3,4.2,2l-4.2-1.9c-1.4-0.7-3.4-1.6-4.8-2.3l-4.2-2.2c-1.5-0.8-3.4-1.8-4.8-2.6
						l-4.2-2.5l-3.5-2.1l-4.6-3c-5.7-3.9-7.2-5-13-9.4l-5-4.1c-6.4-5.7-6.6-5.8-9.8-9.1l-5.3-5.5c-2.3-2.6-5.5-6.2-7.7-8.9l-5.3-6.8
						c-1.9-2.7-4.5-6.3-6.3-9.1l-5-7.9c-1.6-2.9-3.8-6.6-5.4-9.5l-4.5-9c-1.4-3.1-3.3-7.1-4.6-10.2l-4-10c-1.1-3.3-2.7-7.6-3.8-10.9
						l-3.3-10.9c-0.9-3.5-2.2-8.1-3-11.7L52,459.7c-0.7-3.7-1.6-8.7-2.2-12.4l-1.7-12.7c-0.4-3.9-1-9.2-1.3-13.2L46,408.2
						c-0.1-4-0.3-9.4-0.3-13.5l0.1-13.5c0.2-4.1,0.3-9.5,0.6-13.5l1-13.5c0.5-4,1-9.5,1.5-13.5l1.9-13.4c0.7-4,1.6-9.4,2.4-13.3
						l2.8-13.2c1-3.9,2.2-9.2,3.2-13.1l3.6-12.9c1.2-3.8,2.7-8.9,4-12.7l4.4-12.5c1.4-3.7,3.3-8.6,4.7-12.3l5.1-12
						c1.6-3.5,3.7-8.2,5.4-11.7l5.7-11.4c1.8-3.3,4.2-7.8,6-11.1l6.3-10.7c2-3.1,4.6-7.3,6.6-10.4l6.8-9.9c2.1-2.9,4.9-6.8,7.1-9.6
						l7.2-9.1c2.3-2.6,5.2-6.2,7.6-8.8l7.6-8.2c2.4-2.4,5.5-5.6,8-7.9l7.9-7.2c2.5-2.1,5.8-5,8.4-7l8-6.1c2.6-1.9,6.1-4.4,8.8-6.2
						l8-5c2.8-1.6,6.6-3.8,9.4-5.3l7.7-3.9c3.1-1.4,7.3-3.3,10.4-4.6l7-2.6c3.6-1.2,8.5-2.8,12.1-3.8l5.9-1.5c7.4-1.7,8.6-1.9,15-3
						l5-0.7c7.5-0.9,10.3-1.1,19-1.5c0.5,0,1.1,0,1.6,0c0.5,0,1.1,0,1.6,0c0.6,0,1.1,0,1.7,0C293.6,58,294.2,58.1,294.8,58.1"
                  />
                  <path
                    fill="#808080"
                    d="M269.4,59.5C161.2,76.6,107.8,178.9,93.3,236.7l-2.1,9.1l-1.5,6.9c-5.1,13.9-9.5,28.2-13.2,42.9
						c-3.7,14.6-6.7,29.5-8.9,44.6c-2.2,15-3.6,30.1-4.2,45.3c-0.6,15-0.3,30.1,0.8,44.9c1.7,22.7,5.3,43.9,10.6,63.3
						c5.2,19.1,11.9,36.4,20,51.9c7.9,15.1,17,28.3,27.1,39.6c9.9,11,20.6,20.1,32.1,27.3c1.4,0.8,2.7,1.7,4.1,2.5
						c1.4,0.8,2.8,1.6,4.1,2.3c1.4,0.7,2.8,1.4,4.2,2.1c1.4,0.7,2.8,1.3,4.2,2l-4.2-1.9c-1.4-0.7-3.4-1.6-4.8-2.3l-4.2-2.2
						c-1.5-0.8-3.4-1.8-4.8-2.6l-4.2-2.5l-3.5-2.1l-4.6-3c-5.7-3.9-7.2-5-13-9.4l-5-4.1c-6.4-5.7-6.6-5.8-9.8-9.1l-5.3-5.5
						c-2.3-2.6-5.5-6.2-7.7-8.9l-5.3-6.8c-1.9-2.7-4.5-6.3-6.3-9.1l-5-7.9c-1.6-2.9-3.8-6.6-5.4-9.5l-4.5-9
						c-1.4-3.1-3.3-7.1-4.6-10.2l-4-10c-1.1-3.3-2.7-7.6-3.8-10.9l-3.3-10.9c-0.9-3.5-2.2-8.1-3-11.7L52,459.7
						c-0.7-3.7-1.6-8.7-2.2-12.4l-1.7-12.7c-0.4-3.9-1-9.2-1.3-13.2L46,408.2c-0.1-4-0.3-9.4-0.3-13.5l0.1-13.5
						c0.2-4.1,0.3-9.5,0.6-13.5l1-13.5c0.5-4,1-9.5,1.5-13.5l1.9-13.4c0.7-4,1.6-9.4,2.4-13.3l2.8-13.2c1-3.9,2.2-9.2,3.2-13.1
						l3.6-12.9c1.2-3.8,2.7-8.9,4-12.7l4.4-12.5c1.4-3.7,3.3-8.6,4.7-12.3l5.1-12c1.6-3.5,3.7-8.2,5.4-11.7l5.7-11.4
						c1.8-3.3,4.2-7.8,6-11.1l6.3-10.7c2-3.1,4.6-7.3,6.6-10.4l6.8-9.9c2.1-2.9,4.9-6.8,7.1-9.6l7.2-9.1c2.3-2.6,5.2-6.2,7.6-8.8
						l7.6-8.2c2.4-2.4,5.5-5.6,8-7.9l7.9-7.2c2.5-2.1,5.8-5,8.4-7l8-6.1c2.6-1.9,6.1-4.4,8.8-6.2l8-5c2.8-1.6,6.6-3.8,9.4-5.3
						l7.7-3.9c3.1-1.4,7.3-3.3,10.4-4.6l7-2.6c3.6-1.2,8.5-2.8,12.1-3.8l5.9-1.5c7.4-1.7,8.6-1.9,15-3l5-0.7c2.4-0.3,5.6-0.6,8-0.9
						L269.4,59.5"
                  />
                  <path
                    fill="#737373"
                    d="M216.4,74.4c-95.2,46.9-161,166.8-152.9,278.9l0.8,8.1l0.5,4.1c-0.5,5.4-0.8,10.9-1.1,16.3
						c-0.3,5.4-0.4,10.8-0.4,16.3c0,5.4,0,10.8,0.2,16.2c0.2,5.4,0.5,10.7,0.9,16c1.7,22.7,5.3,43.9,10.6,63.3
						c5.2,19.1,11.9,36.4,20,51.9c7.9,15.1,17,28.3,27.1,39.6c9.9,11,20.6,20.1,32.1,27.3c1.4,0.8,2.7,1.7,4.1,2.5
						c1.4,0.8,2.8,1.6,4.1,2.3c1.4,0.7,2.8,1.4,4.2,2.1c1.4,0.7,2.8,1.3,4.2,2l-4.2-1.9c-1.4-0.7-3.4-1.6-4.8-2.3l-4.2-2.2
						c-1.5-0.8-3.4-1.8-4.8-2.6l-4.2-2.5l-3.5-2.1l-4.6-3c-5.7-3.9-7.2-5-13-9.4l-5-4.1c-6.4-5.7-6.6-5.8-9.8-9.1l-5.3-5.5
						c-2.3-2.6-5.5-6.2-7.7-8.9l-5.3-6.8c-1.9-2.7-4.5-6.3-6.3-9.1l-5-7.9c-1.6-2.9-3.8-6.6-5.4-9.5l-4.5-9
						c-1.4-3.1-3.3-7.1-4.6-10.2l-4-10c-1.1-3.3-2.7-7.6-3.8-10.9l-3.3-10.9c-0.9-3.5-2.2-8.1-3-11.7L52,459.7
						c-0.7-3.7-1.6-8.7-2.2-12.4l-1.7-12.7c-0.4-3.9-1-9.2-1.3-13.2L46,408.2c-0.1-4-0.3-9.4-0.3-13.5l0.1-13.5
						c0.2-4.1,0.3-9.5,0.6-13.5l1-13.5c0.5-4,1-9.5,1.5-13.5l1.9-13.4c0.7-4,1.6-9.4,2.4-13.3l2.8-13.2c1-3.9,2.2-9.2,3.2-13.1
						l3.6-12.9c1.2-3.8,2.7-8.9,4-12.7l4.4-12.5c1.4-3.7,3.3-8.6,4.7-12.3l5.1-12c1.6-3.5,3.7-8.2,5.4-11.7l5.7-11.4
						c1.8-3.3,4.2-7.8,6-11.1l6.3-10.7c2-3.1,4.6-7.3,6.6-10.4l6.8-9.9c2.1-2.9,4.9-6.8,7.1-9.6l7.2-9.1c2.3-2.6,5.2-6.2,7.6-8.8
						l7.6-8.2c2.4-2.4,5.5-5.6,8-7.9l7.9-7.2c2.5-2.1,5.8-5,8.4-7l8-6.1c2.6-1.9,6.1-4.4,8.8-6.2l8-5c2.8-1.6,6.6-3.8,9.4-5.3
						l7.7-3.9c3.1-1.4,7.3-3.3,10.4-4.6L216.4,74.4"
                  />
                </g>
              </g>
              <g className="st0">
                <defs>
                  <path
                    id="SVGID_5_"
                    className="st0"
                    d="M306,58.2l278.7,15.5c-0.8,0-1.5-0.1-2.3-0.1c-0.8,0-1.6,0-2.3,0c-0.8,0-1.6,0-2.4,0
						c-0.8,0-1.6,0.1-2.4,0.1L294.8,58.1c1,0,1.9,0,2.8-0.1c0.9,0,1.9,0,2.8,0c0.9,0,1.9,0,2.8,0.1C304.2,58.2,305.1,58.2,306,58.2z
						"
                  />
                </defs>
                <g className="st6">
                  <path
                    fill="#8C8C8C"
                    d="M575.3,73.8L294.8,58.1c1,0,1.9,0,2.8-0.1c0.9,0,1.9,0,2.8,0c0.9,0,1.9,0,2.8,0.1c0.9,0,1.9,0.1,2.8,0.1
						l278.7,15.5c-0.8,0-1.5-0.1-2.3-0.1c-0.8,0-1.6,0-2.3,0c-0.8,0-1.6,0-2.4,0C576.9,73.7,576.1,73.7,575.3,73.8"
                  />
                </g>
              </g>
              <g className="st0">
                <defs>
                  <path
                    id="SVGID_7_"
                    className="st0"
                    d="M426.2,734c-85.1-37.6-170.2-75.1-255.3-112.7c-1.4-0.6-2.8-1.3-4.2-2
						c-1.4-0.7-2.8-1.4-4.2-2.1c-1.4-0.7-2.8-1.5-4.1-2.3c-1.4-0.8-2.7-1.6-4.1-2.5c-11.5-7.2-22.2-16.3-32.1-27.3
						c-10.1-11.3-19.2-24.5-27.1-39.6c-8.1-15.4-14.8-32.8-20-51.9c-5.3-19.4-8.9-40.6-10.6-63.3c-1.7-23-1.4-46.3,0.9-69.6
						c2.3-23.4,6.4-46.5,12.4-69.1c5.9-22.5,13.5-44.2,22.5-64.8c9-20.4,19.3-39.6,30.9-57.2c11.4-17.4,23.8-33.1,37-47
						c13-13.6,26.8-25.3,41-34.9c13.9-9.4,28.3-16.6,42.9-21.7c14.2-4.9,28.6-7.6,42.9-8.1l280.5,15.7c-12.2,0.8-24.6,4.3-37.1,10.3
						c-12.9,6.2-25.8,15.2-38.6,26.6c-13.1,11.7-26.1,26-38.5,42.6c-12.7,17-24.9,36.2-36.4,57.4c-11.6,21.5-22.3,45-31.9,69.9
						c-9.7,25.1-18.1,51.5-25.1,78.8c-7,27.3-12.5,55.2-16.2,83.2c-3.7,27.8-5.7,55.5-5.8,82.5c-0.1,26.6,1.7,51.1,5,73.3
						c3.3,21.8,8.1,41.2,14.2,58.2c6,16.6,13.1,30.7,21.2,42.5c7.9,11.4,16.7,20.4,26.2,27.1c1.1,0.8,2.3,1.5,3.4,2.3
						c1.1,0.7,2.3,1.4,3.4,2.1c1.2,0.7,2.3,1.3,3.5,1.9C423.8,732.9,425,733.4,426.2,734z"
                  />
                </defs>
                <g className="st7">
                  <path
                    fill="#737373"
                    d="M426.2,734L170.8,621.2c-1.4-0.6-2.8-1.3-4.2-2c-1.4-0.7-2.8-1.4-4.2-2.1c-1.4-0.7-2.8-1.5-4.1-2.3
						c-1.4-0.8-2.7-1.6-4.1-2.5c-11.5-7.2-22.2-16.3-32.1-27.3c-10.1-11.3-19.2-24.5-27.1-39.6c-8.1-15.4-14.8-32.8-20-51.9
						c-5.3-19.4-8.9-40.6-10.6-63.3c-0.4-5.3-0.7-10.7-0.9-16.1c-0.2-5.4-0.2-10.8-0.2-16.2c0-5.4,0.2-10.8,0.4-16.3
						c0.3-5.4,0.6-10.9,1.1-16.3l285.9,82.7c-0.8,6.5-1.5,13-2.2,19.5c-0.6,6.5-1.1,12.9-1.6,19.3c-0.4,6.4-0.7,12.8-1,19.1
						c-0.2,6.3-0.3,12.6-0.4,18.9c-0.1,26.6,1.7,51.1,5,73.3c3.3,21.8,8.1,41.2,14.2,58.2c6,16.6,13.1,30.7,21.2,42.5
						c7.9,11.4,16.7,20.4,26.2,27.1c1.1,0.8,2.3,1.5,3.4,2.3c1.1,0.7,2.3,1.4,3.4,2.1c1.2,0.7,2.3,1.3,3.5,1.9
						C423.8,732.9,425,733.4,426.2,734"
                  />
                  <path
                    fill="#808080"
                    d="M350.7,448.2L64.8,365.5c0.8-9.7,2-19.3,3.5-28.9c1.5-9.6,3.3-19.1,5.4-28.6c2.1-9.5,4.5-18.8,7.2-28
						c2.7-9.2,5.7-18.3,8.9-27.2l291.8,59.3c-3.6,10.8-6.9,21.8-10,33c-3.1,11.2-6,22.5-8.6,33.9c-2.6,11.4-4.9,22.9-7,34.5
						C353.9,425,352.1,436.6,350.7,448.2"
                  />
                  <path
                    fill="#8C8C8C"
                    d="M381.5,312.1L89.7,252.8c2.8-7.6,5.7-15,8.8-22.3c3.1-7.3,6.4-14.4,9.9-21.4c3.5-6.9,7.1-13.7,10.8-20.3
						c3.8-6.6,7.7-13,11.7-19.1c2.6-4,5.3-7.9,8-11.7c2.7-3.8,5.5-7.5,8.3-11.1c2.8-3.6,5.7-7.1,8.5-10.5c2.9-3.4,5.8-6.7,8.8-9.9
						l293.1,31.4c-2.9,3.9-5.7,8-8.5,12.2c-2.8,4.2-5.6,8.5-8.4,12.9c-2.8,4.4-5.5,9-8.2,13.6c-2.7,4.7-5.4,9.4-8,14.3
						c-4.1,7.6-8,15.4-11.9,23.4c-3.9,8.1-7.6,16.3-11.2,24.8c-3.6,8.5-7.1,17.2-10.5,26.1C387.7,293.9,384.5,302.9,381.5,312.1"
                  />
                  <path
                    fill="#999999"
                    d="M457.7,157.7l-293.1-31.4c6.5-7.1,13.3-13.7,20.2-19.8c6.8-6,13.8-11.5,21-16.5c7-4.9,14.2-9.3,21.5-13.1
						c7.2-3.8,14.4-7,21.7-9.7l286.7,18.2c-6.5,3.3-13,7.3-19.5,11.9c-6.6,4.7-13.1,10.1-19.6,16.1c-6.6,6.1-13.1,12.8-19.6,20.2
						C470.4,141,464,149.1,457.7,157.7"
                  />
                  <path
                    fill="#8C8C8C"
                    d="M535.6,85.4L248.9,67.2c3.8-1.4,7.7-2.6,11.5-3.7c3.8-1.1,7.7-2,11.5-2.8c3.8-0.8,7.6-1.4,11.5-1.8
						c3.8-0.4,7.6-0.7,11.4-0.8l280.5,15.7c-3.2,0.2-6.4,0.6-9.7,1.2c-3.3,0.6-6.6,1.4-9.9,2.3c-3.3,1-6.7,2.1-10,3.5
						C542.3,82.1,538.9,83.6,535.6,85.4"
                  />
                </g>
              </g>
              <g>
                <path
                  fill="#999999"
                  d="M608.2,84c11.1-0.9,21.5,0.7,31,4.6c9.2,3.7,17.6,9.5,25.2,17.1c7.4,7.4,14,16.4,19.8,26.8
					c5.6,10.1,10.5,21.6,14.7,34.3c4,12.3,7.4,25.7,9.9,40.1c2.5,14,4.3,29,5.4,44.7c1.1,15.4,1.4,31.6,1,48.5
					c-0.4,16.6-1.5,34-3.3,52c-1.8,17.8-4.3,35.5-7.4,52.9c-3.1,17.3-6.7,34.4-10.9,51.2c-4.2,16.8-8.9,33.3-14.1,49.4
					c-5.2,16.2-11,32-17.1,47.4c-6.2,15.5-12.9,30.5-20,44.8c-7.2,14.5-14.9,28.5-22.9,41.5c-8.2,13.3-16.8,25.8-25.8,37.3
					c-9.2,11.7-18.9,22.4-28.9,31.7c-10.3,9.6-20.5,17.3-30.5,23.3c-10.3,6.1-20.5,10.2-30.4,12.5c-10.2,2.3-20.1,2.5-29.5,0.6
					c-9.7-1.9-19-6.1-27.7-12.4c-8.9-6.6-17.1-15.4-24.5-26.7c-7.6-11.5-14.1-25.5-19.6-41.9c-5.6-16.8-9.9-36-12.8-57.5
					c-2.9-22-4.3-46.2-3.9-72.6c0.4-26.7,2.6-54.1,6.4-81.7c3.9-27.7,9.4-55.4,16.3-82.5c6.9-27,15.2-53.3,24.6-78.2
					c9.3-24.7,19.7-48.1,30.9-69.5c11-21.1,22.8-40.4,35-57.3c12-16.6,24.4-30.9,36.9-42.7c12.2-11.5,24.6-20.5,36.9-26.8
					C584.9,88.5,596.7,84.9,608.2,84z"
                />
              </g>
              <g className="st0">
                <defs>
                  <path
                    id="SVGID_9_"
                    className="st0"
                    d="M584.7,73.7c2.5,0.2,5.9,0.4,8.4,0.6c2.4,0.2,5.6,0.6,8,0.9c2.3,0.3,5.4,0.8,7.6,1.2
						c5.2,1,7.9,1.7,8.4,1.8c2.5,0.6,5.8,1.5,8.3,2.3c2.3,0.7,5.4,1.8,7.6,2.7c1.9,0.8,7,2.9,12.5,6.1c6.5,3.9,10.5,7.3,13.4,9.7
						c1.9,1.6,4.2,3.9,6,5.7c1.3,1.4,3,3.3,4.3,4.7c0,0,3.8,4.3,8.6,11.3c3.2,4.8,5.5,8.8,7.4,12.4c1.8,3.3,5,9.8,6.5,13.5
						c0.9,2.1,2.1,4.9,2.9,7c1.4,3.6,3.2,8.5,4.4,12.2c2.9,8.9,4.5,15.5,4.6,15.5c0.6,2.4,1.5,5.6,2,8.1c2.5,11.2,2.7,12.1,3.4,16.7
						c1.3,8,2.1,12.3,2.7,17.4c0.3,2.7,0.8,6.3,1.1,9c0.3,2.7,0.7,6.4,0.9,9.1c0.2,2.8,0.6,6.5,0.7,9.3c0.2,2.8,0.4,6.6,0.6,9.4
						c0.1,2.9,0.3,6.7,0.4,9.6c0.1,2.9,0.2,6.8,0.2,9.7c0.2,12.6-0.1,23.3-0.4,30c-0.3,6.2-0.8,14.5-1.2,20.7
						c-0.2,3.2-0.6,7.4-0.8,10.6c-0.2,2.4-1.3,14-2.2,21.4c-0.4,3.2-0.9,7.5-1.3,10.7c-1.9,13.9-1.9,13.9-3.1,21.1
						c-0.6,3.9-2.9,17.6-5.8,31.2c-2,9.5-6.6,31.6-15.1,60.5c-7.3,24.8-14.6,45.1-19.5,57.7c-1.7,4.4-5.7,14.7-11.3,27.6
						c-2.4,5.4-5.7,12.5-8.1,17.8c-1.2,2.6-2.9,6.1-4.2,8.7c-2.1,4.1-6.9,13.8-13.4,25.4c-2.8,4.9-6.6,11.4-9.5,16.2
						c-1.4,2.4-3.5,5.5-4.9,7.9c-2.7,4.2-3.2,5-10.2,15.2c-1.5,2.2-3.7,5.1-5.3,7.3c-1.7,2.3-6.9,9.1-11,14
						c-4.1,4.9-15,17.9-29.6,30.5c-3.5,3.1-8.5,6.9-12.2,9.7c-1.8,1.4-4.3,3-6.1,4.3c-3.5,2.5-8.5,5.3-12.3,7.3
						c-3.6,1.9-8.6,4-12.3,5.6c-1.8,0.8-4.3,1.5-6.1,2.2c-0.1,0-6.8,2.2-13.6,3.2c-1.7,0.2-3.9,0.6-5.6,0.7
						c-2.4,0.2-5.6,0.4-7.9,0.4c-2.2,0-5.1-0.1-7.3-0.3c-1.2-0.1-2.9-0.2-4.1-0.4c-1.6-0.2-3.6-0.5-5.2-0.7c-4-0.7-5.7-1-8.1-1.6
						c-2.2-0.5-5.2-1.3-7.4-1.9c-1.2-0.3-2.8-0.8-4-1.2c-6.1-1.9-13-4.5-19.9-7.6c-1.2-0.5-2.4-1.1-3.5-1.7
						c-1.2-0.6-2.3-1.2-3.5-1.9c-1.2-0.7-2.3-1.3-3.4-2.1c-1.1-0.7-2.3-1.5-3.4-2.3c-9.5-6.7-18.3-15.7-26.2-27.1
						c-8.1-11.7-15.3-25.9-21.2-42.5c-6.1-17-10.9-36.4-14.2-58.2c-3.4-22.2-5.1-46.7-5-73.3c0.1-27,2.1-54.7,5.8-82.5
						c3.7-28,9.2-55.9,16.2-83.2c7-27.2,15.4-53.7,25.1-78.8c9.6-24.9,20.3-48.3,31.9-69.9c11.4-21.2,23.6-40.5,36.4-57.4
						c12.5-16.6,25.4-30.9,38.5-42.6c12.8-11.4,25.7-20.3,38.6-26.6c12.5-6,24.9-9.5,37.1-10.3c4.6,0.3,8.9,0.7,12.9,1.4
						c4,0.6,7.6,1.5,10.6,2.4c3,0.9,5.4,2,7.1,3.1c1.7,1.1,2.5,2.2,2.4,3.4c-11.5,0.9-23.3,4.6-35.2,10.7
						c-12.3,6.4-24.6,15.4-36.9,26.8c-12.6,11.8-24.9,26.1-36.9,42.7c-12.2,16.9-24,36.2-35,57.3c-11.2,21.5-21.6,44.8-30.9,69.5
						c-9.4,25-17.7,51.2-24.6,78.2c-6.9,27.1-12.4,54.7-16.3,82.5c-3.8,27.5-6,54.9-6.4,81.7c-0.4,26.3,1,50.6,3.9,72.6
						c2.9,21.5,7.2,40.7,12.8,57.5c5.4,16.4,12,30.3,19.6,41.9c7.3,11.2,15.6,20.1,24.5,26.7c8.6,6.4,17.9,10.5,27.7,12.4
						c9.4,1.9,19.3,1.7,29.5-0.6c9.9-2.2,20-6.4,30.4-12.5c10-5.9,20.2-13.7,30.5-23.3c10-9.3,19.6-20,28.9-31.7
						c9-11.5,17.6-24,25.8-37.3c8-13.1,15.7-27,22.9-41.5c7.1-14.3,13.8-29.3,20-44.8c6.2-15.3,11.9-31.2,17.1-47.4
						c5.2-16.1,9.9-32.6,14.1-49.4c4.2-16.8,7.9-33.9,10.9-51.2c3.1-17.4,5.6-35.1,7.4-52.9c1.9-18,2.9-35.3,3.3-52
						c0.4-16.9,0-33.1-1-48.5c-1.1-15.7-2.9-30.6-5.4-44.7c-2.6-14.3-5.9-27.7-9.9-40.1c-4.1-12.6-9-24.1-14.7-34.3
						c-5.8-10.4-12.4-19.5-19.8-26.8c-7.6-7.6-16-13.4-25.2-17.1c-9.5-3.9-19.9-5.5-31-4.6c0.1-1.1-0.8-2.3-2.4-3.4
						c-1.7-1.1-4.1-2.1-7.1-3.1c-3-0.9-6.6-1.7-10.6-2.4c-4-0.6-8.3-1.1-12.9-1.4c0.8-0.1,1.6-0.1,2.4-0.1c0.8,0,1.6,0,2.4,0
						c0.8,0,1.6,0,2.3,0C583.2,73.7,583.9,73.7,584.7,73.7z"
                  />
                </defs>
                <g className="st9">
                  <path
                    fill="#BFBFBF"
                    d="M584.7,73.7c16.6,1.1,40.9,3.9,60.9,15.8c22.9,13.6,34.6,34.8,39.6,43.8c10.4,18.9,34.2,73.8,29.9,176.6
						c-4.3,104.5-32.6,191.3-49,233.8c-22,56.7-42.8,89.6-51.6,103.7c-7.7,12.2-28.6,43.2-56.1,67c-21.2,18.3-42.3,29.2-62.6,32.3
						c-25.8,3.9-50.3-4.5-68.6-12.3c-2.1-0.9-3.3-1.5-4.5-2.1c-1.2-0.6-2.3-1.2-3.5-1.9c-1.2-0.7-2.3-1.3-3.4-2.1
						c-1.1-0.7-2.3-1.5-3.4-2.3c-9.5-6.7-18.3-15.7-26.2-27.1c-8.1-11.7-15.3-25.9-21.2-42.5c-6.1-17-10.9-36.4-14.2-58.2
						c-3.4-22.2-5.1-46.7-5-73.3c0.1-27,2.1-54.7,5.8-82.5c3.7-28,9.2-55.9,16.2-83.2c7-27.2,15.4-53.7,25.1-78.8
						c9.6-24.9,20.3-48.3,31.9-69.9c11.4-21.2,23.6-40.5,36.4-57.4c12.5-16.6,25.4-30.9,38.5-42.6c12.8-11.4,25.7-20.3,38.6-26.6
						c12.5-6,24.9-9.5,37.1-10.3c4.6,0.3,8.9,0.7,12.9,1.4c4,0.6,7.6,1.5,10.6,2.4c3,0.9,5.4,2,7.1,3.1c1.7,1.1,2.5,2.2,2.4,3.4
						c-11.5,0.9-23.3,4.6-35.2,10.7c-12.3,6.4-24.6,15.4-36.9,26.8c-12.6,11.8-24.9,26.1-36.9,42.7c-12.2,16.9-24,36.2-35,57.3
						c-11.2,21.5-21.6,44.8-30.9,69.5c-9.4,25-17.7,51.2-24.6,78.2c-6.9,27.1-12.4,54.7-16.3,82.5c-3.8,27.5-6,54.9-6.4,81.7
						c-0.4,26.3,1,50.6,3.9,72.6c2.9,21.5,7.2,40.7,12.8,57.5c5.4,16.4,12,30.3,19.6,41.9c7.3,11.2,15.6,20.1,24.5,26.7
						c8.6,6.4,17.9,10.5,27.7,12.4c9.4,1.9,19.3,1.7,29.5-0.6c9.9-2.2,20-6.4,30.4-12.5c10-5.9,20.2-13.7,30.5-23.3
						c10-9.3,19.6-20,28.9-31.7c9-11.5,17.6-24,25.8-37.3c8-13.1,15.7-27,22.9-41.5c7.1-14.3,13.8-29.3,20-44.8
						c6.2-15.3,11.9-31.2,17.1-47.4c5.2-16.1,9.9-32.6,14.1-49.4c4.2-16.8,7.9-33.9,10.9-51.2c3.1-17.4,5.6-35.1,7.4-52.9
						c1.9-18,2.9-35.3,3.3-52c0.4-16.9,0-33.1-1-48.5c-1.1-15.7-2.9-30.6-5.4-44.7c-2.6-14.3-5.9-27.7-9.9-40.1
						c-4.1-12.6-9-24.1-14.7-34.3c-5.8-10.4-12.4-19.5-19.8-26.8c-7.6-7.6-16-13.4-25.2-17.1c-9.5-3.9-19.9-5.5-31-4.6
						c0.1-1.1-0.8-2.3-2.4-3.4c-1.7-1.1-4.1-2.1-7.1-3.1c-3-0.9-6.6-1.7-10.6-2.4c-4-0.6-8.3-1.1-12.9-1.4c3.2-0.1,4-0.2,4.8-0.2
						C583.2,73.7,583.9,73.7,584.7,73.7"
                  />
                  <path
                    fill="#BFBFBF"
                    d="M607.2,81.8C515,91.3,426.2,232.5,396.1,417.5c21.3-79.5,86.3-321.6,212-334.3c0,0.1,0,0.1,0,0.2
						c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2c-11.5,0.9-23.3,4.6-35.2,10.7c-12.3,6.4-24.6,15.4-36.9,26.8
						c-12.6,11.8-24.9,26.1-36.9,42.7c-12.2,16.9-24,36.2-35,57.3c-11.2,21.5-21.6,44.8-30.9,69.5c-9.4,25-17.7,51.2-24.6,78.2
						c-6.9,27.1-12.4,54.7-16.3,82.5c-3.8,27.5-6,54.9-6.4,81.7c-0.4,26.3,1,50.6,3.9,72.6c2.9,21.5,7.2,40.7,12.8,57.5
						c5.4,16.4,12,30.3,19.6,41.9c7.3,11.2,15.6,20.1,24.5,26.7c8.6,6.4,17.9,10.5,27.7,12.4c9.4,1.9,19.3,1.7,29.5-0.6
						c9.9-2.2,20-6.4,30.4-12.5c10-5.9,20.2-13.7,30.5-23.3c10-9.3,19.6-20,28.9-31.7c9-11.5,17.6-24,25.8-37.3
						c8-13.1,15.7-27,22.9-41.5c7.1-14.3,13.8-29.3,20-44.8c6.2-15.3,11.9-31.2,17.1-47.4c5.2-16.1,9.9-32.6,14.1-49.4
						c4.2-16.8,7.9-33.9,10.9-51.2c3.1-17.4,5.6-35.1,7.4-52.9c1.9-18,2.9-35.3,3.3-52c0.4-16.9,0-33.1-1-48.5
						c-1.1-15.7-2.9-30.6-5.4-44.7c-2.6-14.3-5.9-27.7-9.9-40.1c-4.1-12.6-9-24.1-14.7-34.3c-5.8-10.4-12.4-19.5-19.8-26.8
						c-7.6-7.6-16-13.4-25.2-17.1c-9.5-3.9-19.9-5.5-31-4.6c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2
						c0-0.1,0-0.1,0-0.2v0c2.9-0.2,6.7-0.4,9.5-0.8C614.6,82,610.4,81.8,607.2,81.8c-0.9-0.9-2.4-1.9-4.4-2.7
						c-2-0.9-4.4-1.7-7.2-2.4c-2.8-0.7-6-1.3-9.4-1.8c-3.4-0.5-7.1-0.9-10.9-1.1c0.8-0.1,1.6-0.1,2.4-0.1c0.8,0,1.6,0,2.4,0
						c0.8,0,1.6,0,2.3,0c0.8,0,1.6,0.1,2.3,0.1v0c6.8,0.4,18.2,1.2,32.5,4.6l4.2,1.1c7.2,2.1,8.9,2.7,15.1,5.4l4.6,2.2
						c2.6,1.4,6,3.5,8.6,5.1l4.7,3.4c1.8,1.5,4.2,3.5,6,5l4.5,4.4c1.5,1.6,3.4,3.7,4.8,5.4l4.1,5.2c1.2,1.8,2.9,4.1,4.1,5.8l3.7,5.9
						c1.1,1.9,2.6,4.4,3.6,6.4l3.3,6.5c0.9,2.1,2.2,4.8,3.1,6.9l2.9,7c1.3,3.6,3.1,8.5,4.4,12.2l2.4,7.6c0.7,2.4,1.6,5.5,2.2,7.9
						l2,8.1c0.5,2.5,1.3,5.8,1.8,8.3l1.6,8.4c0.4,2.6,1,6,1.4,8.6l1.3,8.8c0.3,2.7,0.8,6.3,1.1,9l0.9,9.1c0.2,2.8,0.5,6.5,0.7,9.3
						l0.6,9.4c0.1,2.9,0.3,6.7,0.4,9.6l0.2,9.7c0,3,0.1,6.9,0,9.9l-0.1,10c-0.1,3-0.2,7.1-0.3,10.1l-0.5,10.3
						c-0.2,3.1-0.4,7.3-0.7,10.4l-0.8,10.5c-0.3,3.2-0.7,7.5-1,10.7l-1.2,10.7c-0.4,3.2-0.9,7.5-1.3,10.7l-1.5,10.6
						c-0.5,3.2-1.1,7.4-1.6,10.5l-1.8,10.5c-0.6,3.1-1.3,7.3-1.9,10.4l-2.1,10.3c-0.7,3.1-1.5,7.2-2.2,10.3l-2.3,10.2
						c-0.7,3-1.7,7.1-2.5,10.1l-2.6,10c-0.8,3-1.9,7-2.7,10l-2.8,9.9c-0.9,2.9-2,6.9-3,9.8l-3.1,9.7c-1,2.9-2.2,6.8-3.2,9.7
						l-3.3,9.6c-1,2.8-2.4,6.7-3.4,9.5l-3.5,9.4c-1.1,2.8-2.5,6.5-3.7,9.3l-3.8,9.2c-1.2,2.7-2.7,6.4-3.9,9.1l-4,9
						c-1.2,2.7-2.9,6.2-4.1,8.9l-4.2,8.7c-1.3,2.6-3,6-4.4,8.6l-4.5,8.5c-1.4,2.5-3.2,5.8-4.6,8.3l-4.7,8.2c-1.4,2.4-3.4,5.6-4.8,8
						l-4.9,7.9c-1.5,2.3-3.5,5.4-5.1,7.7l-5.2,7.5c-1.6,2.2-3.7,5.1-5.3,7.3l-5.4,7.1c-1.7,2.1-3.9,4.8-5.6,6.9l-5.7,6.6
						c-1.7,1.9-4,4.5-5.8,6.4l-5.9,6.1c-1.8,1.8-4.2,4.2-6.1,5.9l-6.1,5.4c-10.1,8.4-27,22.5-51.5,29.9l-5.5,1.4
						c-6.8,1.3-8.1,1.5-14.5,2l-4.7,0.1c-2.2-0.1-5.1-0.1-7.3-0.3l-4.1-0.4c-1.6-0.2-3.6-0.5-5.2-0.7l-3.8-0.7
						c-1.3-0.3-3.1-0.6-4.4-1l-3.6-0.9c-1.2-0.3-2.8-0.8-4.1-1.1l-3.6-1.1c-1.2-0.4-2.8-0.9-3.9-1.3l-3.7-1.3
						c-1.2-0.4-2.8-1-3.9-1.5l-3.7-1.5c-1.4-0.6-3.3-1.4-4.7-2c-1.2-0.5-2.4-1.1-3.5-1.7c-1.2-0.6-2.3-1.2-3.5-1.9
						c-1.2-0.7-2.3-1.3-3.4-2.1c-1.1-0.7-2.3-1.5-3.4-2.3c-9.5-6.7-18.3-15.7-26.2-27.1c-8.1-11.7-15.3-25.9-21.2-42.5
						c-6.1-17-10.9-36.4-14.2-58.2c-3.4-22.2-5.1-46.7-5-73.3c0.1-27,2.1-54.7,5.8-82.5c3.7-28,9.2-55.9,16.2-83.2
						c7-27.2,15.4-53.7,25.1-78.8c9.6-24.9,20.3-48.3,31.9-69.9c11.4-21.2,23.6-40.5,36.4-57.4c12.5-16.6,25.4-30.9,38.5-42.6
						c12.8-11.4,25.7-20.3,38.6-26.6c12.5-6,24.9-9.5,37.1-10.3c3.8,0.2,7.5,0.6,10.9,1.1c3.4,0.5,6.6,1.1,9.4,1.8
						c2.8,0.7,5.3,1.5,7.2,2.4C604.9,79.9,606.3,80.8,607.2,81.8"
                  />
                  <path
                    fill="#B3B3B3"
                    d="M605.8,80.6C457.2,96.8,387.2,405.8,390.4,459.1C433.5,196.3,544.3,90.5,608.2,83.6c0,0,0,0.1,0,0.1
						s0,0.1,0,0.1s0,0.1,0,0.1c0,0,0,0.1,0,0.1c-11.5,0.9-23.3,4.6-35.2,10.7c-12.3,6.4-24.6,15.4-36.9,26.8
						c-12.6,11.8-24.9,26.1-36.9,42.7c-12.2,16.9-24,36.2-35,57.3c-11.2,21.5-21.6,44.8-30.9,69.5c-9.4,25-17.7,51.2-24.6,78.2
						c-6.9,27.1-12.4,54.7-16.3,82.5c-3.8,27.5-6,54.9-6.4,81.7c-0.4,26.3,1,50.6,3.9,72.6c2.9,21.5,7.2,40.7,12.8,57.5
						c5.4,16.4,12,30.3,19.6,41.9c7.3,11.2,15.6,20.1,24.5,26.7c8.6,6.4,17.9,10.5,27.7,12.4c9.4,1.9,19.3,1.7,29.5-0.6
						c9.9-2.2,20-6.4,30.4-12.5c10-5.9,20.2-13.7,30.5-23.3c10-9.3,19.6-20,28.9-31.7c9-11.5,17.6-24,25.8-37.3
						c8-13.1,15.7-27,22.9-41.5c7.1-14.3,13.8-29.3,20-44.8c6.2-15.3,11.9-31.2,17.1-47.4c5.2-16.1,9.9-32.6,14.1-49.4
						c4.2-16.8,7.9-33.9,10.9-51.2c3.1-17.4,5.6-35.1,7.4-52.9c1.9-18,2.9-35.3,3.3-52c0.4-16.9,0-33.1-1-48.5
						c-1.1-15.7-2.9-30.6-5.4-44.7c-2.6-14.3-5.9-27.7-9.9-40.1c-4.1-12.6-9-24.1-14.7-34.3c-5.8-10.4-12.4-19.5-19.8-26.8
						c-7.6-7.6-16-13.4-25.2-17.1c-9.5-3.9-19.9-5.5-31-4.6c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1s0-0.1,0-0.1s0-0.1,0-0.1v0
						c22.4-1,36.6,4.1,54.4,19.6C648,87.7,628.9,80.1,605.8,80.6c-1.2-0.8-2.9-1.6-4.9-2.4c-2-0.7-4.4-1.4-7.1-2
						c-2.7-0.6-5.6-1.1-8.7-1.5c-3.1-0.4-6.4-0.7-9.9-0.9c0.8-0.1,1.6-0.1,2.4-0.1c0.8,0,1.6,0,2.4,0c0.8,0,1.6,0,2.3,0
						c0.8,0,1.6,0.1,2.3,0.1v0c6.8,0.4,18.2,1.2,32.5,4.6l4.2,1.1c7.2,2.1,8.9,2.7,15.1,5.4l4.6,2.2c2.6,1.4,6,3.5,8.6,5.1l4.7,3.4
						c1.8,1.5,4.2,3.5,6,5l4.5,4.4c1.5,1.6,3.4,3.7,4.8,5.4l4.1,5.2c1.2,1.8,2.9,4.1,4.1,5.8l3.7,5.9c1.1,1.9,2.6,4.4,3.6,6.4
						l3.3,6.5c0.9,2.1,2.2,4.8,3.1,6.9l2.9,7c1.3,3.6,3.1,8.5,4.4,12.2l2.4,7.6c0.7,2.4,1.6,5.5,2.2,7.9l2,8.1
						c0.5,2.5,1.3,5.8,1.8,8.3l1.6,8.4c0.4,2.6,1,6,1.4,8.6l1.3,8.8c0.3,2.7,0.8,6.3,1.1,9l0.9,9.1c0.2,2.8,0.5,6.5,0.7,9.3l0.6,9.4
						c0.1,2.9,0.3,6.7,0.4,9.6l0.2,9.7c0,3,0.1,6.9,0,9.9l-0.1,10c-0.1,3-0.2,7.1-0.3,10.1l-0.5,10.3c-0.2,3.1-0.4,7.3-0.7,10.4
						l-0.8,10.5c-0.3,3.2-0.7,7.5-1,10.7l-1.2,10.7c-0.4,3.2-0.9,7.5-1.3,10.7l-1.5,10.6c-0.5,3.2-1.1,7.4-1.6,10.5l-1.8,10.5
						c-0.6,3.1-1.3,7.3-1.9,10.4l-2.1,10.3c-0.7,3.1-1.5,7.2-2.2,10.3l-2.3,10.2c-0.7,3-1.7,7.1-2.5,10.1l-2.6,10
						c-0.8,3-1.9,7-2.7,10l-2.8,9.9c-0.9,2.9-2,6.9-3,9.8l-3.1,9.7c-1,2.9-2.2,6.8-3.2,9.7l-3.3,9.6c-1,2.8-2.4,6.7-3.4,9.5
						l-3.5,9.4c-1.1,2.8-2.5,6.5-3.7,9.3l-3.8,9.2c-1.2,2.7-2.7,6.4-3.9,9.1l-4,9c-1.2,2.7-2.9,6.2-4.1,8.9l-4.2,8.7
						c-1.3,2.6-3,6-4.4,8.6l-4.5,8.5c-1.4,2.5-3.2,5.8-4.6,8.3l-4.7,8.2c-1.4,2.4-3.4,5.6-4.8,8l-4.9,7.9c-1.5,2.3-3.5,5.4-5.1,7.7
						l-5.2,7.5c-1.6,2.2-3.7,5.1-5.3,7.3l-5.4,7.1c-1.7,2.1-3.9,4.8-5.6,6.9l-5.7,6.6c-1.7,1.9-4,4.5-5.8,6.4l-5.9,6.1
						c-1.8,1.8-4.2,4.2-6.1,5.9l-6.1,5.4c-10.1,8.4-27,22.5-51.5,29.9l-5.5,1.4c-6.8,1.3-8.1,1.5-14.5,2l-4.7,0.1
						c-2.2-0.1-5.1-0.1-7.3-0.3l-4.1-0.4c-1.6-0.2-3.6-0.5-5.2-0.7l-3.8-0.7c-1.3-0.3-3.1-0.6-4.4-1l-3.6-0.9
						c-1.2-0.3-2.8-0.8-4.1-1.1l-3.6-1.1c-1.2-0.4-2.8-0.9-3.9-1.3l-3.7-1.3c-1.2-0.4-2.8-1-3.9-1.5l-3.7-1.5
						c-1.4-0.6-3.3-1.4-4.7-2c-1.2-0.5-2.4-1.1-3.5-1.7c-1.2-0.6-2.3-1.2-3.5-1.9c-1.2-0.7-2.3-1.3-3.4-2.1
						c-1.1-0.7-2.3-1.5-3.4-2.3c-9.5-6.7-18.3-15.7-26.2-27.1c-8.1-11.7-15.3-25.9-21.2-42.5c-6.1-17-10.9-36.4-14.2-58.2
						c-3.4-22.2-5.1-46.7-5-73.3c0.1-27,2.1-54.7,5.8-82.5c3.7-28,9.2-55.9,16.2-83.2c7-27.2,15.4-53.7,25.1-78.8
						c9.6-24.9,20.3-48.3,31.9-69.9c11.4-21.2,23.6-40.5,36.4-57.4c12.5-16.6,25.4-30.9,38.5-42.6c12.8-11.4,25.7-20.3,38.6-26.6
						c12.5-6,24.9-9.5,37.1-10.3c3.4,0.2,6.8,0.5,9.9,0.9c3.1,0.4,6.1,0.9,8.7,1.5c2.7,0.6,5,1.3,7.1,2
						C603,79,604.6,79.8,605.8,80.6"
                  />
                  <g>
                    <path
                      fill="#A6A6A6"
                      d="M656.6,97.2c-15.2-11.6-28.8-18.3-53.4-18c-1.5-0.7-3.2-1.3-5.2-1.9c-2-0.6-4.2-1.1-6.6-1.6
							c-2.4-0.5-5-0.9-7.7-1.2c-2.7-0.3-5.5-0.6-8.5-0.7c0.8-0.1,1.6-0.1,2.4-0.1c0.8,0,1.6,0,2.4,0c0.8,0,1.6,0,2.3,0
							c0.8,0,1.6,0.1,2.3,0.1v0c6.8,0.4,18.2,1.2,32.5,4.6l4.2,1.1c7.2,2.1,8.9,2.7,15.1,5.4l4.6,2.2c2.6,1.4,6,3.5,8.6,5.1l4.7,3.4
							L656.6,97.2L656.6,97.2"
                    />
                    <path
                      fill="#A6A6A6"
                      d="M603.2,79.2C474.6,90,380.4,342.1,385.2,551.8c4.7-249.7,123.8-456.6,223-467.9c0,0,0,0,0,0c0,0,0,0,0,0
							c0,0,0,0,0,0c0,0,0,0,0,0c-11.5,0.9-23.3,4.6-35.2,10.7c-12.3,6.4-24.6,15.4-36.9,26.8c-12.6,11.8-24.9,26.1-36.9,42.7
							c-12.2,16.9-24,36.2-35,57.3c-11.2,21.5-21.6,44.8-30.9,69.5c-9.4,25-17.7,51.2-24.6,78.2c-6.9,27.1-12.4,54.7-16.3,82.5
							c-3.8,27.5-6,54.9-6.4,81.7c-0.4,26.3,1,50.6,3.9,72.6c2.9,21.5,7.2,40.7,12.8,57.5c5.4,16.4,12,30.3,19.6,41.9
							c7.3,11.2,15.6,20.1,24.5,26.7c8.6,6.4,17.9,10.5,27.7,12.4c9.4,1.9,19.3,1.7,29.5-0.6c9.9-2.2,20-6.4,30.4-12.5
							c10-5.9,20.2-13.7,30.5-23.3c10-9.3,19.6-20,28.9-31.7c9-11.5,17.6-24,25.8-37.3c8-13.1,15.7-27,22.9-41.5
							c7.1-14.3,13.8-29.3,20-44.8c6.2-15.3,11.9-31.2,17.1-47.4c5.2-16.1,9.9-32.6,14.1-49.4c4.2-16.8,7.9-33.9,10.9-51.2
							c3.1-17.4,5.6-35.1,7.4-52.9c1.9-18,2.9-35.3,3.3-52c0.4-16.9,0-33.1-1-48.5c-1.1-15.7-2.9-30.6-5.4-44.7
							c-2.6-14.3-5.9-27.7-9.9-40.1c-4.1-12.6-9-24.1-14.7-34.3c-5.8-10.4-12.4-19.5-19.8-26.8c-7.6-7.6-16-13.4-25.2-17.1
							c-9.5-3.9-19.9-5.5-31-4.6c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0s0,0,0,0l0,0c24.9-0.9,61.1,5.8,88,74.5
							c-3.2-8.6-4.7-12.5-9.4-22l3.2,6.6l1.7,3.8l2.9,7c1.3,3.6,3.1,8.5,4.4,12.2l2.4,7.6c0.7,2.4,1.6,5.5,2.2,7.9l2,8.1
							c0.5,2.5,1.3,5.8,1.8,8.3l1.6,8.4c0.4,2.6,1,6,1.4,8.6l1.3,8.8c0.3,2.7,0.8,6.3,1.1,9l0.9,9.1c0.2,2.8,0.5,6.5,0.7,9.3
							l0.6,9.4c0.1,2.9,0.3,6.7,0.4,9.6l0.2,9.7c0,3,0.1,6.9,0,9.9l-0.1,10c-0.1,3-0.2,7.1-0.3,10.1l-0.5,10.3
							c-0.2,3.1-0.4,7.3-0.7,10.4l-0.8,10.5c-0.3,3.2-0.7,7.5-1,10.7l-1.2,10.7c-0.4,3.2-0.9,7.5-1.3,10.7l-1.5,10.6
							c-0.5,3.2-1.1,7.4-1.6,10.5l-1.8,10.5c-0.6,3.1-1.3,7.3-1.9,10.4l-2.1,10.3c-0.7,3.1-1.5,7.2-2.2,10.3l-2.3,10.2
							c-0.7,3-1.7,7.1-2.5,10.1l-2.6,10c-0.8,3-1.9,7-2.7,10l-2.8,9.9c-0.9,2.9-2,6.9-3,9.8l-3.1,9.7c-1,2.9-2.2,6.8-3.2,9.7
							l-3.3,9.6c-1,2.8-2.4,6.7-3.4,9.5l-3.5,9.4c-1.1,2.8-2.5,6.5-3.7,9.3l-3.8,9.2c-1.2,2.7-2.7,6.4-3.9,9.1l-4,9
							c-1.2,2.7-2.9,6.2-4.1,8.9l-4.2,8.7c-1.3,2.6-3,6-4.4,8.6l-4.5,8.5c-1.4,2.5-3.2,5.8-4.6,8.3l-4.7,8.2c-1.4,2.4-3.4,5.6-4.8,8
							l-4.9,7.9c-1.5,2.3-3.5,5.4-5.1,7.7l-5.2,7.5c-1.6,2.2-3.7,5.1-5.3,7.3l-5.4,7.1c-1.7,2.1-3.9,4.8-5.6,6.9l-5.7,6.6
							c-1.7,1.9-4,4.5-5.8,6.4l-5.9,6.1c-1.8,1.8-4.2,4.2-6.1,5.9l-6.1,5.4c-10.1,8.4-27,22.5-51.5,29.9l-5.5,1.4
							c-6.8,1.3-8.1,1.5-14.5,2l-4.7,0.1c-2.2-0.1-5.1-0.1-7.3-0.3l-4.1-0.4c-1.6-0.2-3.6-0.5-5.2-0.7l-3.8-0.7
							c-1.3-0.3-3.1-0.6-4.4-1l-3.6-0.9c-1.2-0.3-2.8-0.8-4.1-1.1l-3.6-1.1c-1.2-0.4-2.8-0.9-3.9-1.3l-3.7-1.3
							c-1.2-0.4-2.8-1-3.9-1.5l-3.7-1.5c-1.4-0.6-3.3-1.4-4.7-2c-1.2-0.5-2.4-1.1-3.5-1.7c-1.2-0.6-2.3-1.2-3.5-1.9
							c-1.2-0.7-2.3-1.3-3.4-2.1c-1.1-0.7-2.3-1.5-3.4-2.3c-9.5-6.7-18.3-15.7-26.2-27.1c-8.1-11.7-15.3-25.9-21.2-42.5
							c-6.1-17-10.9-36.4-14.2-58.2c-3.4-22.2-5.1-46.7-5-73.3c0.1-27,2.1-54.7,5.8-82.5c3.7-28,9.2-55.9,16.2-83.2
							c7-27.2,15.4-53.7,25.1-78.8c9.6-24.9,20.3-48.3,31.9-69.9c11.4-21.2,23.6-40.5,36.4-57.4c12.5-16.6,25.4-30.9,38.5-42.6
							c12.8-11.4,25.7-20.3,38.6-26.6c12.5-6,24.9-9.5,37.1-10.3c2.9,0.2,5.8,0.4,8.5,0.7c2.7,0.3,5.3,0.7,7.7,1.2
							c2.4,0.5,4.6,1,6.6,1.6C600,77.9,601.7,78.5,603.2,79.2"
                    />
                  </g>
                  <g>
                    <path
                      fill="#999999"
                      d="M625.1,80.6c-12-3.5-21.6-3.5-27.9-3.5c-1.5-0.4-3-0.8-4.7-1.1c-1.7-0.3-3.4-0.7-5.3-0.9
							c-1.8-0.3-3.8-0.5-5.8-0.7c-2-0.2-4-0.4-6.1-0.5c0.8-0.1,1.6-0.1,2.4-0.1c0.8,0,1.6,0,2.4,0c0.8,0,1.6,0,2.3,0
							c0.8,0,1.6,0.1,2.3,0.1v0c6.8,0.4,18.2,1.2,32.5,4.6l4.2,1.1c3.6,1.1,7.4,2.2,12.1,4.1l-5.9-2.2
							C626.9,81.1,625.9,80.8,625.1,80.6"
                    />
                    <path
                      fill="#999999"
                      d="M597.2,77.1C385.4,90.8,333.9,629.6,438.6,725.9c110.2,101.4,285.9-190.2,276.5-458.7
							c-0.5-13.1-1.3-22.1-2-30l0.8,9.2l0.3,4.7l0.6,9.4c0.1,2.9,0.3,6.7,0.4,9.6l0.2,9.7c0,3,0.1,6.9,0,9.9l-0.1,10
							c-0.1,3-0.2,7.1-0.3,10.1l-0.5,10.3c-0.2,3.1-0.4,7.3-0.7,10.4l-0.8,10.5c-0.3,3.2-0.7,7.5-1,10.7l-1.2,10.7
							c-0.4,3.2-0.9,7.5-1.3,10.7l-1.5,10.6c-0.5,3.2-1.1,7.4-1.6,10.5l-1.8,10.5c-0.6,3.1-1.3,7.3-1.9,10.4l-2.1,10.3
							c-0.7,3.1-1.5,7.2-2.2,10.3l-2.3,10.2c-0.7,3-1.7,7.1-2.5,10.1l-2.6,10c-0.8,3-1.9,7-2.7,10l-2.8,9.9c-0.9,2.9-2,6.9-3,9.8
							l-3.1,9.7c-1,2.9-2.2,6.8-3.2,9.7l-3.3,9.6c-1,2.8-2.4,6.7-3.4,9.5l-3.5,9.4c-1.1,2.8-2.5,6.5-3.7,9.3l-3.8,9.2
							c-1.2,2.7-2.7,6.4-3.9,9.1l-4,9c-1.2,2.7-2.9,6.2-4.1,8.9l-4.2,8.7c-1.3,2.6-3,6-4.4,8.6l-4.5,8.5c-1.4,2.5-3.2,5.8-4.6,8.3
							l-4.7,8.2c-1.4,2.4-3.4,5.6-4.8,8l-4.9,7.9c-1.5,2.3-3.5,5.4-5.1,7.7l-5.2,7.5c-1.6,2.2-3.7,5.1-5.3,7.3l-5.4,7.1
							c-1.7,2.1-3.9,4.8-5.6,6.9l-5.7,6.6c-1.7,1.9-4,4.5-5.8,6.4l-5.9,6.1c-1.8,1.8-4.2,4.2-6.1,5.9l-6.1,5.4
							c-10.1,8.4-27,22.5-51.5,29.9l-5.5,1.4c-6.8,1.3-8.1,1.5-14.5,2l-4.7,0.1c-2.2-0.1-5.1-0.1-7.3-0.3l-4.1-0.4
							c-1.6-0.2-3.6-0.5-5.2-0.7l-3.8-0.7c-1.3-0.3-3.1-0.6-4.4-1l-3.6-0.9c-1.2-0.3-2.8-0.8-4.1-1.1l-3.6-1.1
							c-1.2-0.4-2.8-0.9-3.9-1.3l-3.7-1.3c-1.2-0.4-2.8-1-3.9-1.5l-3.7-1.5c-1.4-0.6-3.3-1.4-4.7-2c-1.2-0.5-2.4-1.1-3.5-1.7
							c-1.2-0.6-2.3-1.2-3.5-1.9c-1.2-0.7-2.3-1.3-3.4-2.1c-1.1-0.7-2.3-1.5-3.4-2.3c-9.5-6.7-18.3-15.7-26.2-27.1
							c-8.1-11.7-15.3-25.9-21.2-42.5c-6.1-17-10.9-36.4-14.2-58.2c-3.4-22.2-5.1-46.7-5-73.3c0.1-27,2.1-54.7,5.8-82.5
							c3.7-28,9.2-55.9,16.2-83.2c7-27.2,15.4-53.7,25.1-78.8c9.6-24.9,20.3-48.3,31.9-69.9c11.4-21.2,23.6-40.5,36.4-57.4
							c12.5-16.6,25.4-30.9,38.5-42.6c12.8-11.4,25.7-20.3,38.6-26.6c12.5-6,24.9-9.5,37.1-10.3c2.1,0.1,4.1,0.3,6.1,0.5
							c2,0.2,3.9,0.5,5.8,0.7c1.9,0.3,3.6,0.6,5.3,0.9C594.1,76.3,595.7,76.7,597.2,77.1"
                    />
                  </g>
                  <g>
                    <path
                      fill="#8C8C8C"
                      d="M604.6,75.8c-6.4-0.8-11.9-1.5-21.4-1.4c-0.6-0.1-1.3-0.1-1.9-0.2c-0.6-0.1-1.3-0.1-2-0.2
							c-0.7-0.1-1.3-0.1-2-0.2c-0.7,0-1.3-0.1-2-0.1c0.8-0.1,1.6-0.1,2.4-0.1c0.8,0,1.6,0,2.4,0c0.8,0,1.6,0,2.3,0
							c0.8,0,1.6,0.1,2.3,0.1v0c5.3,0.3,11.9,0.8,21.5,2.4L604.6,75.8"
                    />
                    <path
                      fill="#8C8C8C"
                      d="M457.4,158.2c-105.7,181.7-116,630.3,49.5,585.4c75.5-20.5,153.7-144.2,190-300.8l-2.4,10.1l-0.8,3.1
							l-2.6,10c-0.8,3-1.9,7-2.7,10l-2.8,9.9c-0.9,2.9-2,6.9-3,9.8l-3.1,9.7c-1,2.9-2.2,6.8-3.2,9.7l-3.3,9.6
							c-1,2.8-2.4,6.7-3.4,9.5l-3.5,9.4c-1.1,2.8-2.5,6.5-3.7,9.3l-3.8,9.2c-1.2,2.7-2.7,6.4-3.9,9.1l-4,9c-1.2,2.7-2.9,6.2-4.1,8.9
							l-4.2,8.7c-1.3,2.6-3,6-4.4,8.6l-4.5,8.5c-1.4,2.5-3.2,5.8-4.6,8.3l-4.7,8.2c-1.4,2.4-3.4,5.6-4.8,8l-4.9,7.9
							c-1.5,2.3-3.5,5.4-5.1,7.7l-5.2,7.5c-1.6,2.2-3.7,5.1-5.3,7.3l-5.4,7.1c-1.7,2.1-3.9,4.8-5.6,6.9l-5.7,6.6
							c-1.7,1.9-4,4.5-5.8,6.4l-5.9,6.1c-1.8,1.8-4.2,4.2-6.1,5.9l-6.1,5.4c-10.1,8.4-27,22.5-51.5,29.9l-5.5,1.4
							c-6.8,1.3-8.1,1.5-14.5,2l-4.7,0.1c-2.2-0.1-5.1-0.1-7.3-0.3l-4.1-0.4c-1.6-0.2-3.6-0.5-5.2-0.7l-3.8-0.7
							c-1.3-0.3-3.1-0.6-4.4-1l-3.6-0.9c-1.2-0.3-2.8-0.8-4.1-1.1l-3.6-1.1c-1.2-0.4-2.8-0.9-3.9-1.3l-3.7-1.3
							c-1.2-0.4-2.8-1-3.9-1.5l-3.7-1.5c-1.4-0.6-3.3-1.4-4.7-2c-1.2-0.5-2.4-1.1-3.5-1.7c-1.2-0.6-2.3-1.2-3.5-1.9
							c-1.2-0.7-2.3-1.3-3.4-2.1c-1.1-0.7-2.3-1.5-3.4-2.3c-9.5-6.7-18.3-15.7-26.2-27.1c-8.1-11.7-15.3-25.9-21.2-42.5
							c-6.1-17-10.9-36.4-14.2-58.2c-3.4-22.2-5.1-46.7-5-73.3c0.1-27,2.1-54.7,5.8-82.5c3.7-28,9.2-55.9,16.2-83.2
							c7-27.2,15.4-53.7,25.1-78.8c9.6-24.9,20.3-48.3,31.9-69.9c2.6-4.8,5.3-9.6,8-14.2c2.7-4.6,5.4-9.1,8.2-13.6
							c2.7-4.4,5.5-8.7,8.3-12.9c2.8-4.2,5.6-8.2,8.5-12.1L457.4,158.2"
                    />
                  </g>
                  <path
                    fill="#808080"
                    d="M380.6,319.9c-31,286.5,31.6,514.5,174.3,397.4c33.4-27.4,57.1-65.8,66-80.2c-1.9,3.1-4.5,7.2-6.5,10.3
						c-1.5,2.3-3.5,5.4-5.1,7.7l-5.2,7.5c-1.6,2.2-3.7,5.1-5.3,7.3l-5.4,7.1c-1.7,2.1-3.9,4.8-5.6,6.9l-5.7,6.6
						c-1.7,1.9-4,4.5-5.8,6.4l-5.9,6.1c-1.8,1.8-4.2,4.2-6.1,5.9l-6.1,5.4c-10.1,8.4-27,22.5-51.5,29.9l-5.5,1.4
						c-6.8,1.3-8.1,1.5-14.5,2l-4.7,0.1c-2.2-0.1-5.1-0.1-7.3-0.3l-4.1-0.4c-1.6-0.2-3.6-0.5-5.2-0.7l-3.8-0.7
						c-1.3-0.3-3.1-0.6-4.4-1l-3.6-0.9c-1.2-0.3-2.8-0.8-4.1-1.1l-3.6-1.1c-1.2-0.4-2.8-0.9-3.9-1.3l-3.7-1.3
						c-1.2-0.4-2.8-1-3.9-1.5l-3.7-1.5c-1.4-0.6-3.3-1.4-4.7-2c-1.2-0.5-2.4-1.1-3.5-1.7c-1.2-0.6-2.3-1.2-3.5-1.9
						c-1.2-0.7-2.3-1.3-3.4-2.1c-1.1-0.7-2.3-1.5-3.4-2.3c-9.5-6.7-18.3-15.7-26.2-27.1c-8.1-11.7-15.3-25.9-21.2-42.5
						c-6.1-17-10.9-36.4-14.2-58.2c-3.4-22.2-5.1-46.7-5-73.3c0.1-17.4,0.9-35.2,2.5-53.1c1.6-18,4-36.1,7-54.1
						c3.1-18.1,6.9-36.1,11.3-53.8c4.4-17.7,9.5-35.1,15-52h0C381.2,314.4,380.8,317.5,380.6,319.9"
                  />
                  <path
                    fill="#737373"
                    d="M352.6,462.4c16.5,113.1,55.1,378.1,204,253.4l-6.1,5c-8.2,6.1-23.5,17.6-43.7,23.4l-5.5,1.4
						c-6.8,1.3-8.1,1.5-14.5,2l-4.7,0.1c-2.2-0.1-5.1-0.1-7.3-0.3l-4.1-0.4c-1.6-0.2-3.6-0.5-5.2-0.7l-3.8-0.7
						c-1.3-0.3-3.1-0.6-4.4-1l-3.6-0.9c-1.2-0.3-2.8-0.8-4.1-1.1l-3.6-1.1c-1.2-0.4-2.8-0.9-3.9-1.3l-3.7-1.3
						c-1.2-0.4-2.8-1-3.9-1.5l-3.7-1.5c-1.4-0.6-3.3-1.4-4.7-2c-1.2-0.5-2.4-1.1-3.5-1.7c-1.2-0.6-2.3-1.2-3.5-1.9
						c-1.2-0.7-2.3-1.3-3.4-2.1c-1.1-0.7-2.3-1.5-3.4-2.3c-9.5-6.7-18.3-15.7-26.2-27.1c-8.1-11.7-15.3-25.9-21.2-42.5
						c-6.1-17-10.9-36.4-14.2-58.2c-3.4-22.2-5.1-46.7-5-73.3c0-6.3,0.1-12.5,0.4-18.9c0.2-6.3,0.5-12.7,1-19.1
						c0.4-6.4,0.9-12.9,1.6-19.3c0.6-6.5,1.3-12.9,2.2-19.4l1.3,9.9C352.2,459.4,352.4,461.1,352.6,462.4"
                  />
                  <path
                    fill="#CCCCCC"
                    d="M558.6,101.5c-96.4,69.6-141.4,232.2-156.2,285.6C431,245.3,494.9,133.2,569.2,94.5
						C566,96.6,561.7,99.2,558.6,101.5"
                  />
                  <path
                    fill="#D7D7D7"
                    d="M532.9,122.3c-75.3,77.5-110.5,194-123.8,237.9c15-62.8,52-178.1,130.4-244.4
						C537.5,117.8,534.8,120.3,532.9,122.3"
                  />
                  <path
                    fill="#DBDBDB"
                    d="M519.9,135.5c-54.1,63.9-83.7,140-103.4,199.2C440,250.1,474.8,183.1,519.9,135.5
						C519.8,135.5,519.9,135.5,519.9,135.5"
                  />
                  <path
                    fill="#DFDFDF"
                    d="M494.6,167.8c-34.4,51-54.5,99.7-69.7,140.7c20.6-63.8,45.6-112.6,78.8-153.6
						C501,158.8,497.3,163.9,494.6,167.8"
                  />
                  <path
                    fill="#8C8C8C"
                    d="M583.2,74.5C574,75,556.8,76,535.5,85.4c3.4-1.7,6.8-3.3,10.2-4.6c3.4-1.3,6.7-2.5,10-3.5
						c3.3-1,6.6-1.7,9.9-2.3c3.2-0.6,6.5-1,9.7-1.2c0.7,0,1.4,0.1,2,0.1c0.7,0,1.3,0.1,2,0.2c0.7,0.1,1.3,0.1,2,0.2
						C581.9,74.3,582.5,74.4,583.2,74.5"
                  />
                  <path
                    fill="#E2E2E2"
                    d="M482.2,186.4c-21.9,36-36.2,68.2-46.1,92.3c20.5-54.8,41.5-85.9,50.4-99.2
						C485.1,181.6,483.4,184.4,482.2,186.4"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </SvgIcon>
  );
};

export default Bale;
