import { useQuery } from "react-query";
import useAuth from "../hooks/useAuth";
import useAxios from "../hooks/useAxios";
import { IFarm } from "../types/farm";

function useFarms() {
  const { user } = useAuth();
  const { axios } = useAxios();

  const QFarms = useQuery<IFarm[], [string, string]>(
    ["farms", user.email],
    async (key, email) => await axios.get(`farms`),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1000 * 60 * 60 * 6,
      staleTime: 1000 * 60 * 60 * 6,
      enabled: !!user.email,
    }
  );

  return { QFarms };
}

export default useFarms;
