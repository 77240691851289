import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

interface Props {
  color?: string;
  opacity?: number;
}

const Pens = ({ color = "#000", opacity = 0.7 }: Props) => {
  return (
    <SvgIcon>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path
          d="M323.8,266.7c1.9-3.4,4.5-6.7,5.6-10.4c4.6-16.6,8.7-33.5,13.3-50.1c1.1-3.8,2.8-8.1,5.7-10.5c3.6-3,9.2-6.4,13.1-5.6
				c4.5,0.9,10.4,5.5,11.8,9.7c3.7,11.8,2.1,24.3-7,33c-4.6,4.4-13.2,4.7-20.1,6.9c15.8,11.6,7.4,28.1,3.1,43.3
				c-8.2,28.7-18.1,56.8-26.8,85.3c-1.5,5.1-1.1,11.1-0.4,16.5c1.5,10.9,4.9,21.5,5.5,32.4c0.5,10.7-5.4,15.7-16.2,14.4
				c-7.5-0.8-15.2-3.1-22.1-6.2c-21.5-9.5-42.8-9.3-64.4-0.3c-7.4,3-15.3,5.6-23.1,6.4c-10.8,1-16.6-4-16-14.7
				c0.5-10.5,4.2-20.8,5.7-31.3c0.8-5.8,1.3-12.2-0.3-17.7c-9.9-34.3-19.7-68.7-31.1-102.5c-4.3-12.7,4.1-19,7.8-25.9
				c-7.1-2.3-17.1-2.4-20.4-7.2c-5.2-7.5-7.8-18.2-7.9-27.5c0-5,6.6-12,12-14.6c6.8-3.4,13.5,1.6,15.7,8.5c6,18.5,11,37.3,16.2,56
				c1.4,5.1,1.3,11.1,6.5,10.8c0-14.7,1.5-28.8-0.4-42.4c-1.7-12.2-5.9-24.3-11.1-35.5c-7.4-16-15.2-17.8-31.1-10.6
				c-40.3,18.2-78.1,14.6-112.9-13.5c-1.2-0.9-2.3-1.8-3.4-2.8c-23.2-21.8-20.6-40.9,8.5-53.5c13.4-5.8,27.9-9.2,42.2-12.4
				c17.2-4,30.8,4.1,42.9,15.4c7.5,7,14.9,14.1,22.3,21c7.8-22.4,16.8-28.8,40.8-30.2c4-0.2,8.8-2.9,11.5-6
				c15.6-17,32.4-31.6,57.1-31.7c22.9-0.2,39.9,11.7,54.1,28.1c5.6,6.6,11,9.9,20.2,10.2c20.8,0.7,29.6,8.8,37,32.7
				c9.3-10,17.5-19.4,26.4-28c9.4-9.1,20.9-13.6,34.3-11.9c20,2.4,39.2,7.3,56.2,18.5c15.8,10.3,18,20.7,7.2,36.3
				c-17.2,24.8-42.3,36-71.5,38.6c-19.6,1.8-38.1-2.8-55.7-11.4c-13-6.3-20.6-4.4-27.7,8c-11.5,20.1-16.3,41.6-13.1,64.9
				c0.7,5.1-1.5,10.6-2.4,15.9C322.1,265.8,322.9,266.3,323.8,266.7z M207.7,411.3c3.3-6,6.6-10.8,8.7-16
				c4.5-11.6,10.1-15.7,22.6-14.9c7.7,0.5,15.5,2.4,23.1,1.6c25.5-2.8,25.5-3.2,37.2,20.3c0.3,0.7,0.6,1.5,1.1,1.9
				c2.3,1.7,4.8,4.6,7,4.4c1.9-0.2,4.1-3.7,5.1-6.2c5.1-13.1-1.9-28-16.7-32.4c-26-7.8-52.6-8-78.5,0.3
				C199.2,375.9,194.3,394.6,207.7,411.3z"
        />
        <path d="M290.5,439.4c-19.8,19-48.6,19-66.8,0.5C237.5,422.5,271.3,422,290.5,439.4z" />
        <path
          fill="#fff"
          d="M207.7,411.3c-13.4-16.7-8.5-35.4,9.5-41.1c25.9-8.3,52.5-8.1,78.5-0.3c14.8,4.4,21.8,19.3,16.7,32.4
				c-1,2.5-3.2,6-5.1,6.2c-2.2,0.2-4.7-2.7-7-4.4c-0.5-0.4-0.8-1.2-1.1-1.9c-11.7-23.5-11.7-23.2-37.2-20.3
				c-7.5,0.8-15.4-1.1-23.1-1.6c-12.5-0.8-18,3.3-22.6,14.9C214.3,400.5,211,405.3,207.7,411.3z"
        />

        <path
          fill="#fff"
          d="M276.9,184.2c0,47.9-9.4,132.5-20.9,132.5c-11.5,0-20.9-84.7-20.9-132.5s9.4-55,20.9-55
			C267.5,129.2,276.9,136.4,276.9,184.2z"
        />

        <path d="M31.1,88.4c9.7-4.2,19.6-7.2,29.5-9.7L39.4,9.3H15.3v87.9C19.9,93.9,25.1,91,31.1,88.4z" />
        <path
          d="M165.6,423.3L99.9,207.7c-4.6,0-9.2-0.2-13.7-0.7c-19.5-2.2-38.2-9.5-55.8-21.7l91,298.6h62.7L169,434.4
		C167.4,431.4,166.2,427.7,165.6,423.3z"
        />

        <path
          d="M372.2,99.1c2.5-2.6,5-5.1,7.6-7.6c10.5-10.2,22.9-16.2,36.4-17.6L396.5,9.3h-62.7l23.8,78.1
		C363.6,90.4,368.3,94.4,372.2,99.1z"
        />
        <path
          d="M421.7,207.2c-9,0.8-18,0.5-27.1-0.8c0.1,0.9,0.1,1.8,0.2,2.8L478.5,484h19.9V343.5l-43.6-143.2
		C444.6,203.8,433.6,206.1,421.7,207.2z"
        />
      </svg>
    </SvgIcon>
  );
};

export default Pens;
