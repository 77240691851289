import { useContext } from "react";
import { AuthCtx } from "../auth";
import useRole from "./useRole";

const useAuth = () => {
  const { accountInfo, role, ...rest } = useContext(AuthCtx);
  const { QRole } = useRole();

  return {
    user: {
      name: accountInfo.account.idToken.name,
      email: accountInfo.account.idToken.emails[0] || "",
      role: QRole.data?.roleId,
    },
    accountInfo,
    ...rest,
  } as any;
};

export default useAuth;
