// import "./WDYR";
import "react-app-polyfill/stable";
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./locales/i18n";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
// import cows from "../../images/tmr.jpg";
import SuspenseFallback from "./Screens/SuspenseFallback";

(async () => {
  if ("ResizeObserver" in window === false) {
    const module = await import("@juggle/resize-observer");
    //@ts-ignore
    window.ResizeObserver = module.ResizeObserver;
  }
})();

ReactDOM.render(
  <Suspense fallback={<SuspenseFallback full />}>
    <App />
  </Suspense>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
