import React from "react";
import useAuth from "../../hooks/useAuth";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import AccountCircle from "@material-ui/icons/AccountCircle";
import ExitToApp from "@material-ui/icons/ExitToApp";
import { Box } from "@material-ui/core";

interface IProps {
  menuExpanded?: boolean;
}

const User = ({ menuExpanded }: IProps) => {
  const { user, logout } = useAuth();
  return (
    <Box display="flex" flexDirection="column" alignItems="center" mt={2} py={2}>
      {menuExpanded ? (
        <>
          <Box>
            <AccountCircle fontSize="large" />
          </Box>
          <Typography variant="h6">{user.name}</Typography>
          <Typography variant="caption">{user.email}</Typography>
          <Box mt={2}>
            <Button onClick={logout} variant="outlined" color="primary" size="small" endIcon={<ExitToApp />}>
              Logout
            </Button>
          </Box>
        </>
      ) : (
        <>
          <Box>
            <Tooltip title={`${user.name} ${user.email}`} placement="right">
              <AccountCircle fontSize="large" />
            </Tooltip>
          </Box>
          <Box mt={2}>
            <IconButton onClick={logout} aria-label="Logout" color="primary">
              <ExitToApp />
            </IconButton>
          </Box>
        </>
      )}
    </Box>
  );
};

export default User;
