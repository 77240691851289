import React, { useState, useEffect } from "react";
import { IRecipeListItem } from "../Features/Recipes/common/types";
import { IIngredient } from "../Features/Ingredients/types";
import localForage from "localforage";
import { IGroup } from "../types/graphs2";
import useFarm from "../hooks/useFarm";
import { IPen, IPenType } from "../Features/Pens2/types";
import { IModule } from "../Features/Groups/types";
import { IFeature } from "../Features/LiteProPremium/types";
import useAuth from "../hooks/useAuth";
import { queryCache } from "react-query";
import useAxios from "../hooks/useAxios";
import { IMachine } from "../Features/Machines/types";

export interface Cache {
  recipes: IRecipeListItem[] | undefined;
  sharedRations: IRecipeListItem[] | undefined;
  ingredients: IIngredient[] | undefined;
  pens: IPen[] | undefined;
  groups: IGroup[] | undefined;
  penTypes: IPenType[] | undefined;
  modules: IModule[] | undefined;
  farmfeatures: IFeature[] | undefined;
  userfeatures: IFeature[] | undefined;
  machines: IMachine[] | undefined;
  replace: (key: keyof Cache, data: any) => void;
  add: (key: keyof Cache, data: any) => void;
  clear: (key: keyof Cache) => void;
}

export const CacheCtx = React.createContext<Cache | null>(null);

type CacheProviderProps = {
  children: any;
};

const getLF = (key: string) => localForage.getItem(key);
const addLF = (key: string, value: any) => localForage.setItem(key, value);
const removeLF = (key: string) => localForage.removeItem(key);

export default ({ children }: CacheProviderProps) => {
  const { axios } = useAxios();
  const [farm] = useFarm();
  const { user } = useAuth();
  const [recipes, setRecipes] = useState<IRecipeListItem[]>([]);
  const [sharedRations, setSharedRations] = useState<IRecipeListItem[]>([]);
  const [ingredients, setIngredients] = useState<IIngredient[]>([]);
  const [groups, setGroups] = useState<IGroup[]>([]);
  const [pens, setPens] = useState<IPen[]>();
  const [penTypes, setPenTypes] = useState<IPenType[]>([]);
  const [modules, setModules] = useState<IModule[]>();
  const [farmfeatures, setFarmFeatures] = useState<IFeature[]>([]);
  const [userfeatures, setUserFeatures] = useState<IFeature[]>([]);
  const [machines, setMachines] = useState<IMachine[]>([]);

  const prefetchOptions = {
    refetchOnWindowFocus: false,
    cacheTime: 1000 * 60 * 60 * 24,
    staleTime: 0,
    retry: 0,
    enabled: !!farm?.farmId,
  };

  useEffect(() => {
    queryCache.prefetchQuery<IFeature[]>(
      ["userfeatures", farm.farmId],
      async () => await axios.get(`userfeatures/${farm.farmId}`),
      prefetchOptions
    );
    queryCache.prefetchQuery<IFeature[]>(
      ["farmfeatures", farm.farmId],
      async () => await axios.get(`features/${farm.farmId}`),
      prefetchOptions
    );
  }, []);

  useEffect(() => {
    getLF(`recipes-${farm.farmId}`)
      .then((res: any) => (res ? setRecipes(res) : setRecipes([])))
      .catch((err) => setRecipes([]));
    getLF(`sharedRations-${farm.farmId}`)
      .then((res: any) => (res ? setSharedRations(res) : setSharedRations([])))
      .catch((err) => setSharedRations([]));
    getLF(`ingredients-${farm.farmId}`)
      .then((res: any) => (res ? setIngredients(res) : setIngredients([])))
      .catch((err) => setIngredients([]));
    getLF(`groups-${farm.farmId}`)
      .then((res: any) => (res ? setGroups(res) : setGroups([])))
      .catch((err) => setGroups([]));
    getLF(`pens-${farm.farmId}`)
      .then((res: any) => (res ? setPens(res) : setPens([])))
      .catch((err) => setPens([]));
    getLF(`penTypes-${farm.farmId}`)
      .then((res: any) => (res ? setPenTypes(res) : setPenTypes([])))
      .catch((err) => setPenTypes([]));
    getLF(`modules-${farm.farmId}`)
      .then((res: any) => (res ? setModules(res) : setModules([])))
      .catch((err) => setModules([]));
    getLF(`farmfeatures-${farm.farmId}`)
      .then((res: any) => (res ? setFarmFeatures(res) : setFarmFeatures([])))
      .catch((err) => setFarmFeatures([]));
    getLF(`userfeatures-${user.email}`)
      .then((res: any) => (res ? setUserFeatures(res) : setUserFeatures([])))
      .catch((err) => setUserFeatures([]));
    getLF(`machines-${farm.farmId}`)
      .then((res: any) => (res ? setMachines(res) : setMachines([])))
      .catch((err) => setMachines([]));
  }, [farm]);

  const clear = (key: keyof Cache) => {
    update(key, []);
    removeLF(`${key}-${key === "userfeatures" ? user.email : farm.farmId}`);
  };

  const replace = (key: keyof Cache, data: any) => {
    update(key, data);
    addLF(`${key}-${key === "userfeatures" ? user.email : farm.farmId}`, data);
  };

  const update = (key: keyof Cache, data: any) => {
    switch (key) {
      case "recipes":
        setRecipes(data);
        break;
      case "sharedRations":
        setSharedRations(data);
        break;
      case "ingredients":
        setIngredients(data);
        break;
      case "groups":
        setGroups(data);
        break;
      case "pens":
        setPens(data);
        break;
      case "penTypes":
        setPenTypes(data);
        break;
      case "modules":
        setModules(data);
        break;
      case "farmfeatures":
        setFarmFeatures(data);
        break;
      case "userfeatures":
        setUserFeatures(data);
        break;
      case "machines":
        setMachines(data);
        break;
    }
  };
  const add = replace;
  return (
    <CacheCtx.Provider
      value={{
        recipes,
        sharedRations,
        ingredients,
        groups,
        pens,
        penTypes,
        modules,
        farmfeatures,
        userfeatures,
        machines,
        replace,
        add,
        clear,
      }}
    >
      {children}
    </CacheCtx.Provider>
  );
};
