import {
  Box,
  CircularProgress,
  IconButton,
  LinearProgress,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Cancel, CheckCircle, CloudDownload, HorizontalSplit, Visibility } from "@material-ui/icons";
import Save from "@material-ui/icons/Save";
import React from "react";
import useFarm from "../../hooks/useFarm";
import useInvoicepdf from "../../hooks/useInvoicePdf";
import useInvoices from "../../hooks/useInvoices";
import useLanguage from "../../hooks/useLanguage";
import Invoice from "./Invoice";

interface Props {}

const Finance = (props: Props) => {
  const { QInvoices } = useInvoices();
  const { t, d } = useLanguage();
  const [farm] = useFarm();
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [selectedInvoice, setSelectedInvoice] = React.useState<number | undefined>();

  const selectedInvoiceObject = React.useMemo(
    () => QInvoices.data?.find((i) => i.bookedInvoiceNumber === selectedInvoice),
    [QInvoices.data, selectedInvoice]
  );
  return (
    <>
      <Box height={4}>{QInvoices.isLoading ? <LinearProgress /> : null}</Box>
      <Box maxWidth={1000}>
        <TableContainer component={Paper}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>{t("Date")}</TableCell>
                <TableCell align="right">{t("Due Date")}</TableCell>
                <TableCell align="right">{`${t("Amount")} (${t("Gross")})`}</TableCell>
                <TableCell align="right">{`${t("Amount")} (${t("Net")})`}</TableCell>
                <TableCell align="right">{`${t("Remainder")}`}</TableCell>
                <TableCell align="center">{t("Options")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {QInvoices.data?.map((invoice) => (
                <TableRow key={invoice.bookedInvoiceNumber}>
                  <TableCell>
                    {invoice.remainder > 0 ? (
                      <Cancel fontSize="small" style={{ color: "red" }} />
                    ) : (
                      <CheckCircle fontSize="small" style={{ color: "green" }} />
                    )}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {invoice.date}
                  </TableCell>
                  <TableCell align="right">{d(new Date(invoice.dueDate))}</TableCell>
                  <TableCell align="right">{invoice.grossAmount}</TableCell>
                  <TableCell align="right">{invoice.netAmount}</TableCell>
                  <TableCell align="right">{invoice.remainder}</TableCell>
                  <TableCell align="center">
                    <DownloadButton invoice={invoice.bookedInvoiceNumber} />
                    <IconButton onClick={() => setSelectedInvoice(invoice.bookedInvoiceNumber)} color="primary">
                      <Visibility color="primary" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Dialog
        fullScreen={fullScreen}
        fullWidth
        maxWidth="lg"
        open={!!selectedInvoiceObject}
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogContent>
          <Box mt={2} minHeight={500} height="100%" position="relative">
            <Invoice invoice={selectedInvoiceObject} />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSelectedInvoice(undefined)} color="primary" variant="contained">
            {t("Close")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Finance;
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

interface Props2 {
  disabled?: boolean;
  text?: string;
  invoice: number;
}
export const DownloadButton = (props: Props2) => {
  const { t } = useLanguage();
  const { QInvoices } = useInvoices();
  const { downloadPDF } = useInvoicepdf();

  const doDownload = (invoiceId: number) => {
    const invoice = QInvoices.data?.find((i) => i.bookedInvoiceNumber === invoiceId);
    const downloadLink = invoice?.pdf.download;
    const filename = t("CowConnect_Invoice_") + invoice?.bookedInvoiceNumber;
    downloadLink && downloadPDF.mutate({ invoice: downloadLink, filename });
  };

  return !props.text ? (
    <IconButton disabled={!!props.disabled} color="primary" onClick={() => doDownload(props.invoice)}>
      {downloadPDF.status.isLoading ? <CircularProgress size={20} /> : <CloudDownload />}
    </IconButton>
  ) : (
    <Button
      disabled={!!props.disabled}
      color="primary"
      variant="contained"
      endIcon={downloadPDF.status.isLoading ? <CircularProgress size={16} /> : <CloudDownload />}
      onClick={() => doDownload(props.invoice)}
    >
      {props.text}
    </Button>
  );
};
