import React, { useState } from "react";
import { saveInLocal, getFromLocal } from "../lib/utilities";
import { TimePeriodCtx } from "../lib/contexts";

type TimePeriodProviderProps = {
  children: any;
};

export default ({ children }: TimePeriodProviderProps) => {
  const [timePeriod, setTimePeriod] = useState<number>(() => getFromLocal("__INTELLYNC__TIME__", "number") || 3);

  const setLocalStorageAndState = (value: number) => {
    setTimePeriod(value);
    saveInLocal("__INTELLYNC__TIME__", value, "number");
  };

  return <TimePeriodCtx.Provider value={[timePeriod, setLocalStorageAndState]}>{children}</TimePeriodCtx.Provider>;
};
