import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";
import ExitToAppOutlined from "@material-ui/icons/ExitToAppOutlined";
import React from "react";
import Logo from "../Components/Logo";
import AnimatedBackdrop from "./AnimatedBackdrop";
import useStyles from "./styles";

interface Props {
  text?: string;
}

const LoginInProgress = ({ text = "Authenticating" }: Props) => {
  const classes = useStyles();
  return (
    <AnimatedBackdrop>
      <Box
        p={2}
        maxWidth={400}
        width="100%"
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
      >
        <Logo />
        <Typography variant="h4" component="div" className={classes.prompt}>
          {text}...
        </Typography>
        <Box p={2} width="100%">
          <LinearProgress />
        </Box>
        <Button
          fullWidth
          disabled={true}
          size="large"
          variant="contained"
          color="primary"
          endIcon={<ExitToAppOutlined style={{ marginLeft: 32 }} />}
        >
          Login
        </Button>
      </Box>
    </AnimatedBackdrop>
  );
};

export default LoginInProgress;
