import { useEffect } from "react";
import { useQuery } from "react-query";
import useAxios from "./useAxios";
import useLanguage from "./useLanguage";
import useToast from "./useToast";

interface ILicence {
  id: number;
  name: string;
}
interface ICountry {
  id: number;
  text: string;
  code: string;
  vatZoneNumber: number;
  invoiceLayout: number;
}
interface ICurrency {
  id: number;
  text: string;
  code: string;
}
interface IResponse {
  licenses: ILicence[];
  countries: ICountry[];
  currencies: ICurrency[];
}

const useNewFarmOptions = () => {
  const { axios } = useAxios();
  const toast = useToast();
  const { t } = useLanguage();

  const QNewFarmOptions = useQuery<IResponse, [string]>(["licences"], async () => await axios.get(`cowconnect/data`), {
    refetchOnWindowFocus: false,
    cacheTime: 1000 * 60 * 60 * 48,
    staleTime: 1000 * 60 * 60 * 48,
  });

  useEffect(() => {
    QNewFarmOptions.error && toast.error(t("Can't retrieve licences"));
  }, [QNewFarmOptions.error]);

  return {
    QNewFarmOptions,
  };
};

export default useNewFarmOptions;
