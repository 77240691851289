import React from "react";
import { useQuery } from "react-query";
import useAxios from "./useAxios";
import useFarm from "./useFarm";
import useLanguage from "./useLanguage";
import useToast from "./useToast";
import { IPaymentStatus } from "../types/finance";
import useMainUser from "./useMainUser";
import useRole from "./useRole";

function usePaymentStatus() {
  const isMainUser = useMainUser();
  const { t, code } = useLanguage();
  const [farm] = useFarm();
  const { axios, cancelToken } = useAxios();
  const toast = useToast();
  const { isAdmin } = useRole();

  const QPaymentStatus = useQuery<IPaymentStatus, Error>(
    [`paymentstatus`, farm.farmId],
    async () => {
      return await axios.get(`paymentstatus/${farm.farmId}/${code.substr(0, 2)}`, {
        timeout: 1000 * 60,
        cancelToken,
      });
    },
    {
      refetchOnWindowFocus: false,
      cacheTime: 1000 * 60 * 60,
      staleTime: 1000 * 60 * 60,
      enabled: !!farm?.farmId && (isMainUser || isAdmin),
      retry: 0,
    }
  );

  React.useEffect(() => {
    QPaymentStatus.error && QPaymentStatus.error.message !== "Changed Page" && toast.error(t("Can't find any data"));
  }, [QPaymentStatus.error]);

  return {
    QPaymentStatus,
  };
}

export default usePaymentStatus;
