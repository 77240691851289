import useFarmDetails from "../Features/FarmDetails/useFarmDetails";
import useAuth from "./useAuth";
const useMainUser = () => {
  const { QFarmDetails } = useFarmDetails();
  const { user } = useAuth();

  return user.email === QFarmDetails.data?.email;
};

export default useMainUser;
