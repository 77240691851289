import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

interface Props {
  color?: string;
  opacity?: number;
}

const Recipes = ({ color = "#000", opacity = 0.7 }: Props) => {
  return (
    <SvgIcon>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 496">
        <path
          fill="none"
          stroke={color}
          strokeWidth="15px"
          opacity={opacity}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="m464 112v-112h-152c-24.222656 0-47.496094 10.175781-64 27.617188-16.503906-17.441407-39.777344-27.617188-64-27.617188h-152v112h-32v352h192.640625c11.359375 19.59375 32.550781 32 55.359375 32s44-12.40625 55.359375-32h192.640625v-352zm-416-96h136c21.710938 0 42.40625 10.0625 56 26.910156v173.089844h16v-173.089844c13.59375-16.847656 34.289062-26.910156 56-26.910156h136v336h-33.542969c-60.136719 0-116.945312 24.167969-158.457031 66.863281v-186.863281h-16v186.863281c-41.503906-42.695312-98.304688-66.863281-158.457031-66.863281h-33.542969zm16 352h17.542969c49.609375 0 96.746093 17.734375 133.648437 49.496094-32.953125-11.496094-67.855468-17.496094-102.742187-17.496094h-48.449219zm368 0v32h-48.449219c-34.886719 0-69.808593 6-102.757812 17.503906 36.910156-31.769531 84.0625-49.503906 133.664062-49.503906zm48 80h-186.511719l-2.167969 4.558594c-7.929687 16.671875-24.9375 27.441406-43.320312 27.441406s-35.390625-10.769531-43.3125-27.441406l-2.175781-4.558594h-186.511719v-320h16v240h16v48h64.449219c45.582031 0 91.214843 10.777344 131.976562 31.160156l3.574219 1.785156 3.574219-1.792968c40.761719-20.375 86.394531-31.152344 131.976562-31.152344h64.449219v-48h16v-240h16zm0 0"
        />
        <path
          fill="none"
          stroke={color}
          strokeWidth="15px"
          opacity={opacity}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="m116.152344 306.601562c3.351562 12.597657 14.808594 21.398438 27.847656 21.398438s24.496094-8.800781 27.847656-21.398438l26.257813-98.464843.007812-.007813c1.253907-4.714844 1.886719-9.570312 1.886719-14.417968v-1.710938c0-22.015625-12.800781-41.039062-31.3125-50.191406l10.800781-48.609375c1.582031-7.144531-.136719-14.519531-4.710937-20.222657-4.578125-5.703124-11.410156-8.976562-18.722656-8.976562h-4.054688v-.617188c0-12.886718-10.496094-23.382812-23.382812-23.382812-6.496094 0-12.753907 2.726562-17.167969 7.496094-4.410157 4.761718-6.65625 11.207031-6.160157 17.679687l6.253907 81.273438c-14.222657 10.167969-23.542969 26.773437-23.542969 45.550781v1.710938c0 4.847656.632812 9.703124 1.886719 14.433593zm67.847656-114.601562v1.710938c0 3.464843-.457031 6.9375-1.34375 10.304687l-26.257812 98.464844c-1.503907 5.609375-6.589844 9.519531-12.398438 9.519531s-10.894531-3.910156-12.398438-9.519531l-10.257812-38.480469h22.65625v-16h-26.921875l-4.261719-16h15.183594v-16h-19.457031l-3.191407-11.976562c-.351562-1.328126-.582031-2.679688-.792968-4.023438h39.441406v-16h-39.191406c3.71875-18.230469 19.871094-32 39.191406-32 22.054688 0 40 17.945312 40 40zm-27.945312-112c2.441406 0 4.714843 1.089844 6.234374 2.992188 1.527344 1.902343 2.101563 4.359374 1.566407 6.742187l-10.472657 47.113281c-.460937-.078125-.925781-.144531-1.390624-.214844v-56.632812zm-34.808594-16.046875c-.15625-2.074219.53125-4.058594 1.945312-5.585937 1.417969-1.527344 3.335938-2.367188 5.425782-2.367188 4.070312 0 7.382812 3.3125 7.382812 7.382812v73.257813c-3.078125.449219-6.105469 1.085937-9 2.015625zm0 0"
        />
        <path
          fill="none"
          stroke={color}
          strokeWidth="15px"
          opacity={opacity}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="m304.046875 301.710938c13.234375 11.824218 30.417969 18.28125 48.121094 18.273437 2.71875 0 5.449219-.152344 8.175781-.457031 32.390625-3.65625 58.914062-29.757813 63.054688-62.0625 4.167968-32.617188-14.085938-63.960938-44.398438-76.234375l-6 14.832031c23.574219 9.546875 37.777344 33.960938 34.519531 59.371094-3.207031 25.085937-23.816406 45.351562-48.976562 48.183594-16.222657 1.894531-31.800781-3.089844-43.839844-13.847657-11.878906-10.609375-18.703125-25.839843-18.703125-41.769531 0-26.679688 18.960938-49.785156 45.089844-54.945312 10.957031-2.160157 18.910156-12.007813 18.910156-23.414063v-1.640625h8c22.054688 0 40-17.945312 40-40v-8h-24c-9.039062 0-17.296875 3.128906-24 8.207031v-32.207031h-16v73.648438c0 3.785156-2.519531 7.03125-6.007812 7.71875-33.601563 6.625-57.992188 36.328124-57.992188 70.632812 0 20.480469 8.769531 40.054688 24.046875 53.710938zm79.953125-165.710938h6.632812c-3.304687 9.3125-12.199218 16-22.632812 16h-6.632812c3.304687-9.3125 12.199218-16 22.632812-16zm0 0"
        />
        <path
          fill="none"
          stroke={color}
          strokeWidth="15px"
          opacity={opacity}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="m352 224c13.230469 0 24 10.769531 24 24h16c0-22.054688-17.945312-40-40-40zm0 0"
        />
        <path
          fill="none"
          stroke={color}
          strokeWidth="15px"
          opacity={opacity}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="m368 32h64v16h-64zm0 0"
        />
        <path
          fill="none"
          stroke={color}
          strokeWidth="15px"
          opacity={opacity}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="m368 64h64v16h-64zm0 0"
        />
      </svg>
    </SvgIcon>
  );
};

export default Recipes;
