import { useQuery } from "react-query";
import useAxios from "./useAxios";
import useLanguage from "./useLanguage";
import useFarm from "./useFarm";
import { Cache, CacheCtx } from "../Providers/Cache";
import { useContext, useEffect } from "react";
import { IModule } from "../Features/Groups/types";
import useToast from "./useToast";

export enum EModules {
  CC = "bf8fb31d-5843-4bd8-8ce0-63c4393d13dc",
  DG = "259d2913-0e04-4170-bf81-a9f9c01610ce",
  NorFor = "36745e9f-8ddc-4bf6-bf66-f79b1f6d4398",
  FarmWizard = "b3f3854b-2c90-4bd9-ab85-371e6ba075d9",
  StockControl = "FA8700D2-8A17-4814-A3C0-1E4B3FE3971E",
  SiloConnect = "57d97f70-fb0b-4e02-8c74-2f2769a42022",
  Wageningen = "ad3b0c4e-58ae-474e-af16-38ee783fb177",
  VEM = "58ebbe12-290a-4b42-8669-13843c8362af",
  NIR = "9bb6f1ec-54aa-47e1-bdf7-602c3153c51d",
}

export default function useFarmModules() {
  const cache: Cache | null = useContext(CacheCtx);
  const [farm] = useFarm();
  const { t } = useLanguage();
  const toast = useToast();
  const { axios } = useAxios();
  const {
    data = cache?.modules,
    error,
    ...rest
  } = useQuery<IModule[]>(["modules", farm.farmId], async (key, farmId) => await axios.get(`farms/modules/${farmId}`), {
    refetchOnWindowFocus: false,
    cacheTime: 1000 * 60 * 30,
    staleTime: 1000 * 60 * 30,
    retry: 1,
    enabled: !!farm?.farmId,
  });

  useEffect(() => {
    if (data && cache) cache.replace("modules", data);
  }, [data, cache?.modules]);

  useEffect(() => {
    error && toast.error(t("Can't find any modules"));
  }, [error]);

  const has = (module: EModules) => !!data?.find((_module) => _module.moduleId.toLowerCase() === module.toLowerCase());

  return {
    has,
    data,
    error,
    ...rest,
  };
}
