import Box from "@material-ui/core/Box";
import { motion } from "framer-motion";
import React, { PropsWithChildren } from "react";
import useStyles from "./styles";

interface Props {}

const AnimatedBackdrop = (props: PropsWithChildren<Props>) => {
  const classes = useStyles();

  return (
    <div className={classes.rootLowRes}>
      <div className={classes.root}>
        <Box className={classes.container}>
          <motion.div animate={{ y: 0, opacity: 1 }} initial={{ y: 50, opacity: 0 }} transition={{ delay: 1 }}>
            <Box bgcolor="#fffd" display="flex" justifyContent="center">
              {props.children}
            </Box>
          </motion.div>
        </Box>
      </div>
    </div>
  );
};

export default AnimatedBackdrop;
