import React from "react";
import { useMutation } from "react-query";
import useAxios from "./useAxios";
import useFarm from "./useFarm";
import useLanguage from "./useLanguage";
import useToast from "./useToast";
import { saveAs } from "file-saver";

function useInvoicePdf() {
  const { t } = useLanguage();

  const [farm] = useFarm();
  const { axios, cancelToken } = useAxios();
  const toast = useToast();

  const [mutate, status] = useMutation(
    async ({ invoice, filename }: { invoice: string; filename: string }): Promise<BlobPart> => {
      return await axios.get(`invoices/pdf/${farm.farmId}?link=${invoice}`, {
        headers: {
          "Content-type": "application/pdf;charset=UTF-8",
        },
        cancelToken,
        responseType: "blob",
      });
    },
    {
      onSuccess: (res, vars) => {
        const blob = new Blob([res], { type: "application/pdf" });
        saveAs(blob, vars.filename);
      },
    }
  );

  React.useEffect(() => {
    status.isError && (status.error as Error)?.message !== "Changed Page" && toast.error(t("Error downloading file"));
  }, [status.error]);

  return {
    downloadPDF: {
      mutate,
      status,
    },
  };
}

export default useInvoicePdf;
