import useFarmDetails from "../Features/FarmDetails/useFarmDetails";
import { round } from "../lib/utilities";
import useLanguage from "./useLanguage";

const useUnits = () => {
  const { QFarmDetails } = useFarmDetails();
  const { t } = useLanguage();
  const isMetric = QFarmDetails.data?.unitSystemId === 1;
  return {
    isMetric,
    weightUnit: isMetric ? t("Kg") : t("lb"),
    largeWeightUnit: isMetric ? t("tonne") : t("ton"),
    // THIS ONLY APPLIES TO AREAS AROUND STOCK MANAGEMENT
    // THE COST PER TON VALUE IN INGREDIENT IS ACTUALLY A COST PER 1000 UNITS ON THE DB (HALVED ON SAVE, DOUBLED ON GET)
    // WHICH EQUATES TO HALF AN IMPERIAL TON - THE USER IS SHOWN A COST PER TON.
    largeWeightUnitMultiplier: isMetric ? 1000 : 2000,
    smallWeightUnit: isMetric ? t("g") : t("oz"),
    smallWeightUnitMultiplier: isMetric ? 1000 : 16,
    lengthUnit: isMetric ? t("m") : t("ft"),
    largeLengthUnit: isMetric ? t("km") : t("mi"),
    largeLengthUnitMultiplier: isMetric ? 1000 : 5280,
    areaUnit: isMetric ? t("ha") : t("acre"),
    convertHaToAcres: (ha: number, decimals?: number) =>
      decimals ? round(decimals)(ha * 0.40468564224) : ha * 0.40468564224,
    convertAcresToHa: (acres: number, decimals?: number) =>
      decimals ? round(decimals)(acres * 2.4710538147) : acres * 2.4710538147,
  };
};

export default useUnits;
