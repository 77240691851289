import { queryCache, useMutation } from "react-query";
import { INewFarmOptions } from "../Features/Farm/CreateFarm";
import useAuth from "./useAuth";
import useAxios from "./useAxios";
import useLanguage from "./useLanguage";
import useToast from "./useToast";

const useCreateFarm = () => {
  const { axios } = useAxios();
  const { t } = useLanguage();
  const toast = useToast();
  const { user } = useAuth();

  return useMutation((data: INewFarmOptions) => axios.post(`cowconnect/farm`, data), {
    onSuccess: () => {
      queryCache.invalidateQueries(["farms", user.email]);
      toast.success(t("Farm created successfully"));
    },
    onError: (err: unknown) => {
      toast.error(t("Can't create farm at this time"));
    },
  });
};

export default useCreateFarm;
