import Box from "@material-ui/core/Box";
import React from "react";
import Logo from "../Components/Logo";
import AnimatedBackdrop from "./AnimatedBackdrop";
import CreateFarm from "../Features/Farm/CreateFarm";
import NoFarmsOptions from "../Features/Farm/NoFarmsOptions";

interface Props {}

const LoginNoFarms = ({}: Props) => {
  const [createFarm, setCreateFarm] = React.useState(false);

  return (
    <AnimatedBackdrop>
      <Box
        p={2}
        maxWidth={600}
        width="100%"
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        flexWrap="wrap"
      >
        <Logo />
        {createFarm ? <CreateFarm setCreateFarm={setCreateFarm} /> : <NoFarmsOptions setCreateFarm={setCreateFarm} />}
      </Box>
    </AnimatedBackdrop>
  );
};

export default LoginNoFarms;
