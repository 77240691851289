import { AppInsightsContext, AppInsightsErrorBoundary, ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { createBrowserHistory } from "history";
import React, { PropsWithChildren } from "react";
import ErrorFallback from "../../Screens/ErrorFallback";
import { reactPlugin } from "./AppInsights";

interface Props {}

const Insights = (props: PropsWithChildren<Props>) => {
  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <AppInsightsErrorBoundary onError={() => <ErrorFallback />} appInsights={reactPlugin}>
        {props.children}
      </AppInsightsErrorBoundary>
    </AppInsightsContext.Provider>
  );
};

export default Insights;
