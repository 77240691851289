import makeStyles from "@material-ui/core/styles/makeStyles";
import cows from "../images/tmr.jpg";
import cows_small from "../images/tmr_small.jpg";

export default makeStyles((theme) => ({
  rootLowRes: {
    height: "100vh",
    width: "100vw",
    // backgroundImage: `url(${cows_small})`,
    // backgroundRepeat: "no-repeat",
    // backgroundAttachment: "fixed",
    // backgroundSize: "cover",
    // backgroundPosition: "center bottom",
    backgroundColor: theme.palette.primary.main,
  },
  root: {
    height: "100vh",
    width: "100vw",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    // backgroundImage: `url(${cows})`,
    // backgroundRepeat: "no-repeat",
    // backgroundAttachment: "fixed",
    // backgroundSize: "cover",
    // backgroundPosition: "center bottom",
  },
  container: {
    width: "100%",
  },

  promptBox: {
    height: "100vh",
    width: "100vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  box: {
    width: 400,
    padding: 16,
    backgroundColor: "white",
    borderRadius: 8,
    boxShadow: "0px 10px 10px rgba(0,0,20,.7)",
    opacity: 0.95,
    borderWidth: 8,
    borderColor: theme.palette.primary.main,
    borderStyle: "solid",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    transform: "translateY(-200px)",
  },
  logo: {
    margin: 8,
  },
  prompt: {
    color: theme.palette.primary.main,
    textAlign: "center",
  },
  link: {
    position: "absolute",
    right: 0,
    padding: 8,
    color: "white",
    fontSize: 10,
  },
}));
