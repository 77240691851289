import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { IModule } from "../Features/Groups/types";
import useAuth from "./useAuth";
import useAxios from "./useAxios";
import useLanguage from "./useLanguage";
import useToast from "./useToast";

interface ILegal {
  lastVersionAccepted: boolean;
  url: string;
  version: string;
}
type TAgreed = "checking" | "agreed" | "notagreed" | "error";
const options = {
  refetchOnWindowFocus: false,
  cacheTime: 1000 * 60 * 60,
  staleTime: 1000 * 60 * 60,
};
export default function useLegal() {
  const { user } = useAuth();
  const { t, code } = useLanguage();
  const toast = useToast();
  const { axios } = useAxios();
  const [status, setStatus] = useState<TAgreed>("checking");

  const QLegal = useQuery<ILegal>(["legals", user.email], async () => await axios.get(`privacy`), {
    ...options,
    enabled: !!user.email,
  });

  const agree = useMutation(() => axios.post("privacy"), {
    onError: () => {
      toast.error(t("Can't save agreement"));
      setStatus("error");
    },
    onSuccess: () => {
      toast.success(`${t("Thank you")}, ${user.name}`);
      setStatus("agreed");
    },
  });

  useEffect(() => {
    if (QLegal.error) {
      toast.error(t("Can't get documents status"));
      setStatus("error");
    }
  }, [QLegal.error]);

  useEffect(() => {
    QLegal.data?.lastVersionAccepted && setStatus("agreed");
    QLegal.data && !QLegal.data.lastVersionAccepted && setStatus("notagreed");
  }, [QLegal.data]);

  // I GET A CORS ERROR RATHER THAN A 404 IF THE FILE IS MISSING
  //   const QEula = useQuery(
  //     ["eula", code],
  //     async () =>
  //       await axios.get(
  //         `https://stintellyncsharedprod.blob.core.windows.net/legal/${code.substr(0, 2)}/feedconnect/eula.pdf`
  //       ),
  //     { ...options, enabled: status === "notagreed", retry: 0 }
  //   );
  //   useEffect(() => {
  //     QEula.error && toast.error(t("Can't get documents"));
  //     setStatus("error");
  //   }, [QEula.error]);

  return {
    QLegal,
    agree: { mutate: agree[0], state: agree[1] },
    status,
    setStatus,
  };
}
