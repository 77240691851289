import React, { ReactNode, useEffect, useRef } from "react";
import useLanguage from "../hooks/useLanguage";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { motion } from "framer-motion";
import RefreshIcon from "@material-ui/icons/Refresh";
import useVersionCheck from "../hooks/useVersionCheck";
import useStyles from "../Screens/styles";
import Logo from "../Components/Logo";
import AnimatedBackdrop from "../Screens/AnimatedBackdrop";

interface Props {
  children: ReactNode;
}

const Refresh = ({ children }: Props) => {
  const { t } = useLanguage();
  const version = useVersionCheck();
  const timer = useRef<any>();
  const classes = useStyles();

  useEffect(() => {
    version.fetchVersion();
    timer.current = setInterval(version.fetchVersion, 1000 * 60 * 5);
    return () => {
      clearInterval(timer.current);
    };
  }, []);

  const doReload = () => window.location.reload();

  return version.initDone && version.correct ? (
    <>{children}</>
  ) : (
    <AnimatedBackdrop>
      <Box p={2} width={400} display="flex" justifyContent="center" flexDirection="column" alignItems="center">
        <Logo />
        {!version.initDone ? <h1>{t("Checking for updates")}...</h1> : <h1>{t("New version available")}...</h1>}
        {!version.initDone && (
          <Typography variant="subtitle1" component="div" style={{ textAlign: "center" }}>
            {t("We're trying to determine if you have the latest version of the application")}.
          </Typography>
        )}
        <Typography variant="body1" component="div" style={{ marginTop: 8, marginBottom: 32 }}>
          {!version.initDone && t("It seem's to be taking a long time, try reloading the page")}
          {version.initDone && !version.correct && t("There has been an update, please reload the page")}
        </Typography>
        <Button variant="contained" color="primary" size="large" onClick={doReload} startIcon={<RefreshIcon />}>
          {t("reload")}
        </Button>
      </Box>
    </AnimatedBackdrop>
  );
};

export default Refresh;
