import { CircularProgress, MenuItem, TextField } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Save from "@material-ui/icons/Save";
import { Field, Formik, FormikHelpers } from "formik";
import React from "react";
import * as Yup from "yup";
import useAuth from "../../hooks/useAuth";
import useCowConnectData from "../../hooks/useCowConnectData";
import useCreateFarm from "../../hooks/useCreateFarm";
import useLanguage from "../../hooks/useLanguage";
import useNewFarmOptions from "../../hooks/useNewFarmOptions";
import LanguageSelect from "../../locales/LanguageSelect";
import useSchema from "./useSchema";

interface Props {
  setCreateFarm: React.Dispatch<React.SetStateAction<boolean>>;
}

const CreateFarm = (props: Props) => {
  const { user } = useAuth();
  const { t } = useLanguage();
  const { QNewFarmOptions } = useNewFarmOptions();
  const [saveFarm, state] = useCreateFarm();
  const schema = useSchema();
  const { QCowConnectData } = useCowConnectData();

  const submitForm = (values: INewFarmOptions, actions: FormikHelpers<INewFarmOptions>) => {
    saveFarm(values);
  };

  return (
    <>
      <Box display="flex" justifyContent="space-around" width="100%" maxWidth={600}>
        <Formik<INewFarmOptions>
          initialValues={
            {
              email: user.email,
              farmName: "",
              currencyId: 0,
              countryId: 0,
              unitSystemId: 1,
              licenseId: 10,
              timeZoneId: "Greenwich Standard Time",
            } as INewFarmOptions
          }
          enableReinitialize={false}
          validateOnBlur={true}
          validateOnChange={false}
          validateOnMount={false}
          validationSchema={schema}
          onSubmit={submitForm}
        >
          {(formik) => {
            const handleCountry = (e: React.ChangeEvent<HTMLInputElement>) => {
              formik.setFieldValue("countryId", Number(e.target.value), false);
              if (Number(e.target.value) === 186) {
                //US
                formik.setFieldValue("unitSystemId", 2, false); // SET TO IMPERIAL
              } else {
                formik.setFieldValue("unitSystemId", 1, false); // SET TO METRIC
              }
            };

            return (
              <form onSubmit={formik.handleSubmit}>
                <Box>
                  <Typography
                    variant="body1"
                    component="div"
                    color="textPrimary"
                    style={{ width: "100%", textAlign: "center" }}
                  >
                    {t("Create a new farm for")}
                  </Typography>
                  <Typography
                    variant="h6"
                    color="textPrimary"
                    component="div"
                    style={{ paddingBottom: 0, width: "100%", textAlign: "center" }}
                  >
                    {user.email || t("this user")}
                  </Typography>

                  {!QNewFarmOptions.isLoading ? (
                    <Box display="flex" flexDirection="column">
                      <Box m={2} flexGrow={1}>
                        <Field
                          as={TextField}
                          size="small"
                          error={!!formik.errors.farmName}
                          helperText={formik.errors.farmName}
                          label={t("Farm Name")}
                          required
                          name="farmName"
                          fullWidth
                          inputProps={{
                            style: {
                              fontSize: 24,
                            },
                          }}
                        />
                      </Box>
                      <Box display="flex" justifyContent="space-between" flexWrap="wrap" m={1}>
                        <Box m={1} flexGrow={1} width={150}>
                          <Field
                            as={TextField}
                            size="small"
                            select
                            error={!!formik.errors.currencyId}
                            helperText={formik.errors.currencyId}
                            label={t("Currency")}
                            required
                            name="currencyId"
                            fullWidth
                          >
                            <MenuItem value={0}>{t("Select")}</MenuItem>
                            {QNewFarmOptions.data?.currencies.map((c) => (
                              <MenuItem value={c.id} key={c.id}>
                                {c.text}
                              </MenuItem>
                            ))}
                          </Field>
                        </Box>
                        <Box m={1} flexGrow={1} width={150}>
                          <Field
                            as={TextField}
                            name="countryId"
                            size="small"
                            select
                            required
                            fullWidth
                            label={t("Country")}
                            onChange={handleCountry}
                            error={!!formik.errors.countryId}
                            helperText={formik.errors.countryId}
                          >
                            <MenuItem value={0}>{t("Select")}</MenuItem>
                            {QNewFarmOptions.data?.countries.map((c) => (
                              <MenuItem key={c.id} value={c.id}>
                                {c.text}
                              </MenuItem>
                            ))}
                          </Field>
                        </Box>
                      </Box>
                      <Box display="flex" justifyContent="space-between" flexWrap="wrap" m={1}>
                        <Box m={1} flexGrow={1} width={150}>
                          <Field
                            as={TextField}
                            name="unitSystemId"
                            select
                            fullWidth
                            size="small"
                            label={t("Weight System")}
                            error={!!formik.errors.unitSystemId}
                            helperText={formik.errors.unitSystemId}
                          >
                            <MenuItem value={1}>{t("Metric")}</MenuItem>
                            <MenuItem value={2}>{t("Imperial")}</MenuItem>
                          </Field>
                        </Box>
                        <Box m={1} flexGrow={1} width={150}>
                          <Field
                            as={TextField}
                            select
                            name="timeZoneId"
                            fullWidth
                            label={t("Timezone")}
                            error={!!formik.errors.timeZoneId}
                            helperText={formik.errors.timeZoneId}
                          >
                            <MenuItem value={""}>
                              <em>{t("None Selected")}</em>
                            </MenuItem>
                            {QCowConnectData.data?.timeZones.map((tz) => (
                              <MenuItem value={tz.id} key={tz.id}>
                                {tz.text}
                              </MenuItem>
                            ))}
                          </Field>
                        </Box>
                      </Box>
                      <Box m={2} flexGrow={2}>
                        <Field
                          as={TextField}
                          name="licenseId"
                          select
                          fullWidth
                          size="small"
                          label={t("Licence")}
                          error={!!formik.errors.licenseId}
                          helperText={formik.errors.licenseId}
                          disabled
                        >
                          <MenuItem value={0}>{t("Select")}</MenuItem>
                          {QNewFarmOptions.data?.licenses.map((l) => (
                            <MenuItem key={l.id} value={l.id}>
                              {l.name}
                            </MenuItem>
                          ))}
                        </Field>
                      </Box>
                      <Box display="flex" justifyContent="space-between" mt={2} alignItems="center">
                        <LanguageSelect color={true} text={false} />
                        <Box display="flex" justifyContent="flex-end" alignItems="center">
                          <Box mr={2}>
                            <Button color="primary" onClick={() => props.setCreateFarm(false)}>
                              {t("Cancel")}
                            </Button>
                          </Box>
                          <Box>
                            <Button
                              variant="contained"
                              color="primary"
                              type="submit"
                              startIcon={state.isLoading ? <CircularProgress size={20} color="inherit" /> : <Save />}
                              disabled={state.isLoading}
                            >
                              {t("Create Farm")}
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  ) : (
                    <Box width="100%" height="200" display="flex" justifyContent="center" alignItems="center">
                      <CircularProgress />
                    </Box>
                  )}
                </Box>
              </form>
            );
          }}
        </Formik>
      </Box>
    </>
  );
};

export default CreateFarm;

export interface INewFarmOptions {
  email: string;
  farmName: string;
  currencyId: number;
  countryId: number;
  licenseId: number;
  unitSystemId: number;
  timeZoneId: string;
}
