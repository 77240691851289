import React from "react";
import { Box, Button, Paper, Typography } from "@material-ui/core";
import usePaymentStatus from "../../hooks/usePaymentStatus";
import { Error, Info, Warning } from "@material-ui/icons";
import { motion } from "framer-motion";
import { useTheme } from "@material-ui/core";
import useLanguage from "../../hooks/useLanguage";
import { Link } from "react-router-dom";
import Visibility from "@material-ui/icons/Visibility";

interface Props {}

const FinanceBanner = (props: Props) => {
  const { QPaymentStatus } = usePaymentStatus();
  const theme = useTheme();
  const { t } = useLanguage();
  if (!QPaymentStatus.data) return null;
  const { id, message } = QPaymentStatus.data;
  const icon =
    id === 1 ? (
      <Info fontSize="large" color="primary" />
    ) : id === 2 ? (
      <Warning fontSize="large" color="primary" />
    ) : id === 3 ? (
      <Error fontSize="large" color="error" />
    ) : null;
  return (
    <motion.div initial={{ opacity: 0, y: -64 }} animate={{ opacity: 1, y: 0 }}>
      <Paper elevation={4} style={{ borderRadius: 32, backgroundColor: theme.palette.grey[100] }}>
        <Box display="flex" alignItems="center" justifyContent="center" p={2}>
          {icon}
          <Typography style={{ maxHeight: 24, marginLeft: 16, overflow: "hidden" }} variant="subtitle1" color="primary">
            {message}
          </Typography>
          <Box ml={2}>
            <Button
              component={Link}
              to={"/settings/finance"}
              size="small"
              color="primary"
              variant="contained"
              endIcon={<Visibility />}
            >
              {t("View")}
            </Button>
          </Box>
        </Box>
      </Paper>
    </motion.div>
  );
};

export default FinanceBanner;
