import axios from "../lib/axios";
import Axios from "axios";
import React from "react";

const useAxios = () => {
  const source = Axios.CancelToken.source();

  React.useEffect(() => {
    return () => {
      source.cancel("Changed Page");
    };
  }, []);

  return {
    axios,
    cancelToken: source.token,
    cancel: source.cancel,
    checkStatus: (x: any) => x,
  };
};

export default useAxios;
