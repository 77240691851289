import React from "react";
import logoCowConnect from "../../images/cowconnect-logo.png";
import logoCowConnectReverse from "../../images/cowconnect-logo-reverse.png";
import logoFeedConnect from "../../images/feedconnect-logo.png";
import logoFeedConnectReverse from "../../images/feedconnect-logo-reverse.png";
import Box from "@material-ui/core/Box";
import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

interface Props {
  reverse?: boolean;
  height?: number;
}

const Logo = ({ reverse = false, height = 100 }: Props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const urlIsFeedConnect: boolean = !!window.location.href.toLowerCase().includes("feedconnect");
  return (
    <Box m={1} display="flex" justifyContent="center">
      {urlIsFeedConnect ? (
        <img
          src={reverse ? logoFeedConnectReverse : logoFeedConnect}
          height={isMobile ? height / 1.5 : height}
          alt="Feed Connect"
        />
      ) : (
        <img
          src={reverse ? logoCowConnectReverse : logoCowConnect}
          height={isMobile ? height / 1.5 : height}
          alt="Cow Connect"
        />
      )}
    </Box>
  );
};

export default Logo;
