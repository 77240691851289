import React from "react";
import { IFarm } from "../types/farm";
import { FarmCtx } from "../lib/contexts";

export default () => {
  const [farm, setFarmState] = React.useContext(FarmCtx);
  const setFarm = (opt: any) => {
    setFarmState(opt);
  };
  return [farm, setFarm] as [IFarm, Function];
};
