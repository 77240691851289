import jsPDF from "jspdf";
import XLSX from "xlsx";
import html2canvas from "html2canvas";
import format from "date-fns/format";
import isValid from "date-fns/isValid";
import { base64Logo } from "./constants";

const illegalCharacters = new RegExp(/[`~!@#$%^&*()_|+\-=?;:'",.<>{}[]\\\/]/gi);

export const customDateFormat = (value: string, time?: boolean) => {
  const date = new Date(value);
  if (isValid(date)) {
    const _format = time === false ? "yyyy-MM-dd" : "yyyy-MM-dd HH:mm";
    return format(date, _format);
  } else {
    return value;
  }
};

export function isError(error: unknown): error is Error {
  return error instanceof Error;
}

export function saveInLocal(key: string, value: any, type?: "string" | "number" | "json" | "boolean") {
  var oldValue = getFromLocal(key);
  if (!type || type === "string") localStorage.setItem(key, value);
  if (type?.toLowerCase() === "json") localStorage.setItem(key, JSON.stringify(value));
  if (type?.toLowerCase() === "number") localStorage.setItem(key, value.toString());
  if (type?.toLowerCase() === "boolean") localStorage.setItem(key, value.toString());
  return { oldValue, newValue: value };
}

export function removeFromLocal(key: string) {
  var oldValue = getFromLocal(key);
  localStorage.removeItem(key);
  return { oldValue };
}

export function getFromLocal(key: string, type?: "string" | "number" | "json" | "boolean") {
  var data = localStorage.getItem(key);
  if (data && type?.toLowerCase() === "number") return Number(data);
  else if (data && type?.toLowerCase() === "json") return JSON.parse(data);
  else if (data && type?.toLowerCase() === "boolean") return Boolean(data);
  return data || null;
}

export const round = (decimalPlaces: number) => (num: number) =>
  Math.round(num * Math.pow(10, decimalPlaces)) / Math.pow(10, decimalPlaces);

export interface IOptions {
  type: "pdf" | "excel";
  orientation: "landscape" | "portrait";
  filename: string;
  title: string;
  subtitle?: string;
  data?: IData;
  columns?: (1 | 0)[];
  headColumns?: (1 | 0)[];
}

interface IData {
  columns: string[];
  data: any[];
}

export const downloadCanvasPdf = (
  canvas: HTMLCanvasElement,
  {
    filename = "graph",
    orientation = "landscape",
    title = "Downloaded Graph",
    subtitle,
  }: { filename: string; orientation: "landscape" | "portrait"; title: string; subtitle?: string }
) => {
  const margin = 5;
  const maxHeight = orientation === "landscape" ? 210 : 297;
  const maxWidth = orientation === "landscape" ? 297 : 210;
  const canvasHeight = parseInt(canvas?.style.height);
  const canvasWidth = parseInt(canvas?.style.width);
  const graphWidth = maxWidth - margin * 2;
  let graphHeight = maxHeight - margin * 5;
  const canvasRatio = Math.max(canvasWidth / graphWidth, canvasHeight / graphHeight);
  graphHeight = canvasHeight / canvasRatio;
  const availableHeight = maxHeight - margin * 2 - 5;
  const graphTop = (availableHeight - graphHeight) / 2 + margin + (subtitle ? 20 : 10);

  canvas && (canvas.style.backgroundColor = "white");

  var pdf = new jsPDF(orientation);
  pdf.setFontSize(16);
  pdf.setTextColor(0, 148, 210);
  pdf.text(margin, margin + 5, title);
  pdf.setFontSize(10);
  pdf.setTextColor(0, 148, 210);

  subtitle && pdf.text(margin, margin + 10, subtitle);

  pdf.text(margin, margin + (subtitle ? 15 : 10), "Printed on: " + new Date().toString());

  pdf.addImage(canvas?.toDataURL(), "PNG", margin, graphTop, graphWidth, graphHeight);
  pdf.save(filename.replace(illegalCharacters, "") + ".pdf");

  canvas && (canvas.style.backgroundColor = "transparent");
};

export const downloadDOMPdf = (dom: HTMLElement, options: any) => {
  html2canvas(dom).then(function (canvas) {
    downloadCanvasPdf(canvas, options);
  });
};

export const downloadExcel = (dom: HTMLTableElement, options: IOptions) => {
  const _dom = dom.cloneNode(true) as HTMLTableElement;

  if (_dom.tHead) {
    for (let j = 0; j < _dom.tHead.rows.length; j++) {
      for (let i = _dom.tHead.rows[j].cells.length - 1; i >= 0; i--) {
        _dom.tHead.rows[j].cells[i].innerHTML = _dom.tHead.rows[j].cells[i].innerText
          .replace("arrow_downward", " ")
          .replace("arrow_upward", " ");
      }
    }
  }

  const deleteCols = (el: HTMLTableSectionElement | HTMLTableElement | null, showList?: (1 | 0)[]) => {
    if (!el?.rows || !options.columns) return;
    for (let j = 0; j < el.rows.length; j++) {
      for (let i = el.rows[j].cells.length - 1; i >= 0; i--) {
        if (showList) {
          !showList[i] && el.rows[j].deleteCell(i);
        } else {
          !options.columns[i] && el.rows[j].deleteCell(i);
        }
      }
    }
  };
  deleteCols(_dom.tFoot);
  deleteCols(_dom.tHead, options.headColumns);
  deleteCols(_dom);

  // CREATE ROW 3
  var row4 = _dom.insertRow(0);
  var row3 = _dom.insertRow(0);

  // TIME IN SECOND 2 COLS
  var cell4 = row3.insertCell(0);
  cell4.innerHTML = format(new Date(), "HH:mm");
  cell4.colSpan = 2;

  // DATE ON FIRST 2 COLS
  var cell3 = row3.insertCell(0);
  cell3.innerHTML = format(new Date(), "yyyy/MM/dd HH:mm");
  cell3.colSpan = 2;

  // ROW 2 TITLE GIVEN FROM PAGE
  var row2 = _dom.insertRow(0);
  var cell2 = row2.insertCell(0);
  cell2.innerHTML = options.title;
  cell2.colSpan = 10;

  // ROW 1 INTELLYNC BRAND - PERHAPS TO CHANGE TO CowConnect powered by Intellync
  var row1 = _dom.insertRow(0);
  var cell1 = row1.insertCell(0);
  cell1.innerHTML = "CowConnect powered by Intellync";
  cell1.colSpan = 10;

  var wb = XLSX.utils.table_to_book(_dom, { cellDates: true });
  XLSX.writeFile(wb, options.filename.replace(illegalCharacters, "") + ".xlsx");
};

export const downloadExcelData = (data: any[][], options: any) => {
  var ws_name = options.title.replace(illegalCharacters, "").substr(0, 30);
  var wb = XLSX.utils.book_new();
  var ws = XLSX.utils.aoa_to_sheet([["Cow Connect"], [options.title.replace(illegalCharacters, "")], [], ...data]);
  XLSX.utils.book_append_sheet(wb, ws, ws_name);
  XLSX.writeFile(wb, options.filename.replace(illegalCharacters, "") + ".xlsx");
};

export const isGUID = (str: string) => {
  if (str.length !== 36) return false;
  const _arr = str.split("-");
  if (_arr.length !== 5) return false;
  if (_arr[0].length !== 8) return false;
  if (_arr[1].length !== 4) return false;
  if (_arr[2].length !== 4) return false;
  if (_arr[3].length !== 4) return false;
  if (_arr[4].length !== 12) return false;
  return true;
};

export const mergeObjectKeys = (obj: any) => Object.keys(obj).reduce((t, k) => ({ ...t, ...obj[k] }), {});

export const printLogoDiv = (): HTMLDivElement => {
  let elemDiv = document.createElement("div");
  elemDiv.style.cssText = "display:flex;flex-direction:row;justify-content:flex-end;align-items:flex-start";
  let img = document.createElement("img");
  img.src = `data:image/png;base64,${base64Logo}`;
  img.style.cssText = "width:250px;height:55px;";
  elemDiv.appendChild(img);
  return elemDiv;
};

export const printFiltersDiv = (_summariseFilters: string[][]): HTMLDivElement => {
  const filterDiv = document.createElement("div");
  for (let filter of _summariseFilters) {
    let elemDiv = document.createElement("div");
    elemDiv.style.cssText = "";
    elemDiv.innerText = filter[0];
    filterDiv.appendChild(elemDiv);
  }
  return filterDiv;
};
