import { Hidden } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import ExitToApp from "@material-ui/icons/ExitToApp";
import React from "react";
import { CowConnect } from "../../Components/Icons";
import useAuth from "../../hooks/useAuth";
import useLanguage from "../../hooks/useLanguage";

interface Props {
  setCreateFarm: React.Dispatch<React.SetStateAction<boolean>>;
  m?: number;
}

const NoFarmsOptions = (props: Props) => {
  const { user, logout } = useAuth();
  const { t } = useLanguage();

  return (
    <>
      <Typography variant="body1" component="div" color="textPrimary" style={{ width: "100%", textAlign: "center" }}>
        {t("No farm registered to")}
      </Typography>
      <Typography
        variant="h6"
        color="textPrimary"
        component="div"
        style={{ paddingBottom: 0, width: "100%", textAlign: "center" }}
      >
        {user.email || t("this user")}
      </Typography>
      <Box display="flex" justifyContent="space-around" width="100%" mt={1} mb={3}>
        <Hidden smDown>
          <CreateFarm {...props} />
          <Box display="flex" alignItems="center" flexDirection="column" width={240}>
            <Box display="flex" justifyContent="center" alignItems="center">
              <ExitToApp color="secondary" style={{ fontSize: 72 }} />
            </Box>
            <Box m={2}>
              <Button
                fullWidth
                onClick={logout}
                variant="contained"
                color="primary"
                size="large"
                endIcon={<ExitToApp />}
              >
                {t("Logout")}
              </Button>
            </Box>
          </Box>
        </Hidden>
      </Box>
      <Hidden mdUp>
        <CreateFarm {...props} m={0} />
        <Box width="100%" display="flex" justifyContent="flex-end" pr={2}>
          <Button size="small" onClick={logout} variant="text" color="primary" endIcon={<ExitToApp />}>
            {t("Logout")}
          </Button>
        </Box>
      </Hidden>
    </>
  );
};

export default NoFarmsOptions;

const CreateFarm = (props: Props) => {
  const { t } = useLanguage();
  return (
    <Box display="flex" alignItems="center" flexDirection="column" width={240}>
      <Box display="flex" justifyContent="center" alignItems="center">
        <CowConnect color="secondary" style={{ fontSize: 72 }} />
      </Box>

      <Box m={props.m || 2}>
        <Button
          fullWidth
          onClick={() => props.setCreateFarm(true)}
          variant="contained"
          color="primary"
          size="large"
          endIcon={<CowConnect color="#fff" />}
        >
          {t("Create a farm")}
        </Button>
      </Box>
    </Box>
  );
};
