import React, { ReactNode } from "react";

interface Props {
  children: ReactNode;
  justifyContent?: "flex-start" | "flex-end" | "center" | "space-between" | "space-around" | "space-evenly" | "stretch";
  alignContent?: "flex-start" | "flex-end" | "center" | "space-between" | "space-around" | "space-evenly" | "stretch";
  alignItems?:
    | "flex-start"
    | "flex-end"
    | "center"
    | "space-between"
    | "space-around"
    | "space-evenly"
    | "stretch"
    | "baseline";
  flexWrap?: "wrap" | "wrap-reverse" | "nowrap" | "unset" | "revert" | "initial" | "inherit";
  style?: any;
  spacing?: number;
}

const FlexRow = ({
  children,
  justifyContent = "flex-start",
  alignContent = "flex-start",
  alignItems = "flex-start",
  flexWrap = "nowrap",
  spacing = 0,
  style = {},
}: Props) => {
  return (
    <div
      style={{
        width:'100%',
        display: "flex",
        flexDirection: "row",
        justifyContent: justifyContent,
        alignContent: alignContent,
        alignItems: alignItems,
        flexWrap: flexWrap,
        margin: spacing,
        ...style,
      }}
    >
      {children}
    </div>
  );
};

export default FlexRow;
