import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

interface Props {
  color?: string;
  opacity?: number;
  text?: boolean;
  size?: "small" | "large" | "inherit" | "medium";
}

const MilkJar = ({ color = "#000", opacity = 0.7, size = "medium" }: Props) => {
  return (
    <SvgIcon fontSize={size}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024">
        <g id="jar" style={{ opacity }}>
          <path
            style={{ opacity: 0.6, fill: color }}
            d="M774,430H250H100v300v35v9c0,82.8,67.2,150,150,150h211v100h101V924h212
	c82.8,0,150-67.2,150-150v-9v-35V430H774z"
          />
          <rect
            x="150"
            y="680"
            transform="matrix(6.123234e-17 -1 1 6.123234e-17 -580 930)"
            style={{ fill: color }}
            width="50"
            height="150"
          />
          <rect
            x="150"
            y="530"
            transform="matrix(6.123234e-17 -1 1 6.123234e-17 -430 780)"
            style={{ fill: color }}
            width="50"
            height="150"
          />
          <rect
            x="150"
            y="380"
            transform="matrix(6.123234e-17 -1 1 6.123234e-17 -280 630)"
            style={{ fill: color }}
            width="50"
            height="150"
          />
          <rect
            x="150"
            y="230"
            transform="matrix(6.123234e-17 -1 1 6.123234e-17 -130 480)"
            style={{ fill: color }}
            width="50"
            height="150"
          />
          <g>
            <path
              style={{ fill: color }}
              d="M411,50H250C140,50,50,140,50,250v524c0,110,90,200,200,200h161v50h70V874h-70H299
		c-82.5,0-150-67.5-150-150V300c0-82.5,67.5-150,150-150h112h70V0h-70V50z"
            />
          </g>
          <g>
            <path
              style={{ fill: color }}
              d="M620,50h161c110,0,200,90,200,200v524c0,110-90,200-200,200H620v50h-70V874h70h112
		c82.5,0,150-67.5,150-150V300c0-82.5-67.5-150-150-150H620h-70V0l70,0V50z"
            />
          </g>
        </g>
      </svg>
    </SvgIcon>
  );
};

export default MilkJar;
