import { useQuery } from "react-query";
import useAxios from "./useAxios";
import useToast from "./useToast";
import useLanguage from "./useLanguage";
import { ICowConnectData } from "../types/settings";
import React from "react";

function useCowConnectData() {
  const { axios, cancelToken } = useAxios();
  const toast = useToast();
  const { t } = useLanguage();

  const QCowConnectData = useQuery<ICowConnectData, [string, string]>(
    ["cowconnect/data"],
    async (key) =>
      await axios.get(key, {
        cancelToken,
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1000 * 60 * 60 * 24,
      staleTime: 1000 * 60 * 60 * 24,
    }
  );

  React.useEffect(() => {
    QCowConnectData.error && toast.error(t("Can't retrieve data"));
  }, [QCowConnectData.error]);

  return { QCowConnectData };
}

export default useCowConnectData;
