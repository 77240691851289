import React from "react";
import { useEffect } from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import useLanguage from "./useLanguage";
import useAxios from "./useAxios";

export interface IRole {
  roleId: ERole;
  roleName: string;
}

export enum ERole {
  none,
  admin,
  advisor,
}

const useRole = () => {
  const { axios } = useAxios();
  const { t } = useLanguage();
  const history = useHistory();

  const QRole = useQuery<IRole, [string, string]>(["role"], async () => await axios.get(`userrole`), {
    refetchOnWindowFocus: false,
    cacheTime: 1000 * 60 * 60,
    staleTime: 1000 * 60 * 60,
    retry: 1,
  });

  useEffect(() => {
    QRole.error && console.error(t("Can't find user role"));
  }, [QRole.error]);

  const redirect = React.useCallback(() => {
    history.push("/");
  }, []);

  return {
    QRole,
    isAdmin: QRole.data?.roleId === ERole.admin,
    isAdvisor: QRole.data?.roleId === ERole.advisor,
    redirect,
  };
};

export default useRole;
